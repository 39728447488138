import React, { Component } from "react";

import {
  View,
  Text,
  TextInput,
  StyleSheet,
  TouchableOpacity,
  OpenSnack,
  DatePicker,
  ListView,
  Icon,
  Switch
} from "react-1app";
import ListSubheader from "@material-ui/core/ListSubheader";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import * as IngressoPadrao from "../../worker/ingresso_padrao";
import * as Evento from "../../worker/evento";
import { Util } from "../../infra";
import styleGlobal from "../../styleGlobal";

export default class Produtos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      produtos: null,
      item: this.props.item || {},
      load: true
    };
  }

  criar() {
    Evento.criar(
      {
        ...this.state.item,
        ingressos: (this.state.list || []).filter(e => e.add)
      },
      (item, error) => {
        this.onRes(item, error, "criar");
      }
    );
  }

  buscar() {
    IngressoPadrao.listar({ limit: 70 }, ({ list = [] }, error) => {
      this.setState({
        list: list.map(item => ({ ...item, add: true })),
        load: false
      });
    });
  }

  onRes(item, error, tag) {
    if (error) {
      OpenSnack({ message: "Erro ao " + tag + " evento!", type: "error" });
    } else {
      this.setState({ item });
      this.props.atualizar(item);
      this.props.closeModal();
      OpenSnack({
        message: "Evento ao " + tag + " com sucesso!",
        type: "success"
      });
    }
  }

  componentDidMount() {
    this.buscar();
  }

  componentWillUnmount() {}

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View style={styles.tela} type={"Paper"}>
            <Text style={styles.label}>{"NOVO DO EVENTO"}</Text>
            <TextInput
              style={styles.textinput1}
              value={this.state.item.nome}
              onChange={value => {
                this.state.item.nome = value;
                this.setState({ item: this.state.item });
              }}
              keyboardType={"default"}
              label={"Nome do evento"}
            />
            <DatePicker
              style={styles.datepicker}
              value={this.state.item.data_hora}
              onChange={value => {
                this.setState({
                  item: { ...this.state.item, data_hora: value }
                });
              }}
              datePickerModeAndroid={"calendar"}
              label={"Data e Hora de Inicio do Evento"}
            />
            <View type={"Paper"}>
              <ListView
                ListHeaderComponent={() => (
                  <View style={styles.head}>
                    <ListSubheader component="div">
                      {"Ingressos Padrões"}
                    </ListSubheader>
                  </View>
                )}
                renderItem={({ item, index }) => {
                  return (
                    <ListItem button key={index} style={{ width: "100%" }}>
                      <Avatar src={item.img}>
                        <Icon name={"confirmation_number"} />
                      </Avatar>
                      <ListItemText
                        primary={item.nome}
                        secondary={"Valor: " + Util.parseMoney(item.valor)}
                      />
                      <Switch
                        style={styles.switch}
                        value={item.add}
                        onChange={value => {
                          item.add = value;
                          this.setState({ value });
                        }}
                      />
                    </ListItem>
                  );
                }}
                separator
                nativo
                data={this.state.list}
              />
            </View>
            <View style={styles.action}>
              <TouchableOpacity
                onPress={() => this.criar()}
                load={this.state.load}
                children={"Salvar"}
                variant="outlined"
              ></TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.props.closeModal()}
                style={styles.delete}
                children={"Fechar"}
                color={"default"}
                variant="outlined"
              ></TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  tela: {
    padding: 20,
    marginTop: 50
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  },
  textinput1: {
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    textAlign: "left",
    fontWeight: "normal"
  },
  selectinput: {},
  view2: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  fragment: {
    alignSelf: "stretch",
    flex: 1
  },
  view3: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  progress: {
    width: 35,
    height: 35
  },
  text: {
    textAlign: "center",
    color: "rgba(255,255,255,1)",
    alignSelf: "stretch",
    fontWeight: "bold"
  }
});
