import { Cloud } from "../infra";

export function salvar(ingresso, callback) {
  Cloud.post("caixa_fato", ingresso, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function atualizar(ingresso, callback) {
  Cloud.put("caixa_fato/" + ingresso.id, ingresso, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function baixa(data, callback) {
  Cloud.put("caixa_fato/validar/item", data, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function remover(id, callback) {
  Cloud.delete("caixa_fato/" + id, {}, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function listar(input, callback) {
  Cloud.get("caixa_fato", input, (res, error) => {
    if (callback) callback(res, error);
  });
}
