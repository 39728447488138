import { Cloud } from "../infra";

export function enviarNotificacao(input, callback) {
  Cloud.post(
    "notificacao",
    input,
    (res, error) => {
      if (callback) callback(res, error);
    }
  );
}
