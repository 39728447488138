import {Cloud} from "../infra";


export function totalizadoresPriodo(obj, callback){
  Cloud.get('dashboard/adm/dados/perido', obj, (res,error) => {
    if(callback) callback(res,error);
  })
}


export function totalizadoresSatus(obj, callback){
  Cloud.get('dashboard/adm/dados/status', obj, (res,error) => {
    if(callback) callback(res,error);
  })
}

export function getDadosSemanais(obj, callback){
  Cloud.get('dashboard/adm/dados/semana', obj, (res,error) => {
    if(callback) callback(res,error);
  })
}

export function getDadosMensais(obj, callback){
  Cloud.get('dashboard/adm/dados/mes', obj, (res,error) => {
    if(callback) callback(res,error);
  })
}

export function getUsuariosMensais(obj, callback){
  Cloud.get('dashboard/adm/user/mes', obj, (res,error) => {
    if(callback) callback(res,error);
  })
}

export function getResumoVendasPeriodo(obj, callback){
  Cloud.get('dashboard/adm/venda/periodo', obj, (res,error) => {
    if(callback) callback(res,error);
  })
}


export function getDadosMensaisComissao(obj, callback){
  Cloud.get('dashboard/adm/dados/mes/comissao', obj, (res,error) => {
    if(callback) callback(res,error);
  })
}
