import React, { Component } from "react";

import Icon from "react-1app/lib/Icon";
import StyleSheet from "react-1app/lib/StyleSheet";
import TextInput from "react-1app/lib/TextInput";
import * as CaixaFato from "../../worker/caixa_fato";
import TableSelect from "react-1app/lib/Table";
import InputAdornment from "@material-ui/core/InputAdornment";
import moment from "moment";
import md5 from "md5";
import { OpenSnack } from "react-1app/lib/SnackBar";
import { OpenDialog } from "react-1app/lib/DialogAlert";
import { Util } from "../../infra";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import View from "react-1app/lib/View";
import DatePicker from "react-1app/lib/DatePicker";

moment.locale("pt-br");
export default class Listar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      load: true,
      totalLista: 0,
      parametros: {
        limit: 15,
        page: 0,
        promoter: false,
        busca: "",
        inicio: null,
        fim: null
      },
      open: false,
      promoter: {},
      user: this.props.screenProps.store.getState().user || {},
      regras: this.props.screenProps.store.getState().regras,
      senha: ""
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.projeto != this.state.projeto)
        this.setState({ projeto: store.projeto, anchorEl: null }, () => {
          this.buscar();
        });
    });
    this.buscar();
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }
  buscar() {
    this.setState({ load: true });
    CaixaFato.listar(
      this.state.parametros,
      (dados = { list: [], total: 0 }, error) => {
        console.log(dados.list);
        this.setState({
          lista: dados.list,
          totalLista: dados.total,
          load: false
        });
      }
    );
  }

  validar(item, value) {
    this.setState({ load: true });
    CaixaFato.baixa({ id_caixa_fato: item.id }, (res, error) => {
      if (error) {
        alert(error.msg);
      } else {
        alert("Validado");
      }
      this.buscar();
    });
  }

  validarItem(item, value) {
    OpenDialog({
      title: "Confirmação",
      description: "Você deseja validar este item?",
      action: [
        {
          label: "CONFIRMAR",
          onClick: () => {
            this.validar(item);
          },
          color: "secondary"
        },
        { label: "CANCELAR" }
      ]
    });
  }

  validarRemover(item, value) {
    OpenDialog({
      title: "Confirmação",
      description:
        "Você deseja apagar este item? Ele será removido e não poderá mais ser usado! Compras no cartão não serão estornadas, Para estorno acesse a tela pagamentos",
      action: [
        {
          label: "CONFIRMAR",
          onClick: () => {
            if (md5(this.state.senha) !== this.state.user.password) {
              return OpenSnack({ message: "Senha inválida!", type: "error" });
            }
            this.remover(item);
          },
          color: "secondary"
        },
        { label: "CANCELAR" }
      ],
      input: {
        secureTextEntry: true,
        type: "password",
        onChange: value => this.setState({ senha: value }),
        label: "Senha de confirmação"
      }
    });
  }

  remover(item, value) {
    this.setState({ load: true });
    CaixaFato.remover(item.id, (res, error) => {
      console.log(res, error);
      if (error) return alert(error.message || "Erro ao remover!");
      this.buscar();
      OpenSnack({ message: "Item deletado", type: "success" });
    });
  }

  render() {
    return (
      <TableSelect
        title={"Vendas"}
        actionBar={
          <View>
            <DatePicker
              style={styles.datepicker}
              value={this.state.parametros.inicio}
              onChange={value => {
                this.setState({
                  parametros: { ...this.state.parametros, inicio: value }
                });
              }}
              datePickerModeAndroid={"calendar"}
              label={"Data Inicial da validação"}
              type={"date"}
            />
            <DatePicker
              style={styles.datepicker}
              value={this.state.parametros.fim}
              onChange={value => {
                this.setState({
                  parametros: { ...this.state.parametros, fim: value }
                });
              }}
              datePickerModeAndroid={"calendar"}
              label={"Data Final da validação"}
              type={"date"}
            />
            <TextInput
              style={{ marginLeft: 5, marginTop: 20 }}
              value={this.state.parametros.busca}
              onChange={value => {
                this.setState({
                  parametros: { ...this.state.parametros, busca: value }
                });
              }}
              keyboardType={"default"}
              label={"Buscar por código"}
              onSubmitEditing={() => {
                this.setState(
                  {
                    parametros: Object.assign(this.state.parametros, {
                      page: 0
                    })
                  },
                  () => this.buscar()
                );
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <TouchableOpacity
                      style={{}}
                      onPress={() => {
                        this.setState(
                          {
                            parametros: Object.assign(this.state.parametros, {
                              page: 0
                            })
                          },
                          () => this.buscar()
                        );
                      }}
                    >
                      <Icon
                        style={{}}
                        fromFontFamily={"Material Icons"}
                        name={"search"}
                      />
                    </TouchableOpacity>
                  </InputAdornment>
                )
              }}
            />
          </View>
        }
        tableHead={[
          "Origem",
          "Tipo",
          "Nome",
          "Valor",
          "Criado",
          "Codigo",
          "Usuario",
          "Status",
          "Ações"
        ]}
        tableData={this.state.lista.map(item => [
          item.origen,
          item.tipo,
          item.nome,
          Util.parseMoney(item.valor),
          moment(item.criado).format("DD/MM/YYYY HH:mm"),
          item.codigo,
          item.id_usuario,
          item.validado
            ? "Validado: " + moment(item.validado).format("DD/MM/YY HH:mm")
            : "Não Validado",
          <div>
            {(this.state.regras &&
              this.state.regras.admin &&
              !item.id_usuario) ||
            this.state.user.adm ? (
              <TouchableOpacity
                style={styles.action}
                onPress={() => {
                  this.validarRemover(item);
                }}
              >
                <Icon
                  style={{}}
                  fromFontFamily={"Material Icons"}
                  name={"delete"}
                />
              </TouchableOpacity>
            ) : null}
            {!item.validado ? (
              <TouchableOpacity
                variant="outlined"
                children={"Validar"}
                onPress={() => {
                  this.validarItem(item);
                }}
              ></TouchableOpacity>
            ) : null}
          </div>
        ])}
        tablePagination
        load={this.state.load}
        count={Number(this.state.totalLista)}
        page={this.state.parametros.page}
        rowsPerPage={this.state.parametros.limit}
        handleChangePage={(event, page) => {
          this.setState(
            {
              parametros: Object.assign(this.state.parametros, { page }),
              load: true
            },
            this.buscar()
          );
        }}
        handleChangeRowsPerPage={event => {
          this.setState(
            {
              parametros: Object.assign(this.state.parametros, {
                limit: event.target.value,
                page: 0
              }),
              load: true
            },
            this.buscar()
          );
        }}
      />
    );
  }
}

var styles = StyleSheet.create({
  action: {
    width: 50,
    alignItems: "center",
    justifyContent: "center"
  },
  datepicker: {
    color: "rgba(0,0,0,1)",
    alignSelf: "auto",
    marginLeft: 10,
    maxWidth: 220
  }
});
