import React from "react";
import ListSubheader from "@material-ui/core/ListSubheader";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import ImageUpload from "react-1app/lib/ImageUpload";
import TextInput from "react-1app/lib/TextInput";
import DatePicker from "react-1app/lib/DatePicker";
import { OpenSnack } from "react-1app/lib/SnackBar";
import moment from "moment";
import ListView from "react-1app/lib/ListView";
import * as Evento from "../../worker/evento";
import IngressoView from "./Ingresso";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import ListItem from "@material-ui/core/ListItem";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "react-1app/lib/Icon";
import styleGlobal from "../../styleGlobal";
import { Util } from "../../infra";
import NovoEvento from "./Novo";
import { OpenModal } from "react-1app/lib/PgModal";
import Switch from "react-1app/lib/Switch";
import Relatorio from "./Relatorio";
import ModalVideoAjuda from "../ModalVideoAjuda";
import ModalEndereco from "../configuracoes/ModalEndereco";
import InputAdornment from "@material-ui/core/InputAdornment";

class Eventos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {},
      load: true,
      totalLista: 0,
      value: 0,
      parametros: {
        limit: 10,
        page: 0
      },
      projeto: this.props.screenProps.store.getState().projeto || {},
      list: []
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.projeto != this.state.projeto)
        this.setState({ projeto: store.projeto, item: {}, value: 0 }, () => {
          this.buscar();
        });
    });
    this.buscar();
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  buscar() {
    if (!this.state.projeto.id) return;
    Evento.listar(
      this.state.parametros,
      (dados = { list: [], total: 0 }, error) => {
        console.log(dados.list);
        this.setState({
          list: dados.list,
          totalLista: dados.total,
          yapay: dados.yapay,
          load: false
        });
      }
    );
  }

  salvar() {
    if (this.state.item.id && this.state.item.id != "new") {
      Evento.atualizar(this.state.item, this.state.user, (evento, error) => {
        this.onRes(evento, error, "atualizar");
      });
    } else {
      Evento.salvar(this.state.item, this.state.user, (evento, error) => {
        this.onRes(evento, error, "salvar");
      });
    }
  }

  onRes(evento, error, tag) {
    if (error) {
      OpenSnack({ message: "Erro ao " + tag + " evento!", type: "error" });
    } else {
      this.setState({ item: evento });
      this.buscar();
      OpenSnack({
        message: "Evento ao " + tag + " com sucesso!",
        type: "success"
      });
    }
  }

  remover() {
    if (window.confirm("Deseja remover este item?")) {
      Evento.remover(this.state.item.id, (evento, error) => {
        if (error) {
          OpenSnack({ message: "Erro ao salvar evento!", type: "error" });
        } else {
          this.setState({ item: {} });
          this.buscar();
          OpenSnack({ message: "Evento removido!", type: "success" });
        }
      });
    }
  }

  novoEvento() {
    OpenModal(NovoEvento, {
      item: {
        data_hora: moment()
          .add(2, "days")
          .toJSON()
      },
      atualizar: evento => {
        this.buscar();
        this.setState({ item: evento }, () => {
          setTimeout(() => {
            window.focusElementById("editar");
          }, 100);
        });
      }
    });
  }

  openEndereco() {
    OpenModal(ModalEndereco, {
      ...this.props,
      endereco: this.state.item.endereco,
      decricao: "Adicione um endereço para o seu evento",
      salvarendereco: endereco => {
        this.state.item.endereco = endereco;
        this.setState({
          item: this.state.item
        });
      }
    });
  }

  render() {
    return (
      <View container spacing={16}>
        <View {...styleGlobal.gridListDimacao} item>
          <View type={"Paper"} style={styles.root}>
            <ListView
              load={this.state.load}
              ListHeaderComponent={() => (
                <View style={styles.head}>
                  <ListSubheader component="div">{"Eventos"}</ListSubheader>
                  <TouchableOpacity
                    children={"Ajuda"}
                    style={styles.add}
                    variant="default"
                    onPress={() => {
                      OpenModal(ModalVideoAjuda, {
                        url: "https://www.youtube.com/embed/Mb1rHlM3gWA"
                      });
                    }}
                  />
                  <TouchableOpacity
                    children={"Novo"}
                    style={{ marginLeft: 10 }}
                    variant="outlined"
                    onPress={() => {
                      // this.setState({item:{id:"new",
                      //   data_hora:moment().add(1, "days").toJSON(),
                      //   data_hora_fim:moment().add(2, "days").toJSON()
                      // },value:0})
                      this.novoEvento();
                    }}
                  />
                </View>
              )}
              renderItem={({ item, index }) => {
                return (
                  <ListItem
                    button
                    key={index}
                    style={{ width: "100%" }}
                    selected={this.state.index === index}
                    onClick={event =>
                      this.setState({ item, value: 0, index }, () => {
                        setTimeout(() => {
                          window.focusElementById("editar");
                        }, 100);
                      })
                    }
                  >
                    <Avatar src={item.imagem} style={{ width: 60, height: 60 }}>
                      <Icon name={"event"} />
                    </Avatar>
                    <ListItemText
                      primary={item.nome}
                      secondary={
                        moment(item.data_hora).format("DD/MM/YYYY HH:mm") +
                        " | Vendas: " +
                        Util.parseMoney(item.valor)
                      }
                    />
                    <Icon color="default" name={"create"} />
                  </ListItem>
                );
              }}
              separator
              data={this.state.list}
            />
          </View>
        </View>
        <View {...styleGlobal.gridEditDimacao} style={styles.gridEdit} item>
          {this.state.item && this.state.item.id ? (
            <View type={"Paper"} id="editar" style={styles.view}>
              <View style={styles.head}>
                <ListSubheader style={{ flex: 1 }} component="div">
                  {"Evento"}
                </ListSubheader>
                <Tabs
                  value={this.state.value}
                  onChange={(ii, value) => this.setState({ value })}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab label="Info" />
                  <Tab
                    label="Ingresso"
                    disabled={
                      !this.state.item.id || this.state.item.id == "new"
                    }
                  />
                  <Tab
                    label="Relatorio"
                    disabled={
                      !this.state.item.id || this.state.item.id == "new"
                    }
                  />
                </Tabs>
              </View>
              {this.state.value == 0 ? (
                <View style={styles.view2} xs={12} item>
                  <ImageUpload
                    value={this.state.item.imagem}
                    onChange={value => {
                      this.setState({
                        item: { ...this.state.item, imagem: value }
                      });
                    }}
                    style={styles.image}
                  />
                  <TextInput
                    value={this.state.item.nome}
                    onChange={value => {
                      this.setState({
                        item: { ...this.state.item, nome: value }
                      });
                    }}
                    label={"Nome"}
                  />
                  <TextInput
                    value={this.state.item.descricao}
                    onChange={value => {
                      this.setState({
                        item: { ...this.state.item, descricao: value }
                      });
                    }}
                    multiline={true}
                    label={"Descrição"}
                  />
                  <DatePicker
                    style={styles.datepicker}
                    value={this.state.item.data_hora}
                    onChange={value => {
                      this.setState({
                        item: { ...this.state.item, data_hora: value }
                      });
                    }}
                    datePickerModeAndroid={"calendar"}
                    label={"Data e Hora de Inicio do Evento"}
                  />
                  <DatePicker
                    style={styles.datepicker}
                    value={this.state.item.data_hora_fim}
                    onChange={value => {
                      this.setState({
                        item: { ...this.state.item, data_hora_fim: value }
                      });
                    }}
                    datePickerModeAndroid={"calendar"}
                    label={"Data e Hora de Termino do Anuncio"}
                  />
                  <View style={styleGlobal.Grid} item xs={12}>
                    <TextInput
                      style={styles.textinput31}
                      fullWidth
                      margin="normal"
                      value={
                        this.state.item.endereco
                          ? this.state.item.endereco.endereco
                          : ""
                      }
                      keyboardType={"default"}
                      label={
                        "Endereco (Opcional, caso não seja no seu endereço principal)"
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <TouchableOpacity
                              icon
                              onClick={() => {
                                this.openEndereco();
                              }}
                            >
                              <Icon name={"border_color"} />
                            </TouchableOpacity>
                          </InputAdornment>
                        ),
                        disabled: true
                      }}
                    />
                  </View>
                  <View style={styles.action}>
                    <Switch
                      style={styles.switch}
                      label={"Publico"}
                      value={this.state.item.publico}
                      onChange={value => {
                        this.setState({
                          item: { ...this.state.item, publico: value }
                        });
                      }}
                    />
                    <TouchableOpacity
                      onPress={() => this.salvar()}
                      children={"Salvar"}
                      variant="outlined"
                    ></TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => this.remover()}
                      style={styles.delete}
                      children={"Apagar"}
                      color={"secondary"}
                      variant="outlined"
                    ></TouchableOpacity>
                  </View>
                </View>
              ) : null}
              {this.state.value == 1 ? (
                <IngressoView
                  {...this.props}
                  atualizar={() => this.buscar()}
                  evento={this.state.item}
                />
              ) : null}
              {this.state.value == 2 ? (
                <Relatorio
                  {...this.props}
                  atualizar={() => this.buscar()}
                  evento={this.state.item}
                />
              ) : null}
            </View>
          ) : null}
        </View>
      </View>
    );
  }
}

const styles = {
  root: {},
  gridList: {},
  view2: {
    width: "100%"
  },
  head: {
    height: 60,
    width: "100%",
    padding: 5
  },
  image: {
    maxWidth: 600,
    maxHeight: 600 / (16 / 9),
    height: "100vh",
    margin: "auto",
    borderRadius: 5,
    background: "rgba(0,0,0,0.1)"
  },
  gridEdit: {},
  view: {
    padding: 20,
    paddingTop: 0,
    width: "100%"
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  add: {
    marginLeft: "auto"
  },
  delete: {
    marginLeft: 20
  }
};

export default Eventos;
