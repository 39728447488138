import React, { Component } from "react";

import {
  TouchableOpacity,
  View,
  Icon,
  ListView,
  Switch,
  Text,
  StyleSheet
} from "react-1app";
import { Util } from "../../infra";

export default class Produtos extends Component {
  constructor(props) {
    super(props);
    this.state = { load: true, novo: false, item: {}, lista: [] };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <View type={"Paper"} style={styles.tela}>
        <View style={styles.view1}>
          <Icon
            style={styles.icon7}
            fromFontFamily={"Material Design Icons"}
            name={"package-variant"}
            />
          <Text style={styles.label}>{"PRODUTOS DO CAIXA"}</Text>
        </View>
        <ListView
          style={styles.campanha}
          dataSource={this.props.produtos}
          nativo
          renderRow={(rowData, sectionID, rowID) => {
            return (
              <Cell
                item={rowData}
                heranca={this.state}
                activity={this}
                screenProps={this.props.screenProps}
                navigator={this.state.navigator}
                rowID={rowID}
                sectionID={sectionID}
                />
            );
          }}
          colorSeparator={"rgba(227,227,227,1)"}
          ref={v => (this.campanha = v)}
          separator
          />
      </View>
    );
  }
}

class Cell extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  getValor() {
    return Util.parseMoney(this.props.item.valor);
  }

  getNome() {
    return this.props.item.nome;
  }

  render() {
    return (
      <View style={styles.cell}>
        <View style={styles.view6}>
          <Switch
            style={styles.switch}
            value={this.props.item.add}
            onChange={value => {
              this.props.item.add = value;
              this.setState({ value });
            }}
            />
        </View>
        <View style={styles.view5}>
          <Text style={styles.text2}>{this.getNome()}</Text>
          <Text style={styles.text3}>{this.props.item.tipo}</Text>
        </View>
        <View style={styles.view9}>
          <Text style={styles.text6}>{this.getValor()}</Text>
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  tela: {
    width:"100%"
  },

  view1: {
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    padding: 5
  },
  icon7: {
    color: "rgba(69,191,85,1)",
    fontSize: 20
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal"
  },
  campanha: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection:"column"
  },
  cell: {
    flexDirection: "row",
    alignSelf: "stretch",
    flex: 1,
    padding: 5,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
  },
  view6: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: 3,
    backgroundColor: null,
    marginRight: 8,
    borderRadius: 5,
    minWidth: 50
  },
  switch: {
    alignSelf: "auto"
  },
  view5: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: 3,
    borderRadius: 5
  },
  text2: {
    textAlign: "left",
    alignSelf: "stretch",
    fontWeight: "normal"
  },
  text3: {
    textAlign: "left",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    fontWeight: "normal",
    fontSize: 12
  },
  view9: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: 5
  },
  text6: {
    textAlign: "left",
    alignSelf: "stretch",
    fontWeight: "bold"
  }
});
