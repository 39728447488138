import React, { Component } from "react";

import {
  View,
  Image,
  Text,
  StyleSheet,
  Icon,
  TouchableOpacity,
  TextInput
} from "react-1app";
import { Util } from "../../infra";
import QRCode from "react-1app/lib/QRCode";
import * as CaixaFato from "../../worker/caixa_fato";
import moment from "moment";
import { OpenSnack } from "react-1app/lib/SnackBar";
import styleGlobal from "../../styleGlobal";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
      confirmacao: "",
      item: { codigo: "" },
      user: this.props.screenProps.store.getState().user || {},
      projeto: this.props.screenProps.store.getState().projeto || {}
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.projeto != this.state.projeto) {
        this.setState({
          projeto: store.projeto
        });
      }
    });
    if (this.inputQrcode) this.inputQrcode.focus();
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  buscarDados() {
    this.setState({ load: true });
    CaixaFato.listar(
      { busca: this.state.item.codigo, limit: 1 },
      (dados = { list: [] }, error) => {
        let item = (dados && dados.list && dados.list[0]) || {};
        console.log(item);
        if (error) {
          OpenSnack({
            message: error.msg || "Falha ao buscar item.",
            type: "error"
          });

          return this.setState({ load: false, item: { codigo: "" } }, () => {
            if (this.inputQrcode) this.inputQrcode.focus();
          });
        } else {
          if (!item || !item.id) {
            this.setState({ load: false, item: { codigo: "" } }, () => {
              if (this.inputQrcode) this.inputQrcode.focus();
            });
            return OpenSnack({
              message: "Item não encontrado.",
              type: "error"
            });
          }
          this.setState({ item, load: false }, () => {
            if (this.inputQrcodeConfirmar) this.inputQrcodeConfirmar.focus();
          });
        }
      }
    );
  }

  validarItem() {
    this.setState({ load: true });
    CaixaFato.baixa({ id_caixa_fato: this.state.item.id }, (res, error) => {
      if (error) {
        OpenSnack({
          message:
            error.msg ||
            "Falha ao validar item " +
              (this.state.item.item_nome || this.state.item.nome),
          type: "error"
        });

        if (this.inputQrcode) this.inputQrcode.focus();
        return this.setState({ load: false, item: { codigo: "" } });
      }

      if (this.inputQrcode) this.inputQrcode.focus();

      OpenSnack({
        message:
          "Item " +
          (this.state.item.item_nome || this.state.item.nome) +
          " validado com sucesso.",
        type: "success"
      });
      this.setState({ load: false, item: { codigo: "" } });
    });
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View type={"Paper"} style={styles.content}>
            <Image
              style={styles.image}
              resizeMode={"cover"}
              source={"/img/scanner.png"}
            />
            <View style={styles.view21}>
              <TextInput
                autoFocus
                style={styles.textinput}
                value={this.state.item.codigo}
                onChange={value => {
                  this.setState({
                    item: { ...this.state.item, codigo: value }
                  });
                }}
                keyboardType={"default"}
                inputNative={true}
                onSubmitEditing={() => {
                  this.buscarDados();
                }}
                placeholder={"Escaneie o QRCODE"}
                refInput={v => (this.inputQrcode = v)}
              />
            </View>

            {this.state.item && this.state.item.id ? (
              <View
                style={{
                  ...styles.cell,
                  minHeight: 100,
                  padding: 30,
                  backgroundColor: this.state.item.validado ? "red" : "green"
                }}
              >
                <View style={{ ...styles.view6 }}>
                  <Icon
                    style={
                      this.state.item.validado ? styles.icon3 : styles.icon4
                    }
                    fromFontFamily={"Material Design Icons"}
                    name={
                      this.state.item.validado
                        ? "check-circle"
                        : "checkbox-blank-circle"
                    }
                  />
                </View>
                <View style={{ ...styles.view5 }}>
                  <Text style={{ ...styles.text2, fontSize: 20 }}>
                    {this.state.item.item_nome || this.state.item.nome}
                  </Text>
                  {this.state.item.itens && this.state.item.itens[0] ? (
                    <Text style={{ ...styles.text3, fontSize: 17 }}>
                      {this.state.item.itens.map(item => {
                        return item;
                      })}
                    </Text>
                  ) : null}
                  <Text style={{ ...styles.text3, fontSize: 15 }}>
                    {this.state.item.validado
                      ? "Validado: " +
                        moment(this.state.item.validado).fromNow()
                      : "Criado: " + moment(this.state.item.criado).fromNow()}
                  </Text>
                </View>
                <View style={styles.view9}>
                  <Text
                    style={{ ...styles.text5, color: "white", fontSize: 20 }}
                  >
                    {Util.parseMoney(this.state.item.valor)}
                  </Text>
                </View>
              </View>
            ) : null}

            {this.state.item && this.state.item.validado ? (
              <Text
                style={{
                  ...styles.text2,
                  ...styles.textValidado
                }}
              >
                {"Item já foi validado!"}
              </Text>
            ) : null}

            {this.state.item.id && !this.state.item.validado ? (
              <TouchableOpacity
                style={styles.action}
                variant="outlined"
                onPress={() => {
                  this.validarItem();
                }}
                children={"CONFIRMAR"}
              ></TouchableOpacity>
            ) : null}
            {this.state.item.id || this.state.item.codigo ? (
              <TouchableOpacity
                style={styles.action}
                variant="outlined"
                color={"secondary"}
                onPress={() => {
                  this.setState({ item: { codigo: "" }, confirmacao: "" });
                  if (this.inputQrcode) this.inputQrcode.focus();
                }}
                children={"Limpar"}
              ></TouchableOpacity>
            ) : null}
            {this.state.item.id && !this.state.item.validado ? (
              <View style={styles.view21}>
                <TextInput
                  autoFocus
                  style={styles.textinput1}
                  value={this.state.confirmacao}
                  onChange={value => {
                    this.setState({ confirmacao: value });
                  }}
                  keyboardType={"default"}
                  inputNative={true}
                  onSubmitEditing={() => {
                    if (this.state.confirmacao == "concluido") {
                      this.validarItem();
                    } else {
                      this.setState({ confirmacao: "" });
                    }
                  }}
                  placeholder={"Escaneie o QRCODE de confirmação"}
                  refInput={v => (this.inputQrcodeConfirmar = v)}
                />
              </View>
            ) : null}
            {this.state.item.id && !this.state.item.validado ? (
              <Text style={styles.text}>{"QRCODE de confirmação"}</Text>
            ) : null}
            {this.state.item.id && !this.state.item.validado ? (
              <View style={styles.view41}>
                <QRCode style={styles.view4} value={"concluido"} size={250} />
              </View>
            ) : null}
          </View>
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    padding: 20
  },
  action: {
    marginTop: 10,
    width: "100%"
  },
  textValidado: {
    color: "red",
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 10,
    fontSize: 20
  },
  image: {
    width: 100,
    height: 100,
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    margin: "auto"
  },
  cell: {
    flexDirection: "row",
    alignSelf: "stretch",
    flex: 1,
    padding: 5,
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    backgroundColor: "#eee",
    borderRadius: 5,
    marginTop: 20,
    minHeight: 50
  },
  view6: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: 3,
    backgroundColor: "rgba(0,0,0,1)",
    marginRight: 8,
    borderRadius: 5,
    minWidth: 50
  },
  icon3: {
    color: "rgba(255,255,255,1)",
    fontSize: 25
  },
  icon4: {
    color: "rgba(150,150,145,1)",
    fontSize: 25
  },
  view5: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "rgba(255,255,255,1)",
    padding: 3,
    borderRadius: 5
  },
  text2: {
    textAlign: "left",
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    fontWeight: "normal"
  },
  text3: {
    textAlign: "left",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    fontWeight: "normal",
    fontSize: 12
  },
  view9: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: 5
  },
  text5: {
    textAlign: "right",
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    fontWeight: "bold"
  },
  view2: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    backgroundColor: "rgba(0,0,0,0.2)",
    padding: 10,
    borderRadius: 5
  },
  textinput: {
    alignSelf: "stretch",
    textAlign: "center",
    fontWeight: "normal",
    fontSize: 20
  },
  view21: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    backgroundColor: "#eee",
    padding: 10,
    borderRadius: 5,
    marginTop: 20,
    marginBottom: 20
  },
  view41: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  textinput1: {
    alignSelf: "stretch",
    textAlign: "center",
    fontWeight: "normal",
    fontSize: 20
  },
  text: {
    textAlign: "center",
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    fontWeight: "normal",
    fontSize: 12
  },
  view4: {
    margin: "auto",
    alignItems: "flex-start",
    width: 450,
    height: 450
  }
});
