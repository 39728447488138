import React, { Component } from "react";

import {
  TouchableOpacity,
  View,
  Text,
  StyleSheet,
  OpenSnack,
  TextInput
} from "react-1app";
import styleGlobal from "../../styleGlobal";
import md5 from "md5";
import * as Configuracoes from "../../worker/configuracoes";

export default class NotificacaoClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.screenProps.store.getState().user,
      casa: {
        nome: "",
        cnpj: ""
      },
      load: false
    };
  }

  confirmarCriacao() {
    let senha = prompt(
      "Confirmar criação de uma nva casa? Para confirmar digite sua senha."
    );
    if (senha && md5(senha) == this.state.user.password) {
      this.criar();
    } else {
      OpenSnack({
        message: "Senha inválida!",
        type: "error"
      });
    }
  }

  criar() {
    this.setState({ load: true });
    Configuracoes.criar({ ...this.state.casa }, (retorno, erro) => {
      this.setState({ load: false });
      if (erro) {
        return OpenSnack({
          message: erro.msg || "Erro ao enviar casa...",
          type: "error"
        });
      }
      OpenSnack({
        message: "Casa Criada",
        type: "success"
      });
      this.props.closeModal();
      this.props.atualizar(retorno);
    });
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View style={styles.tela} type={"Paper"}>
            <Text style={styles.txNotif}>{"Criar uma nova casa"}</Text>
            <View style={styles.view5}>
              <TextInput
                value={this.state.casa.nome}
                onChange={value => {
                  this.state.casa.nome = value;

                  this.setState({
                    casa: this.state.casa
                  });
                }}
                keyboardType={"default"}
                label={"Nome"}
              />
            </View>
            <View style={styles.view51}>
              <TextInput
                value={this.state.casa.cnpj}
                onChange={value => {
                  this.state.casa.cnpj = value;
                  this.setState({
                    casa: this.state.casa
                  });
                }}
                keyboardType={"default"}
                label={"CNPJ"}
                mask={"99.999.999/9999-99"}
              />
            </View>
            <View style={styles.action}>
              <TouchableOpacity
                onPress={() => {
                  this.confirmarCriacao();
                }}
                load={this.state.load}
                children={"Criar"}
                variant="outlined"
              ></TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.props.closeModal()}
                style={styles.delete}
                children={"Fechar"}
                color={"default"}
                variant="outlined"
              ></TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  tela: {
    padding: 20,
    marginTop: 50
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  },
  txNotif: {
    fontSize: 20,
    textAlign: "center",
    marginBottom: 10
  },
  view5: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row"
  },

  text3: {
    textAlign: "center",
    color: "rgba(150,150,145,1)",
    justifyContent: "flex-end",
    alignSelf: "stretch",
    fontWeight: "normal"
  },
  view51: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row"
  },
  text31: {
    textAlign: "center",
    color: "rgba(150,150,145,1)",
    justifyContent: "flex-end",
    alignSelf: "stretch",
    fontWeight: "normal"
  }
});
