import { Cloud } from "../infra";
import cookie from "react-cookies";
import md5 from "md5";

export function atualizar(user, callback) {
  Cloud.get("login/atualizar", { token: user.token }, (res, error) => {
    if (!error && res.id) cacheUser(res);
    if (callback) callback(res, error);
  });
}

export function criarConta(data, callback) {
  data.senha = data.password;
  data.password = md5(data.password);
  data.token_afiliado = getTokenAfiliado();
  Cloud.get("login/email/criar", data, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function recuperarSenha(login, callback) {
  Cloud.get("login/email/recuperar", { email: login }, (res, error) => {
    if (callback) callback(res, error);
  });
}
export function login({email, senha}, callback) {
let password = md5(senha);
  Cloud.get("login/email", { email, password: password }, (res, error) => {
    // console.log(res);
    if (!error && res.id) cacheUser(res);
    if (callback) callback(res, error);
  });
}
export function loginFacebook(token, callback) {
  Cloud.get("login/facebook", { token: token }, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function loginGoogle(nome, email, foto, idGoogle, callback) {
  Cloud.get("login/google", { nome, email, foto, idGoogle }, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function loadUser(callback) {
  try {
    var user = cookie.load("reduxUser");
    Cloud.setTokenUser(user.token);
    Cloud.setTokenEmpresa(user.token_projeto);
    return user;
  } catch (e) {
    if (callback) callback(null);
  }
}
export function getTokenAfiliado() {
  try {
    var data = cookie.load("afiliado_1mk");
    return data;
  } catch (e) {}
}
export function cacheUser(user) {
  try {
    cookie.save("reduxUser", user, {
      path: "/"
    });
  } catch (e) {}
}

export function cacheEmpresa(id_temp) {
  try {
    cookie.save("empresa", id_temp, {
      path: "/"
    });
  } catch (e) {}
}

export function loadEmpresa() {
  try {
    var data = cookie.load("empresa");
    console.log(data);
    return data;
  } catch (e) {}
}
