export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_USER = "SET_USER";
export const SET_EVENTO = "EVENTO";
export const SET_INGRESSO = "SET_INGRESSO"
export const BEBIDA_LIST = "BEBIDA_LIST"
export const SET_BEBIDA = "SET_BEBIDA"
export const EQUIPE_LIST = "EQUIPE_LIST"
export const EMPRESA_CONFIG = "EMPRESA_CONFIG"
export const USER = "USER";
export const SET_THEMA = "SET_THEMA";
