import React from 'react';

import InputAdornment from "@material-ui/core/InputAdornment";
import { Util } from "../../infra"
import { getEnderecosCep, getEnderecosGoogle } from '../../worker/endereco';
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import TextInput from "react-1app/lib/TextInput";
import StyleSheet from "react-1app/lib/StyleSheet";
import Icon from "react-1app/lib/Icon";
import Progress from "react-1app/lib/Progress";
import Switch from "react-1app/lib/Switch";

class ModalEndereco extends React.Component {
  constructor(props) {
    super(props)
    this.state = {load:false}
  }

  _getEndereco(cep='') {
    if (cep.length > 7) {
      this.setState({load:true},()=>{
        var dados = cep.replace(/[^\d]+/g, "");
        getEnderecosCep(dados, (ret) => {
          this.setState({load:false});
          if (ret && !ret.erro) {
            this.props.onChange({
              ...this.props.endereco,
              cep: ret.cep,
              cidade: ret.cidade,
              rua: ret.rua,
              estado: ret.estado,
              uf: ret.estado,
              bairro: ret.bairro,
              complemento: ret.complemento
            })
          }
        })
      })
    }
  }


  handleChange = (name, mask, limit, font) => value => {
    let endereco = this.props.endereco
    this.props.onChange({
      ...endereco,
      [name]: Util.maskFormate(value, mask, limit, font)
    })
  }


  _atualizarCoordenadas(endereco) {
    var endereco = this.montarEndereco(endereco)
    getEnderecosGoogle(endereco, (lat, lng, dados) => {
      this.props.onChange({
        ...this.props.endereco,
        latitude: lat,
        longitude: lng
      });
    });
  }

  montarEndereco(endereco) {
    var temp = endereco.rua ? endereco.rua + ", " : "";
    temp += endereco.numero ? endereco.numero + ", " : "";
    temp += endereco.bairro ? endereco.bairro + ", " : "";
    temp += endereco.cidade ? endereco.cidade + ", " : "";
    temp += endereco.estado ? endereco.estado + ", " : "";
    temp += endereco.cep ? endereco.cep + ", " : "";
    return temp;
  }

  render() {
    return (
      <View container justify="center" spacing={8}>
        <View item xs={12} sm={12} md={12}>
          <TextInput
            fullWidth margin="normal"
            value={this.props.endereco.cep}
            onChange={this.handleChange("cep", "99999-999")}
            keyboardType={"default"}
            label={"CEP"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {this.state.load ? (
                    <Progress />
                  ) : (
                    <TouchableOpacity icon
                      onClick={() => {
                        if (this.props.endereco.cep) {
                          this._getEndereco(this.props.endereco.cep);
                        }
                      }}
                      >
                      <Icon name={"search"} />
                    </TouchableOpacity>
                  )}
                </InputAdornment>
              ),
              placeholder: "Adicione o CEP e clique em buscar",
            }}
            />
        </View>
        <View item xs={12} sm={8} md={9}>
          <TextInput
            fullWidth margin="normal"
            value={this.props.endereco.rua}
            onChange={this.handleChange("rua")}
            keyboardType={"default"}
            label={"Rua"}
            />
        </View>
        <View item xs={12} sm={4} md={3}>
          <TextInput
            fullWidth margin="normal"
            value={this.props.endereco.numero}
            onChange={this.handleChange("numero")}
            keyboardType={"default"}
            label={"Número"}
            />
        </View>
        <View item xs={12} sm={6} md={6}>
          <TextInput
            fullWidth margin="normal"
            value={this.props.endereco.cidade}
            onChange={this.handleChange("cidade")}
            keyboardType={"default"}
            label={"Cidade"}
            />
        </View>
        <View item xs={12} sm={6} md={6}>
          <TextInput
            fullWidth margin="normal"
            value={this.props.endereco.bairro}
            onChange={this.handleChange("bairro")}
            keyboardType={"default"}
            label={"Bairro"}
            />
        </View>
        <View item xs={12} sm={12} md={12}>
          <TextInput
            fullWidth margin="normal"
            value={this.props.endereco.complemento}
            onChange={this.handleChange("complemento")}
            keyboardType={"default"}
            label={"Complemento"}
            />
        </View>
        {!this.props.notGeo?(
          <View item xs={12} sm={12} md={12}>
            <View container justify="center" spacing={8}>
              {this.state.manual ? (
                <View item xs={7} style={{ display: "flex" }}>
                  <TextInput
                    fullWidth
                    margin="normal"
                    value={this.props.endereco.latitude}
                    onChange={this.handleChange("latitude", "doub")}
                    keyboardType={"default"}
                    label={"Latitude"}
                    />

                  <TextInput
                    fullWidth
                    margin="normal"
                    value={this.props.endereco.longitude}
                    onChange={this.handleChange("longitude", "doub")}
                    keyboardType={"default"}
                    label={"Longitude"}
                    />
                </View>
              ) : (
                <View item xs={7} style={{ display: "flex" }}>
                  <TextInput
                    fullWidth
                    margin="normal"
                    value={this.props.endereco.latitude}
                    onChange={this.handleChange("latitude", "doub")}
                    keyboardType={"default"}
                    disabled
                    label={"Latitude"}
                    />
                  <TextInput
                    fullWidth
                    disabled
                    margin="normal"
                    value={this.props.endereco.longitude}
                    onChange={this.handleChange("longitude", "doub")}
                    keyboardType={"default"}
                    label={"Longitude"}
                    />
                </View>
              )}
              <View item xs={3} style={{ display: "flex" }}>
                <TouchableOpacity
                  color="green"
                  onClick={() => {
                    this._atualizarCoordenadas(this.props.endereco);
                  }}
                  >
                  Atualizar Coordenadas
                </TouchableOpacity>
              </View>

              <View item xs={2} style={{ display: "flex" }}>
                <Switch
                  style={styles.switch}
                  value={this.state.manual}
                  onChange={value => {
                    this.state.manual = value;
                    this.setState({ manual: this.state.manual });
                  }}
                  label={"Manual"}
                  />
              </View>
            </View>
          </View>
        ):null}
      </View>
    )
  }
}

var styles = StyleSheet.create({
  textinput: {
    marginTop: 10,
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    textAlign: "left",
    fontWeight: "normal"
  },

})
ModalEndereco.defaultProps={
  endereco:{}
}
export default ModalEndereco;
