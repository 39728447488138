import React, { Component } from "react";

import {
  TouchableOpacity,
  View,
  Icon,
  SelectInput,
  StyleSheet,
  TextInput,
  OpenSnack,
  Table as TableSelect
} from "react-1app";
import moment from "moment";
import * as Acesso from "../../worker/acesso";
import ModalVideoAjuda from "../ModalVideoAjuda";
import { OpenModal } from "react-1app/lib/PgModal";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      load: true,
      totalLista: 0,
      acesso: {},
      parametros: {
        limit: 10,
        page: 0,
        paginacao: true
      }
    };
  }

  componentDidMount() {
    this.buscar();
  }

  buscar() {
    Acesso.listar(
      this.state.parametros,
      (dados = { list: [], total: 0 }, error) => {
        this.setState({
          lista: dados.list,
          totalLista: dados.total,
          load: false
        });
      }
    );
  }

  salvar() {
    this.setState({ load: true });
    if (this.state.acesso.id) {
      Acesso.atualizar(this.state.acesso, (acesso, error) => {
        this.onRetorno(acesso, error);
      });
    } else {
      Acesso.salvar({ ...this.state.acesso }, (acesso, error) => {
        this.onRetorno(acesso, error);
      });
    }
  }

  onRetorno(acesso = {}, error) {
    let isNew = !this.state.acesso.id;
    if (error) {
      OpenSnack({
        message: error.msg || `Erro ao ${isNew ? "criada" : "editar"} Acesso!`,
        type: "error"
      });
      this.setState({ load: false });
    } else {
      this.setState(
        {
          load: false,
          acesso: { endereco: {}, ...acesso }
        },
        () => {
          OpenSnack({
            message: `Acesso ${isNew ? "criada" : "atualizado"} com sucesso!`,
            type: "success"
          });
          this.buscar();
        }
      );
    }
  }

  componentWillUnmount() {}

  render() {
    return (
      <View style={styles.view2} container spacing={16}>
        <View style={styles.view3} xs={12} md={6} item>
          <TableSelect
            tableHead={["Nome", "Criado", "Ações"]}
            tableData={this.state.lista.map(item => [
              item.nome,
              moment(item.criado).format("DD/MM/YYYY HH:mm"),
              <View style={styles.view2}>
                <TouchableOpacity
                  icon
                  onPress={() => this.setState({ acesso: item })}
                >
                  <Icon fromFontFamily={"Material Icons"} name={"create"} />
                </TouchableOpacity>
              </View>
            ])}
            title={"Lista de acessos"}
            tablePagination
            count={Number(this.state.totalLista)}
            load={this.state.load}
            page={this.state.parametros.page}
            rowsPerPage={this.state.parametros.limit}
            handleChangePage={(event, page) => {
              this.setState(
                {
                  parametros: Object.assign(this.state.parametros, { page }),
                  load: true
                },
                this.buscar()
              );
            }}
            handleChangeRowsPerPage={event => {
              this.setState(
                {
                  parametros: Object.assign(this.state.parametros, {
                    limit: event.target.value,
                    page: 0
                  }),
                  load: true
                },
                this.buscar()
              );
            }}
            actionBar={
              <View>
                <TouchableOpacity
                  children={"Ajuda"}
                  style={styles.add}
                  variant="default"
                  onPress={() => {
                    OpenModal(ModalVideoAjuda, {
                      url: "https://www.youtube.com/embed/H8bLHQ8J0Ec"
                    });
                  }}
                />
                <TouchableOpacity
                  onPress={() =>
                    this.setState({ acesso: { cnpj: this.props.empresa.cnpj } })
                  }
                  variant="outlined"
                  children={"Novo"}
                ></TouchableOpacity>
              </View>
            }
          />
        </View>
        {this.state.acesso && this.state.acesso.cnpj ? (
          <View style={styles.view3} xs={12} md={6} item>
            <View type={"Paper"} style={{ padding: 20 }}>
              <View style={styles.view4}>
                <TextInput
                  value={this.state.acesso.nome}
                  onChange={value => {
                    this.state.acesso.nome = value;
                    this.setState({ acesso: this.state.acesso });
                  }}
                  keyboardType={"default"}
                  label={"Nome"}
                />
                <SelectInput
                  value={this.state.acesso.tipo}
                  onChange={value => {
                    this.state.acesso.tipo = value;
                    this.setState({ acesso: this.state.acesso });
                  }}
                  keyboardType={"default"}
                  label={"Tipo"}
                  list={["pdv", "controle"]}
                />
                {this.state.acesso.tipo == "pdv" ? (
                  <SelectInput
                    value={this.state.acesso.printer}
                    onChange={value => {
                      this.state.acesso.printer = value;
                      this.setState({ acesso: this.state.acesso });
                    }}
                    keyboardType={"default"}
                    label={"Layout da Impressora"}
                    list={[
                      { value: 1, nome: "Layout" },
                      { value: 2, nome: "Layout 2" }
                    ]}
                    key
                  />
                ) : null}
                <TextInput
                  value={this.state.acesso.cnpj}
                  onChange={value => {
                    this.state.acesso.cnpj = value;
                    this.setState({ acesso: this.state.acesso });
                  }}
                  keyboardType={"default"}
                  label={"CNPJ"}
                  disabled={true}
                />
                <TextInput
                  value={this.state.acesso.codigo}
                  onChange={value => {
                    this.state.acesso.codigo = value;
                    this.setState({ acesso: this.state.acesso });
                  }}
                  keyboardType={"default"}
                  label={"Senha"}
                />
              </View>
              <View
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 15
                }}
                xs={"12"}
                item
              >
                <TouchableOpacity
                  onClick={() => {
                    this.salvar();
                  }}
                  variant="outlined"
                  children={"Salvar"}
                ></TouchableOpacity>
              </View>
            </View>
          </View>
        ) : null}
      </View>
    );
  }
}

var styles = StyleSheet.create({
  view2: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  buttonActio: {
    alignSelf: "auto",
    alignItems: "center",
    justifyContent: "center"
  },
  IconButtonActio: {
    color: "rgba(0,0,0,1)",
    fontSize: 25,
    margin: 15
  },
  button2: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    padding: 15,
    borderRadius: 10,
    borderStyle: "solid",
    borderWidth: 1
  },
  text2: {
    textAlign: "left",
    alignSelf: "stretch",
    fontWeight: "normal",
    fontSize: 17
  },
  view3: {},
  content2: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "flex-start"
  },

  imageBanner: {
    backgroundColor: "rgba(227,227,227,1)",
    height: 200,
    width: 350,
    marginTop: 20,
    alignSelf: "auto"
  },
  textinput3: {
    alignSelf: "stretch",
    textAlign: "left",
    fontWeight: "normal",
    marginTop: 5
  },
  view4: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column"
  }
});
