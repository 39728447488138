import React from 'react';

import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from "recharts";
import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer';

const data = [
      {name: 'Page A', uv: 4000, pv: 2400, amt: 2400},
      {name: 'Page B', uv: 3000, pv: 1398, amt: 2210},
      {name: 'Page C', uv: 2000, pv: 9800, amt: 2290},
      {name: 'Page D', uv: 2780, pv: 3908, amt: 2000},
      {name: 'Page E', uv: 1890, pv: 4800, amt: 2181},
      {name: 'Page F', uv: 2390, pv: 3800, amt: 2500},
      {name: 'Page G', uv: 3490, pv: 4300, amt: 2100},
];
const Key=[{dataKey:"pv",fill:"#8884d8"},{dataKey:"uv",fill:"#82ca9d"}]
 const StackedBarChart = ({lista=data,dataKey=Key})=> {
   console.log(lista);
  	return (
      <ResponsiveContainer width="99%" height={320}>
    	<BarChart data={lista}
            margin={{top: 20, right: 30, left: 20, bottom: 5}}>
       <CartesianGrid strokeDasharray="3 3"/>
       <XAxis dataKey="name" stroke="#fff"  hide={lista.length > 15}/>
       <YAxis  fill="#fff" stroke="#fff"/>
       <Tooltip/>
       <Legend />
         {dataKey.map(line=>(
           <Bar stackId="a" fill="#fff" {...line} />
         ))}
      </BarChart>
      </ResponsiveContainer>
    );
  }
export default StackedBarChart
