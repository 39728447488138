import React, { Component } from "react";

import {
  TouchableOpacity,
  View,
  Icon,
  Image,
  StyleSheet,
  Table,
  Switch,
  OpenModal,
  Text
} from "react-1app";
import * as Evento from "../../worker/evento";
import moment from "moment";
import EventoFotos from "./Fotos.js";
import { Util } from "../../infra";
import RelatorioBebidas from "./RelatorioBebidas";
import { OpenSnack } from "react-1app/lib/SnackBar";
import Relatorio from "../evento/Relatorio";
import styleGlobal from "../../styleGlobal";

export default class Listar_Finalizados extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      load: true,
      limite: 6,
      pagina: 0,
      yapay: 0,
      totalLista: 0,
      parametros: {
        limit: 10,
        page: 0
      },
      evento: {},
      openFotos: false,
      openRelatorios: false,
      projeto: this.props.screenProps.store.getState().projeto || {}
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.projeto != this.state.projeto)
        this.setState({ projeto: store.projeto, anchorEl: null }, () => {
          this.buscar();
        });
    });
    this.buscar();
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  buscar() {
    var regras = this.props.screenProps.store.getState().regras;
    if (!regras) return;
    Evento.listarPassados(
      { ...this.state.parametros, publico: regras.realatorioIngresso },
      (dados = { list: [], total: 0, yapay: 3.99 }, error) => {
        console.log(dados.list);
        this.setState({
          lista: dados.list,
          totalLista: dados.total,
          yapay: dados.yapay,
          load: false
        });
      }
    );
  }

  getAlbuns(item) {
    OpenModal(EventoFotos, {
      evento: item,
      atualizar: () => this.buscar()
    });
    // this.setState({ evento: item, openFotos: true });
  }
  openInfo(item) {
    OpenModal(props => (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} md={9} xl={6} item>
          <View type={"Paper"} id="editar" style={{ marginTop: 20 }}>
            <View style={styles.head}>
              <Text style={[styleGlobal.SubHeader, { marginRight: "auto" }]}>
                {"Relatorio de ingresso"}
              </Text>
              <TouchableOpacity
                style={styles.action}
                onPress={() => {
                  props.closeModal();
                }}
              >
                <Icon fromFontFamily={"Material Design Icons"} name={"close"} />
              </TouchableOpacity>
            </View>
            <Relatorio {...this.props} evento={item} />
          </View>
        </View>
      </View>
    ));
    // OpenModal(RelatorioIngresso, {
    //   evento: item,
    //   atualizar: () => this.buscar()
    // });
    // this.setState({ evento: item, openRelatorios: true });
    // this.props.screenProps.store.dispatch(actions.setEvento(item));
  }

  openInfo2(item) {
    OpenModal(props => (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} md={9} xl={6} item>
          <View type={"Paper"} id="editar" style={{ marginTop: 20 }}>
            <View style={styles.head}>
              <Text style={[styleGlobal.SubHeader, { marginRight: "auto" }]}>
                {"Relatorio de produtos"}
              </Text>
              <TouchableOpacity
                style={styles.action}
                onPress={() => {
                  props.closeModal();
                }}
              >
                <Icon fromFontFamily={"Material Design Icons"} name={"close"} />
              </TouchableOpacity>
            </View>
            <RelatorioBebidas {...this.props} evento={item} />
          </View>
        </View>
      </View>
    ));
    // OpenModal(RelatorioIngresso, {
    //   evento: item,
    //   atualizar: () => this.buscar()
    // });
    // this.setState({ evento: item, openRelatorios: true });
    // this.props.screenProps.store.dispatch(actions.setEvento(item));
  }

  mudarStatus(item) {
    if (
      window.confirm(
        `Deseja ${
          item.publico ? "dês" : ""
        } publicar este evento no app e relatório de ingresso do usuário padrão`
      )
    ) {
      this.setState({ load: true });
      Evento.atualizar(
        { id: item.id, publico: !item.publico },
        this.state.user,
        (evento, error) => {
          this.onRes(evento, error, "atualiza");
        }
      );
    }
  }

  onRes(evento, error, tag) {
    if (error) {
      OpenSnack({ message: "Erro ao " + tag + "r evento!", type: "error" });
      this.setState({ load: false });
    } else {
      this.buscar();
      OpenSnack({
        message: "Evento ao " + tag + "do com sucesso!",
        type: "success"
      });
    }
  }

  isPermicao() {
    return (
      (this.props.screenProps.store.getState().regras &&
        this.props.screenProps.store.getState().regras.realatorio) ||
      (this.props.screenProps.store.getState().user &&
        this.props.screenProps.store.getState().user.adm)
    );
  }

  render() {
    return (
      <View style={styles.fragment1}>
        <Table
          tableHead={[
            "",
            "Nome",
            "Inicio",
            "Vendidos",
            "V. Total",
            "Fotos",
            "Ações"
          ]}
          tableData={this.state.lista.map(item => [
            <Image
              style={styles.image}
              resizeMode={"cover"}
              source={{ uri: item.imagem }}
            />,
            item.nome,
            moment(item.data_hora).format("DD/MM/YYYY HH:mm"),
            item.ingresso + " / " + (item.usuarios ? item.usuarios : "0"),
            Util.parseMoney(item.valor),
            (item.fotos && item.fotos.length) || 0,
            <View style={styles.view2}>
              {this.isPermicao() ? (
                <TouchableOpacity
                  style={styles.button12}
                  onClick={() => this.getAlbuns(item)}
                  icon
                >
                  <Icon
                    style={styles.icon1}
                    fromFontFamily={"Material Design Icons"}
                    name={"camera"}
                  />
                </TouchableOpacity>
              ) : null}
              <TouchableOpacity
                style={styles.button12}
                onClick={() => this.openInfo(item)}
                icon
              >
                <Icon
                  style={styles.icon1}
                  fromFontFamily={"Material Design Icons"}
                  name={"information-outline"}
                />
              </TouchableOpacity>
              {this.isPermicao() ? (
                <TouchableOpacity
                  style={styles.button12}
                  onClick={() => this.openInfo2(item)}
                  icon
                >
                  <Icon
                    style={styles.icon1}
                    fromFontFamily={"Material Icons"}
                    name={"assessment"}
                  />
                </TouchableOpacity>
              ) : null}
              {this.isPermicao() ? (
                <Switch
                  style={styles.switch}
                  label={"Publico"}
                  value={item.publico}
                  onChange={value => {
                    this.mudarStatus(item);
                  }}
                />
              ) : null}
            </View>
          ])}
          title={"Lista de Eventos Finalizados"}
          tablePagination
          count={Number(this.state.totalLista)}
          load={this.state.load}
          page={this.state.parametros.page}
          rowsPerPage={this.state.parametros.limit}
          handleChangePage={(event, page) => {
            this.setState(
              {
                parametros: Object.assign(this.state.parametros, { page }),
                load: true
              },
              this.buscar()
            );
          }}
          handleChangeRowsPerPage={event => {
            this.setState(
              {
                parametros: Object.assign(this.state.parametros, {
                  limit: event.target.value,
                  page: 0
                }),
                load: true
              },
              this.buscar()
            );
          }}
        />
      </View>
    );
  }
}

var styles = StyleSheet.create({
  fragment1: {
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  image: {
    width: 50,
    height: 50,
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    borderRadius: 50
  },
  view2: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  button12: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "row",
    marginTop: 10,
    marginRight: 10,
    backgroundColor: null,
    width: 50
  },
  icon1: {
    color: "rgba(150,150,145,1)",
    fontSize: 25
  },
  switch: {
    height: 64
  },
  button1: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "row",
    marginTop: 10,
    marginRight: 10,
    backgroundColor: null,
    width: 50
  },
  icon3: {
    color: "rgba(150,150,145,1)",
    fontSize: 25
  },
  icon: {
    color: "rgba(150,150,145,1)",
    fontSize: 25
  }
});
