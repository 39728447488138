import { Cloud } from "../infra";

export function salvar(bebida, callback){
  Cloud.post('categoria', bebida, (res,error) => {
    if(callback) callback(res,error);
  })
}

export function atualizar(bebida, callback){
  Cloud.put('categoria/' + bebida.id, bebida, (res,error) => {
    if(callback) callback(res,error);
  })
}

export function remover(id, callback){
  Cloud.delete('categoria/' + id, {}, (res,error) => {
    if(callback) callback(res,error);
  })
}

export function listar(bebida, callback){
  Cloud.get('categoria', bebida, (res,error) => {
    if(callback) callback(res,error);
  })
}
