import { StyleSheet } from "react-1app";
export default StyleSheet.create({
  Componente: {
    // backgroundColor: "rgba(255,255,255,1)",
    alignSelf: "stretch",
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    padding: 20
  },
  CardBody: {
    // boxShadow:
    //   "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
    borderRadius: 4,
    display: "block",
    // background: "rgb(255, 255, 255)",
    padding: 10,
    paddingTop: 0,
    flex: 1
  },
  CardHeader: {
    flexDirection: "row",
    color: "#fff",
    padding: "0.75rem 1.25rem",
    marginBottom: 10,
    zIndex: "3 !important",
    boxShadow:
      "0 12px 20px -10px rgba(22, 0, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(22, 0, 0, 0.2)",
    // background: "linear-gradient(#df312f,#e53838)",
    background: "rgb(52,52,52)",
    position: "relative",
    top: -15,
    borderRadius: 4,
    display: "block"
  },
  Paper: {
    boxShadow:
      "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
    borderRadius: 4,
    display: "block",
    background: "rgb(255, 255, 255)"
  },
  Grid: {
    // alignSelf: "stretch",
    // flexDirection: "column",
  },
  BotaoCentralizado: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20
  },
  BotaoVoltarProximo: {
    display: "flex",
    justifyContent: "center",
    height: 50,
    marginRight: 10
  },
  TextoCardHeader: {
    textAlign: "center",
    color: "rgba(255,255,255,1)",
    alignSelf: "stretch",
    fontWeight: "normal",
    fontSize: 20
  },
  BotaoPersonalizado: {
    backgroundColor: "#d8ad16",
    color: "#fff",
    borderRadius: 15,
    alignSelf: "flex-end",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    //   marginTop: 15,
    flexDirection: "column",
    width: 150
  },
  SubHeader: {
    paddingLeft: 24,
    paddingRight: 24,
    height: 48,
    // alignSelf: "stretch",
    color: "rgba(150,150,150,1)",
    fontSize: "0.9rem",
    display: "flex",
    alignItems: "center"
  },
  subTabLabel: {
    textAlign: "left",
    // color: "rgba(0,0,0,1)",
    // alignSelf: "stretch",
    fontWeight: "normal",
    fontSize: 20
  },
  subTabIcon: {
    // color: "rgba(0,0,0,1)",
    fontSize: 25
  },
  progress: {
    height: 25,
    width: 25
  },
  textinput: {
    alignSelf: "stretch",
    textAlign: "left",
    fontWeight: "normal"
    // marginTop:8
  },
  gridListDimacao: {
    xs: 12,
    md: 5,
    xl: 4
  },
  gridEditDimacao: {
    xs: 12,
    md: 7,
    xl: 5
  }
});
