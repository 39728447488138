import React, { Component } from "react";

import {
  View,
  TouchableOpacity,
  SelectInput,
  Switch,
  Text,
  TextInput,
  StyleSheet,
  // DatePicker,
  OpenSnack,
  OpenDialog
} from "react-1app";
import * as ItemCaixa from "../../worker/item_caixa";
import { Util } from "../../infra";
import styleGlobal from "../../styleGlobal";
import {DatePickerUm as DatePicker} from "../../components/DatePicker";
import Produtos from "./Produtos";

import * as ItemProduto from "../../worker/item_produto";

export default class AddItemCaixa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categorias:this.props.screenProps.store.getState().projeto.categorias|| [
        "cervejas",
        "sem-alcool",
        "doses nacionais",
        "doses importadas",
        "garrafas e combos",
        "bar",
        "drinks",
        "outros",
        "comida"
      ],
      itemCaixa: { ...this.props.itemCaixa } || { produtos: [] }
    };
  }

  componentDidMount() {
    this.buscarDados();
  }

  buscarDados() {
    if (!this.props.itemCaixa || !this.state.itemCaixa.id) return;
    ItemProduto.listar(
      { id_item_caixa: this.props.itemCaixa.id, limit: 1000 },
      (dados = { list: [], total: 0 }, error) => {
        console.log(dados);
        this.state.itemCaixa.produtos = dados.list || [];
        this.setState({ itemCaixa: this.state.itemCaixa });
      }
    );
  }

  componentWillUnmount() {}

  salvar(callback, notNotif) {
    if (this.state.itemCaixa.id) {
      ItemCaixa.atualizar(this.state.itemCaixa, (itemCaixa, error) => {
        if (error) {
          OpenSnack({ message: error.msg || "Erro ao salvar!", type: "error" });
        } else {
          this.props.activity.setState({ itemCaixa });
          this.props.atualizar();
          this.buscarDados();
          if (!notNotif) {
            this.props.closeModal();
            OpenSnack({
              message: "Atualizado com sucesso!",
              type: "success"
            });
          }
        }
      });
    } else {
      ItemCaixa.salvar(this.state.itemCaixa, (itemCaixa, error) => {
        if (error) {
          OpenSnack({ message: error.msg || "Erro ao salvar!", type: "error" });
        } else {
          this.props.activity.setState({ itemCaixa });
          this.props.atualizar();
          this.buscarDados();
          this.props.closeModal();
          OpenSnack({ message: "Salvo com sucesso!", type: "success" });
          if (callback) callback();
        }
      });
    }
  }

  atualizar() {
    this.props.atualizar();
  }

  deletar() {
    ItemCaixa.remover(this.state.itemCaixa.id, (itemCaixa, error) => {
      if (error) {
        OpenSnack({ message: error.msg || "Erro ao deletar!", type: "error" });
      } else {
        this.setState({ itemCaixa, load: false });
        this.props.atualizar();
        this.props.closeModal();
        OpenSnack({ message: "Deletado com sucesso!", type: "success" });
      }
    });
  }

  validarLimparFotos() {
    OpenDialog({
      title: "Deletar",
      description: "Confirmação para deletar este item!",
      action: [
        {
          label: "CONFIRMAR",
          onClick: () => {
            this.deletar();
          },
          color: "secondary"
        },
        { label: "CANCELAR" }
      ]
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.itemCaixa != nextProps.itemCaixa)
      this.setState(
        { itemCaixa: { ...nextProps.itemCaixa, produtos: [] } },
        () => {
          this.buscarDados();
        }
      );
    return true;
  }

  getValor() {
    let valor = 0;
    if (this.state.itemCaixa && this.state.itemCaixa.produtos)
      this.state.itemCaixa.produtos.map(item => {
        valor +=
          Number(item.valor) * (item.mutiplicar ? Number(item.quantidade) : 1);
      });
    return Util.parseNumeroDuasCasas(valor || this.state.itemCaixa.valor);
  }

  render() {
    if (!this.state.itemCaixa.produtos) this.state.itemCaixa.produtos = [];
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View style={styles.tela} type={"Paper"}>
            <View style={styles.view2}>
              <Text style={styles.label}>{"ITEM DO CAIXA"}</Text>

              <Switch
                value={this.state.itemCaixa.app}
                onChange={value => {
                  this.state.itemCaixa.app = value;
                  this.setState({ item: this.state.item });
                }}
                label={"APP"}
              />
              <Switch
                value={this.state.itemCaixa.pdv}
                onChange={value => {
                  this.state.itemCaixa.pdv = value;
                  this.setState({ item: this.state.item });
                }}
                label={"PDV"}
              />
            </View>
            <View style={styles.view2}>
              <TextInput
                style={styles.textinput1}
                value={this.state.itemCaixa.nome}
                onChange={value => {
                  this.state.itemCaixa.nome = value;
                  this.setState({ item: this.state.item });
                }}
                keyboardType={"default"}
                label={"Nome do produto"}
              />
              <SelectInput
                style={styles.selectinput2}
                value={this.state.itemCaixa.tipo}
                onChange={(value, data, index) => {
                  this.state.itemCaixa.tipo = value;
                  this.setState({ item: this.state.item });
                }}
                label={"Categoria"}
                list={this.state.categorias}
              />
            </View>
            <View style={styles.view2}>
              <TextInput
                style={styles.textinput3}
                value={this.getValor()}
                onChange={value => {
                  this.state.itemCaixa.valor = Util.parseNumber(value);
                  this.setState({ item: this.state.item });
                }}
                keyboardType={"numeric"}
                label={"Valor de venda"}
                disabled={true}
              />
              <View style={styles.view4}>
                <Switch
                  value={this.state.itemCaixa.limitar}
                  onChange={value => {
                    this.state.itemCaixa.limitar = value;
                    this.setState({ item: this.state.item });
                  }}
                  label={"Limitar Estoque PDV"}
                />
              </View>
            </View>
            <View style={styles.view2}>
              <TextInput
                style={styles.textinput1}
                value={this.state.itemCaixa.descricao}
                onChange={value => {
                  this.state.itemCaixa.descricao = value;
                  this.setState({ item: this.state.item });
                }}
                keyboardType={"default"}
                label={"Descrição"}
              />
            </View>
            <View style={styles.view3}>
              <Text style={styles.label}>{"CONFIGURAÇÕES"}</Text>

              <View style={styles.view2} container spacing={16}>
                <View item xs={4}>
                  <DatePicker
                    value={this.state.itemCaixa.h_inicio}
                    onChange={value => {
                      console.log(value);
                      this.state.itemCaixa.h_inicio = value;
                      this.setState({ item: this.state.item });
                    }}
                    label={"Hora de inicio (APP e PDV)"}
                    type={"time"}
                  />
                </View>
                <View item xs={4}>
                  <DatePicker
                    value={this.state.itemCaixa.h_fim}
                    onChange={value => {
                      this.state.itemCaixa.h_fim = value;
                      this.setState({ item: this.state.item });
                    }}
                    label={"Hora de fim (APP e PDV)"}
                    type={"time"}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </View>
                <View item xs={4}>
                  <TextInput
                    style={styles.textinput21}
                    value={this.state.itemCaixa.limite}
                    onChange={value => {
                      this.state.itemCaixa.limite = Util.limparStringNumber(
                        value
                      );
                      this.setState({ item: this.state.item });
                    }}
                    keyboardType={"numeric"}
                    label={"Limite de venda App"}
                  />
                </View>
              </View>
            </View>
            <View style={styles.view5}>
              <Produtos
                style={styles.fragment}
                screenProps={this.props.screenProps}
                navigation={this.props.navigation}
                activity={this}
                itemCaixa={this.state.itemCaixa}
                lista={this.state.itemCaixa.produtos}
              />
            </View>
            <View style={styles.action}>
              <TouchableOpacity
                onPress={() => this.salvar()}
                children={"Salvar"}
                variant="outlined"
              ></TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.validarLimparFotos()}
                disabled={!this.state.itemCaixa.id}
                style={styles.delete}
                children={"Apagar"}
                color={"secondary"}
                variant="outlined"
              ></TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.props.closeModal()}
                style={styles.delete}
                children={"Fechar"}
                color={"default"}
                variant="outlined"
              ></TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  },
  modal: {},
  tela: {
    marginTop: 50,
    padding: 20
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1
  },
  textinput1: {
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    textAlign: "left",
    fontWeight: "normal"
  },
  view2: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    paddingTop: 10
  },
  textinput3: {
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    textAlign: "left",
    fontWeight: "normal",
    flex: 1
  },
  textinput21: {
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    textAlign: "left",
    fontWeight: "normal"
  },
  view4: {
    alignSelf: "stretch",
    // flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  switch: {
    alignSelf: "stretch",
    flex: 1,
    marginLeft: 20
  },
  view3: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    padding: 10,
    marginTop: 10,
    borderColor: "rgba(0,0,0,0.5)",
    borderWidth: 2,
    borderRadius: 5,
    borderStyle: "solid"
  },
  textinput2: {
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    textAlign: "left",
    fontWeight: "normal",
    marginRight: 10,
    width: 150,
    minWidth: 150
  },
  selectinput2: {},
  view5: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  fragment: {
    alignSelf: "stretch",
    flex: 1
  },
  selectinput: {
    color: "rgba(0,0,0,1)",
    minHeight: 40,
    alignSelf: "stretch",
    borderStyle: ""
  }
});
