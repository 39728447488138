import React, { Component } from "react";

import {
  TouchableOpacity,
  View,
  ListView,
  Text,
  StyleSheet,
  OpenDialog
} from "react-1app";
import { Util } from "../../infra";
import styleGlobal from "../../styleGlobal";
import { json2excel } from "js2excel";
import * as pdv from "../../worker/pdv";
import md5 from "md5";

export default class Links extends Component {
  constructor(props) {
    super(props);
    this.state = { lista: [] };
  }

  componentWillUnmount() {}

  exportToExcel() {
    var data = this.props.item.resumo;
    try {
      json2excel({
        data,
        name: Util.cleanString(this.props.item.nome),
        formateDate: "yyyy/mm/dd"
      });
    } catch (e) {
      console.error("export error");
      alert("Erro ao criar");
    }
  }

  validarDeletar() {
    OpenDialog({
      title: "Deletar",
      description: "Confirmação para deletar este caixa!",
      action: [
        {
          label: "CONFIRMAR",
          onClick: () => this.deletar(),
          color: "secondary"
        },
        { label: "CANCELAR" }
      ],
      input: {
        secureTextEntry: true,
        type: "password",
        onChange: value => this.setState({ senha: value }),
        label: "Senha de confirmação"
      }
    });
  }

  deletar() {
    if (!this.state.senha) return alert("Adicio sua senha");
    var data = {
      senha: md5(this.state.senha),
      id_caixa: this.props.item.id
    };
    this.setState({ load: true });
    pdv.deletarCaixas(data, (data, error) => {
      if (error) {
        this.setState({ load: true });
        alert(error.msg);
      } else {
        this.setState({ load: true });
        this.props.atualizar();
        this.props.closeModal();
      }
    });
  }

  fechar() {
    if (window.confirm(`Deseja fechar este caixa?`)) {
      this.setState({ load2: true });
      pdv.fechar(this.props.item, (data, error) => {
        if (error) {
          this.setState({ load2: true });
          alert(error.msg);
        } else {
          this.setState({ load2: true });
          this.props.atualizar();
          this.props.closeModal();
        }
      });
    }
  }

  componentDidMount() {}

  getTotal() {
    return Util.parseMoney(this.props.item.valor_vendas);
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View style={styles.tela} type={"Paper"}>
            <Text style={styles.label}>{"Relatorio"}</Text>
            <ListView
              style={styles.campanha}
              dataSource={this.props.lista}
              renderRow={(rowData, sectionID, rowID) => {
                return (
                  <Cell
                    item={rowData}
                    heranca={this.state}
                    activity={this}
                    screenProps={this.props.screenProps}
                    navigator={this.state.navigator}
                    rowID={rowID}
                    sectionID={sectionID}
                  />
                );
              }}
              nativo
              colorSeparator={"rgba(227,227,227,1)"}
              ref={v => (this.campanha = v)}
              separator
            />
            <View style={styles.view7}>
              <Text style={styles.text4}>{"TOTAL DE VENDAS"}</Text>
              <Text style={styles.text6}>{this.getTotal()}</Text>
            </View>
            <View style={styles.action}>
              <TouchableOpacity
                onPress={() => this.exportToExcel()}
                children={"Exportar"}
                variant="outlined"
              ></TouchableOpacity>
              {!this.props.item.fechamento ? (
                <TouchableOpacity
                  onPress={() => this.fechar()}
                  style={styles.delete}
                  children={"Fechar caixa"}
                  color={"secondary"}
                  variant="outlined"
                  load={this.state.load2}
                ></TouchableOpacity>
              ) : null}
              {!this.props.item.totalizador ? (
                <TouchableOpacity
                  onPress={() => this.validarDeletar()}
                  style={styles.delete}
                  children={"Apagar"}
                  color={"secondary"}
                  variant="outlined"
                  load={this.state.load}
                ></TouchableOpacity>
              ) : null}
              <TouchableOpacity
                onPress={() => this.props.closeModal()}
                style={styles.delete}
                children={"Fechar"}
                color={"default"}
                variant="outlined"
              ></TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

class Cell extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillUnmount() {}

  getName() {
    return this.props.item.nome;
  }

  getValor() {
    return Util.parseMoney(this.props.item.valor);
  }

  getQnt() {
    if (this.props.item.saldo) return "FINAL";

    return (this.props.item.quantidade || 0) + "";
  }

  getCor() {
    var cor = "rgba(0,0,0,0.0)";
    let cor2 = "rgba(0,0,0,0.3)";
    if (this.props.item.nome == "SUPRIMENTO") cor = cor2;
    if (this.props.item.nome == "SANGRIA") cor = cor2;
    if (this.props.item.nome == "DINHEIRO") cor = cor2;
    if (this.props.item.nome == "CREDITO") cor = cor2;
    if (this.props.item.nome == "DEBITO") cor = cor2;
    if (this.props.item.nome == "REIMPRESSOES") cor = cor2;
    return cor;
  }

  componentDidMount() {}

  render() {
    return (
      <TouchableOpacity
        style={[styles.cell, { backgroundColor: this.getCor() }]}
        disabled={true}
      >
        <View style={styles.view6}>
          <Text style={styles.thisgetQnt}>{this.getQnt()}</Text>
        </View>
        <View style={styles.view5}>
          <Text style={styles.text2}>{this.getName()}</Text>
        </View>
        <View style={styles.view9}>
          <Text style={styles.text5}>{this.getValor()}</Text>
        </View>
      </TouchableOpacity>
    );
  }
}

var styles = StyleSheet.create({
  tela: {
    padding: 20,
    marginTop: 50
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  },
  view: {
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    borderRadius: 5,
    backgroundColor: "rgba(238,238,238,1)",
    padding: 10
  },
  campanha: {
    // alignSelf: "stretch",
    // flex: 1,
    // flexDirection:"column",
    // marginTop:10
  },
  cell: {
    flexDirection: "row",
    // alignSelf: "stretch",
    flex: 1,
    padding: 5,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%"
    // flexWrap: "nowrap",
    // backgroundColor: "rgba(255,255,255,1)"
  },
  view6: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: 3,
    backgroundColor: "rgba(0,0,0,1)",
    marginRight: 8,
    borderRadius: 5,
    minWidth: 50
  },
  thisgetQnt: {
    alignSelf: "auto",
    textAlign: "left",
    fontWeight: "normal",
    color: "rgba(255,255,255,1)"
  },
  view5: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: 3,
    borderRadius: 5
  },
  text2: {
    textAlign: "left",
    alignSelf: "stretch",
    fontWeight: "normal"
  },
  view9: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: 5
  },
  text5: {
    textAlign: "right",
    alignSelf: "stretch",
    fontWeight: "bold"
  },
  view7: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row",
    padding: 15
  },
  text4: {
    textAlign: "left",
    alignSelf: "stretch",
    fontWeight: "bold",
    flex: 1,
    fontSize: 15
  },
  text6: {
    textAlign: "right",
    alignSelf: "stretch",
    fontWeight: "bold",
    flex: 1,
    fontSize: 25
  }
});
