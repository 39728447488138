import React from "react";
import ListSubheader from "@material-ui/core/ListSubheader";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Icon from "react-1app/lib/Icon";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListView from "react-1app/lib/ListView";
import * as ItemCaixa from "../../worker/item_caixa";
import moment from "moment";
import { OpenModal } from "react-1app/lib/PgModal";
import AddItemCaixa from "./AddItemCaixa";
import Text from "react-1app/lib/Text";
import { Util } from "../../infra";
import ClonarProdutos from "./ClonarProdutos";
import ModalVideoAjuda from "../ModalVideoAjuda";

export default class IngressoView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {},
      load: true,
      list: [],
      listaCaixa: [],
      parametros: {
        limit: 200,
        page: 0,
        id_empresa: this.props.screenProps.store.getState().projeto.id,
        id_caixa_padrao: this.props.caixa_padrao.id
      },
      caixa_padrao: this.props.caixa_padrao || {},
      projeto: this.props.screenProps.store.getState().projeto || {}
    };
  }

  componentDidMount() {
    this.buscar();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.caixa_padrao &&
      this.props.caixa_padrao &&
      nextProps.caixa_padrao.id != this.props.caixa_padrao.id
    )
      this.setState(
        {
          caixa_padrao: nextProps.caixa_padrao,
          list: [],
          caixa_padrao: nextProps.caixa_padrao,
          parametros: {
            ...this.state.parametros,
            id_caixa_padrao: nextProps.caixa_padrao.id
          },
          load: true
        },
        () => {
          this.buscar();
        }
      );
    return true;
  }

  buscar() {
    if (
      !this.state.parametros.id_caixa_padrao ||
      !this.state.parametros.id_empresa
    )
      return this.setState({ list: [], load: false });
    ItemCaixa.listar(this.state.parametros, (dados = [], error) => {
      this.setState({
        list: dados.list,
        totalLista: dados.total,
        load: false
      });
    });
  }

  openEditar(
    item = {
      id_empresa: this.state.projeto.id,
      id_caixa_padrao: this.props.caixa_padrao.id,
      produtos: [],
      pdv: true
    }
  ) {
    OpenModal(AddItemCaixa, {
      itemCaixa: item,
      atualizar: () => this.buscar(),
      activity:this
    });
  }

  openClonar() {
    OpenModal(ClonarProdutos, {
      atualizar: () => this.buscar(),
      id_caixa_padrao: this.props.caixa_padrao.id,
      historico: this.props.historico
    });
  }

  getTags(item) {
    return [
      { label: "APP", ativo: item.app },
      { label: "PDV", ativo: item.pdv }
    ].map(a => (
      <View
        style={{
          ...styles.view91,
          backgroundColor: a.ativo ? "rgb(0, 194, 0)" : "rgb(200, 200, 200)"
        }}
      >
        <Text style={styles.text6}>{a.label}</Text>
      </View>
    ));
  }

  getDescricao(item) {
    return (
      moment(item.criado).fromNow() +
      " " +
      (`( ${item.tipo || "SEM CATEGORIA"} )` || "")
    );
  }

  render() {
    return (
      <View container spacing={16}>
        <View xs={12} item>
          <View type={"Paper"}>
            <ListView
              ListHeaderComponent={() => (
                <View style={styles.head}>
                  <ListSubheader component="div">
                    {"PRODUTOS PADRÃO PARA PDV"}
                  </ListSubheader>
                  <TouchableOpacity
                    children={"Ajuda"}
                    style={styles.add}
                    variant="default"
                    onPress={() => {
                      OpenModal(ModalVideoAjuda,{url:"https://www.youtube.com/embed/d5tuitP36Zw"});
                    }}
                  />
                  <TouchableOpacity
                    children={"Copiar"}
                    style={{marginLeft:10}}
                    variant="outlined"
                    color={"default"}
                    onPress={() => {
                      this.openClonar();
                    }}
                  />
                  <TouchableOpacity
                    children={"Novo"}
                    style={{marginLeft:10}}
                    variant="outlined"
                    onPress={() => {
                      this.openEditar();
                    }}
                  />
                </View>
              )}
              renderItem={({ item, index }) => {
                return (
                  <ListItem
                    button
                    key={index}
                    style={{ width: "100%", flexWrap: "wrap" }}
                    selected={this.state.index === index}
                    onClick={event => this.openEditar(item)}
                  >
                    <Avatar src={item.img}>
                      <Icon name={"local_bar"} />
                    </Avatar>
                    <ListItemText
                      primary={item.nome}
                      secondary={this.getDescricao(item)}
                    />
                    <View style={{ alignSelf: "auto", padding: 10 }}>
                      {this.getTags(item)}
                      <Text style={{ width: 100, justifyContent: "flex-end" }}>
                        {Util.parseMoney(item.valor)}
                      </Text>
                    </View>
                  </ListItem>
                );
              }}
              separator
              tablePagination
              load={this.state.load}
              data={this.state.list}
              count={Number(this.state.totalLista)}
              page={this.state.parametros.page}
              rowsPerPage={this.state.parametros.limit}
              handleChangePage={(event, page) => {
                this.setState(
                  {
                    parametros: Object.assign(this.state.parametros, { page }),
                    load: true
                  },
                  this.buscar()
                );
              }}
              handleChangeRowsPerPage={event => {
                this.setState(
                  {
                    parametros: Object.assign(this.state.parametros, {
                      limit: event.target.value,
                      page: 0
                    }),
                    load: true
                  },
                  this.buscar()
                );
              }}
            />
          </View>
        </View>
      </View>
    );
  }
}

const styles = {
  root: {
    width: 500
  },
  icon: {
    margin: 15
  },
  cell: {
    width: "100%"
  },
  head: {
    height: 50,
    width: "100%",
    padding: 5
  },
  image: {
    maxWidth: 600,
    maxHeight: 600 / (16 / 9),
    height: "100vh",
    margin: "auto",
    borderRadius: 5,
    background: "rgba(0,0,0,0.1)"
  },
  gridEdit: {
    flex: 1,
    maxWidth: 800
  },
  view91: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: null,
    marginLeft: 5,
    marginRight: 5,
    borderRadius: 2,
    padding: 5,
    height: 30
  },
  view: {
    padding: 20,
    paddingTop: 0
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  add: {
    marginLeft: "auto"
  },
  delete: {
    marginLeft: 20
  }
};
