import React from "react";
import ListSubheader from "@material-ui/core/ListSubheader";
import View from "react-1app/lib/View";
import Icon from "react-1app/lib/Icon";
import TextInput from "react-1app/lib/TextInput";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListView from "react-1app/lib/ListView";
import moment from "moment";
import { Util } from "../../infra";
import Vendas from "./Vendas";
import * as Pdv from "../../worker/pdv";
import styleGlobal from "../../styleGlobal";

class Produtos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {},
      load: true,
      total: 0,
      list: [],
      parametros: {
        limit: 5,
        page: 0,
        paginacao: true,
        id_empresa: this.props.screenProps.store.getState().projeto.id
      }
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.projeto != this.state.projeto)
        this.setState(
          {
            projeto: store.projeto,
            parametros: {
              ...this.state.parametros,
              id_empresa: store.projeto.id
            },
            anchorEl: null
          },
          () => {
            this.buscar();
          }
        );
    });
    this.buscar();
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  buscar() {
    if (!this.state.parametros.id_empresa) return;
    Pdv.listarCaixas(
      this.state.parametros,
      (dados = { list: [], total: 0 }, error) => {
        console.log(dados);
        this.setState({
          list: dados.list,
          total: dados.total,
          load: false
        });
      }
    );
  }

  render() {
    return (
      <View container spacing={16}>
        <View {...styleGlobal.gridListDimacao} item>
          <View type={"Paper"} style={styles.root}>
            <ListView
              ListHeaderComponent={() => (
                <ListSubheader component="div">
                  {"Caixas fechados"}
                </ListSubheader>
              )}
              renderItem={({ item, index }) => {
                return (
                  <ListItem
                    button
                    key={index}
                    style={{ width: "100%" }}
                    selected={this.state.index === index}
                    onClick={event =>
                      this.setState({ index, item }, () => {
                        setTimeout(() => {
                          window.focusElementById("editar");
                        }, 100);
                      })
                    }
                  >
                    <Avatar src={item.img}>
                      <Icon name={"remove_shopping_cart"} />
                    </Avatar>
                    <ListItemText
                      primary={item.nome}
                      secondary={"Vendas: " + Util.parseMoney(item.valor_caixa)}
                    />
                    <Icon color="default" name={"visibility"} />
                  </ListItem>
                );
              }}
              separator
              data={this.state.list}
              tablePagination
              load={this.state.load}
              count={Number(this.state.total)}
              page={this.state.parametros.page}
              rowsPerPage={this.state.parametros.limit}
              handleChangePage={(event, page) => {
                this.setState(
                  {
                    parametros: Object.assign(this.state.parametros, { page }),
                    load: true
                  },
                  this.buscar()
                );
              }}
              handleChangeRowsPerPage={event => {
                this.setState(
                  {
                    parametros: Object.assign(this.state.parametros, {
                      limit: event.target.value,
                      page: 0
                    }),
                    load: true
                  },
                  this.buscar()
                );
              }}
            />
          </View>
        </View>
        <View {...styleGlobal.gridEditDimacao} item>
          {this.state.item && this.state.item.id ? (
            <View type={"Paper"} id="editar" style={styles.view}>
              <View style={styles.head}>
                <ListSubheader component="div">{"Caixa"}</ListSubheader>
              </View>
              <View container spacing={16}>
                <View xs={3} item>
                  <TextInput
                    value={this.state.item.nome}
                    variant="outlined"
                    disabled={true}
                    label={"Nome"}
                  />
                </View>
                <View xs={3} item>
                  <TextInput
                    value={moment(this.state.item.criado).format(
                      "DD/MM/YYYY HH:mm"
                    )}
                    variant="outlined"
                    disabled={true}
                    label={"Abertura"}
                  />
                </View>
                <View xs={3} item>
                  <TextInput
                    value={this.state.item.valor_vendas}
                    variant="outlined"
                    disabled={true}
                    label={"Total em vendas"}
                  />
                </View>
                <View xs={3} item>
                  <TextInput
                    value={this.state.item.nome_usuario}
                    variant="outlined"
                    disabled={true}
                    label={"Nome Operador"}
                  />
                </View>
              </View>
              {this.state.item.id && this.state.item.id !== "new" ? (
                <Vendas {...this.props} caixa={this.state.item} />
              ) : null}
            </View>
          ) : null}
        </View>
      </View>
    );
  }
}

const styles = {
  root: {},
  icon: {
    margin: 15
  },
  cell: {
    width: "100%"
  },
  head: {
    height: 50,
    width: "100%",
    padding: 5
  },
  image: {
    maxWidth: 600,
    maxHeight: 600 / (16 / 9),
    height: "100vh",
    margin: "auto",
    borderRadius: 5,
    background: "rgba(0,0,0,0.1)"
  },
  gridEdit: {},
  view: {
    padding: 20,
    paddingTop: 0
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  add: {
    marginLeft: "auto"
  },
  delete: {
    marginLeft: 20
  }
};

export default Produtos;
