import React, { Component } from "react";

import * as Relatorios from "../../worker/relatorio_adm";
import moment from "moment";
import TableSelect from "react-1app/lib/Table";
import { Util } from "../../infra";
import Switch from "react-1app/lib/Switch";
import SelectInput from "react-1app/lib/SelectInput";
// import DatePicker from "react-1app/lib/DatePicker";
import DatePicker from "react-1app/lib/DatePicker";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Icon from "react-1app/lib/Icon";
import View from "react-1app/lib/View";
import StyleSheet from "react-1app/lib/StyleSheet";
import Grafico from "../../components/GraficoBar";

export default class Listar_Finalizados extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      inicio: moment()
        .subtract(1, "months")
        .toJSON(),
      fim: moment().toJSON(),
      load: true,
      total: 0,
      projeto: this.props.screenProps.store.getState().projeto || {},
      dia: true,
      projetos: this.props.screenProps.store.getState().projetos || []
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.projeto != this.state.projeto)
        this.setState({ projeto: store.projeto, anchorEl: null }, () => {
          this.buscar();
        });
    });
    this.buscar();
  }
  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  calculaTotais(lista = [], tag = "total") {
    let total = 0;
    lista.map(item => {
      total += item[tag];
    });

    return total;
  }

  buscar() {
    var regras = this.props.screenProps.store.getState().regras;
    if (!regras) return;
    if (this.state.inicio > this.state.fim) {
      return alert(
        "Por favor, selecione uma data final maior que a data inicial."
      );
    }

    this.setState({ load: true });
    Relatorios.getResumoVendasPeriodo(
      { inicio: this.state.inicio, fim: this.state.fim, dia: this.state.dia,id_empresa:this.state.id_empresa },
      (dados = [], error) => {
        if (regras.realatorioIngresso)
          dados = dados.map(item => ({
            ingressos_app: item.ingressos_app,
            data: item.data,
            ingressos_pdv: item.ingressos_pdv,
            total: (item.ingressos_app || 0) + (item.ingressos_pdv || 0)
          }));
        let { listG, listH } = this.ajusteGrafico(dados);

        this.setState({
          listG,
          listH,
          lista: dados,
          load: false
        });
      }
    );
  }

  ajusteGrafico(list) {
    let listH = [
      { dataKey: "Ingressos App", fill: "#4FEBB1" },
      { dataKey: "Bar App", fill: "#54FF75" }
    ];
    let listG = list.map(item => ({
      ...item,
      name: moment(item.data).add(12,"hour").format("DD/MM/YYYY"),
      "Bar App": item.bar_app,
      "Ingressos App": item.ingressos_app,
    }));
    return { listG, listH };
  }

  render() {
    return ([
      <View
        type={"Paper"}
        // classes={{
        //   root: this.props.classes.cardHeader
        // }}
        style={{ padding: 10, marginBottom: 20 }}
      >
        <Grafico dataKey={this.state.listH} lista={this.state.listG} />
      </View>,
      <TableSelect
        tableHead={[
          "Data",
          "Bar App",
          "Ingresso App",
          "GetNet",
          "Total",
          "Diferença"
        ]}
        tableData={[
          ...this.state.lista.map(item => [
            moment(item.data).add(12,"hour").format("DD/MM/YYYY"),
            Util.parseMoney(item.bar_app),
            Util.parseMoney(item.ingressos_app),
            Util.parseMoney(item.getnet),
            Util.parseMoney(item.total),
            Util.parseMoney(item.getnet-item.total),
          ]),
          [null, null, null, null, null,null],
          [
            "Totalizadores",
            Util.parseMoney(this.calculaTotais(this.state.lista, "bar_app")),
            Util.parseMoney(
              this.calculaTotais(this.state.lista, "ingressos_app")
            ),
            Util.parseMoney(
              this.calculaTotais(this.state.lista, "getnet")
            ),
            Util.parseMoney(this.calculaTotais(this.state.lista, "total")),
            Util.parseMoney(
              this.calculaTotais(this.state.lista, "getnet")-this.calculaTotais(this.state.lista, "total")
            ),
          ]
        ]}
        title={"Relatório de Vendas por Período GetNet"}
        load={this.state.load}
        actionBar={
          <View style={styles.viewAction}>
            <SelectInput
              value={this.state.id_empresa}
              style={{marginTop:22}}
              onChange={value => {
                this.setState({ id_empresa: value });
              }}
              label={"Empresa"}
              key_label="nome"
              key_value="id"
              list={this.state.projetos}
            />
            <DatePicker
              style={styles.datepicker}
              value={this.state.inicio}
              onChange={value => {
                this.setState({ inicio: value });
              }}
              datePickerModeAndroid={"calendar"}
              label={"Data Inicial"}
              type={"date"}
            />
            <DatePicker
              style={styles.datepicker}
              value={this.state.fim}
              onChange={value => {
                this.setState({ fim: value });
              }}
              datePickerModeAndroid={"calendar"}
              label={"Data Final"}
              type={"date"}
            />
            <TouchableOpacity
              style={styles.search}
              onPress={() => {
                this.buscar();
              }}
              disable={this.state.load}
            >
              <Icon
                style={styles.icon2}
                fromFontFamily={"Material Icons"}
                name={"search"}
              />
            </TouchableOpacity>
          </View>
        }
      />
    ]);
  }
}

var styles = StyleSheet.create({
  fragment1: {
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  total: {
    fontWeight: "bold",
    fontSize: 17,
    textAlign: "right"
  },
  viewFooter: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-end",
    padding: 20
  },
  search: {
    width: 80,
    height: 50,
    alignItems: "center",
    justifyContent: "center"
  },
  viewAction: {
    // flex: 1,
    // alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  icon2: {
    color: "rgba(150,150,145,1)",
    fontSize: 25
  },
  switch: {},
  image: {
    width: 50,
    height: 50,
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    borderRadius: 50
  },
  view2: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  button12: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "row",
    marginTop: 10,
    marginRight: 10,
    backgroundColor: null,
    width: 50
  },
  datepicker: {
    color: "rgba(0,0,0,1)",
    alignSelf: "auto",
    marginLeft: 10,
    maxWidth: 150
  },
  icon1: {
    color: "rgba(150,150,145,1)",
    fontSize: 25
  },
  button1: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "row",
    marginTop: 10,
    marginRight: 10,
    backgroundColor: null,
    width: 50
  },
  icon3: {
    color: "rgba(150,150,145,1)",
    fontSize: 25
  },
  icon: {
    color: "rgba(150,150,145,1)",
    fontSize: 25
  }
});
