import React, { Component } from "react";
import Divider from "@material-ui/core/Divider";

import {
  TouchableOpacity,
  View,
  Icon,
  ListView,
  Progress,
  SelectInput,
  Text,
  Switch,
  TextInput,
  StyleSheet,
  Alert
} from "react-1app";
import { Util } from "../../infra";
import * as Produto from "../../worker/produtos";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";

export default class ProdutosItem extends Component {
  constructor(props) {
    super(props);
    this.state = { load: true, novo: false, item: {}, lista: [] };
  }

  componentDidMount() {
    this.buscarProdutos();
  }

  componentWillUnmount() {}

  buscarProdutos() {
    Produto.listar({ limit: 1000 }, (dados = { list: [], total: 0 }, error) => {
      this.setState({ listaProduto: dados.list });
    });
  }

  update() {
    this.buscarDados();
    this.props.activity.atualizar();
  }

  nova() {
    this.props.lista.push({
      id_item_caixa: this.props.itemCaixa.id,
      mutiplicar: true
    });
    this.setState({ lista: this.props.lista });
  }

  removerLista(index) {
    let item = this.props.lista[index];
    item.remover = true;
    this.setState({ lista: this.props.lista });
  }

  setProduto(item) {
    if (!this.props.itemCaixa.descricao) {
      this.props.itemCaixa.descricao = item.descricao;
    }
    if (!this.props.itemCaixa.nome) {
      this.props.itemCaixa.nome = item.nome;
    }
    this.props.activity.setState({ itemCaixa: this.props.itemCaixa });
  }

  render() {
    return (
      <View type={"Paper"} style={styles.paper}>
        <ListView
          style={styles.campanha}
          ListHeaderComponent={() => [
            <View style={styles.view1}>
              <ListSubheader component="div">
                {"PRODUTO PARA ITEM"}
              </ListSubheader>
              <TouchableOpacity
                children={"Novo"}
                variant="outlined"
                style={styles.bottom}
                onPress={() => {
                  this.nova();
                }}
              />
            </View>,
            <Divider />
          ]}
          separator
          dataSource={this.props.lista.filter(a => !a.remover)}
          renderItem={({ item, index }, rowID) => {
            return (
              <Cell
                item={item}
                heranca={this.state}
                activity={this}
                screenProps={this.props.screenProps}
                navigator={this.state.navigator}
                rowID={index}
                raiz={this.props.activity}
                sectionID={index}
                listaProduto={this.state.listaProduto}
                atualizar={() => this.update()}
              />
            );
          }}
          colorSeparator={"rgba(227,227,227,1)"}
          ref={v => (this.campanha = v)}
          renderFooter={() => (
            <Text style={styles.text2}>
              {
                "Após salvar o produto individualmente ele recalculará o valor do item no caixa."
              }
            </Text>
          )}
        />
      </View>
    );
  }
}

class Cell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
      open: false
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  getValor() {
    return Util.parseMoney(this.props.item.valor);
  }

  getNome() {
    return this.props.item.nome;
  }

  toggleDialogRemover() {
    Alert.alert("Remover", "Deseja remover este item?", [
      { text: "Sim", onPress: () => this.remover() },
      { text: "Cancelar" }
    ]);
  }

  remover() {
    this.props.activity.removerLista(this.props.rowID);
  }

  render() {
    return (
      <ListItem style={styles.cell}>
        <View style={styles.viewBranca}>
          <View style={styles.view5}>
            <SelectInput
              style={styles.selectinput2}
              value={this.props.item.id_produto}
              onChange={(value, data, index) => {
                this.props.item.id_produto = value;
                let item =
                  this.props.listaProduto.find(e => e.id == value) || {};
                if (!this.props.item.quantidade) this.props.item.quantidade = 1;
                if (!this.props.item.valor) this.props.item.valor = item.valor;
                if (this.props.activity.setProduto)
                  this.props.activity.setProduto(item);
                this.setState({ item: this.state.item });
              }}
              label={"Produto"}
              list={this.props.listaProduto}
            />
          </View>
          <View style={styles.viewValores}>
            <TextInput
              style={styles.textinput31}
              value={this.props.item.quantidade}
              onChange={value => {
                this.props.item.quantidade = Util.limparStringNumber(value);
                this.setState({ item: this.state.item });
                this.props.raiz.setState({ alterado: true });
              }}
              keyboardType={"numeric"}
              label={"Quantidade"}
            />
            <Switch
              style={styles.switch}
              value={this.props.item.mutiplicar}
              onChange={value => {
                this.props.item.mutiplicar = value;
                this.setState({ item: this.state.item });
                this.props.raiz.setState({ alterado: true });
              }}
              label={"QNT/VALOR"}
            />
            <TextInput
              style={styles.textinput3}
              value={this.props.item.valor}
              onChange={value => {
                this.props.item.valor = Util.maskFormate(value, "money");
                this.setState({ item: this.state.item });
                this.props.raiz.setState({ alterado: true });
              }}
              keyboardType={"numeric"}
              label={"Valor de venda"}
            />
          </View>
        </View>

        <View style={styles.view9}>
          {!this.state.load ? (
            <TouchableOpacity
              style={styles.button1}
              variant="outlined"
              color={"secondary"}
              tooltip={"Deletar"}
              onPress={() => {
                this.toggleDialogRemover();
              }}
            >
              <Icon fromFontFamily={"Material Icons"} name={"delete"} />
            </TouchableOpacity>
          ) : null}

          {this.state.load ? <Progress style={styles.progress} /> : null}
        </View>
      </ListItem>
    );
  }
}

var styles = StyleSheet.create({
  paper: {
    width: "100%",
    marginTop: 20
  },
  viewValores: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    flex: 1
  },
  viewBranca: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    flex: 1,
    width: "100%"
  },
  view: {
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    borderRadius: 5,
    backgroundColor: "rgba(238,238,238,1)",
    padding: 10
  },
  view1: {
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    padding: 5
  },
  bottom: {
    marginLeft: "auto"
  },
  campanha: {
    alignSelf: "stretch",
    flex: 1
  },
  cell: {
    width: "100%",
    flexDirection: "row",
    alignSelf: "stretch",
    padding: 0,
    paddingLeft: 10,
    paddingRight: 10
  },
  view5: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: 3,
    borderRadius: 5
  },
  selectinput2: {},
  view61: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: 3,
    backgroundColor: null,
    marginRight: 8,
    borderRadius: 5,
    flex: 1
  },
  textinput31: { alignSelf: "stretch", flex: 1, maxWidth: 120 },
  view6: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: 3,
    backgroundColor: null,
    borderRadius: 5,
    flex: 1
  },
  textinput3: { alignSelf: "stretch", flex: 1 },
  view9: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  button1: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    width: 50,
    marginLeft: 10
  },
  icon: {
    color: "rgba(150,150,145,1)",
    fontSize: 20
  },
  progress: {},
  text2: {
    textAlign: "center",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    fontWeight: "700",
    fontSize: 12,
    marginTop: 10
  }
});
