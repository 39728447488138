import React, { Component } from "react";

import * as Usuario from "../../worker/usuario";
import Switch from "react-1app/lib/Switch";
import Icon from "react-1app/lib/Icon";
import Avatar from "@material-ui/core/Avatar";
import Text from "react-1app/lib/Text";
import StyleSheet from "react-1app/lib/StyleSheet";
import moment from "moment";
import md5 from "md5";
import { OpenSnack } from "react-1app/lib/SnackBar";
import { OpenDialog } from "react-1app/lib/DialogAlert";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import View from "react-1app/lib/View";
import ModalVendasPromoter from "./ModalVendasPromoter";
import { Util } from "../../infra";
import TableSelect from "react-1app/lib/Table";

import { OpenModal } from "react-1app/lib/PgModal";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import styleGlobal from "../../styleGlobal";
import * as CaixaFato from "../../worker/caixa_fato";
import ModalNotif from "../notificacao";
import QRCode from "react-1app/lib/QRCode";

export default class Listar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || {},
      load: true,
      total: 0,
      parametros: {
        limit: 4,
        page: 0,
        promoter: false,
        busca: ""
      },
      limit_compra:0,
      compras: [],
      open: false,
      promoter: {},
      senha: "",
      lista: [],
      regras: this.props.screenProps.store.getState().regras,
      user: this.props.screenProps.store.getState().user || {},
    };
  }

  componentDidMount() {
    this.buscar();
  }

  validarAlterarPromoter(item, value) {
    OpenDialog({
      title: "Promoter",
      description: "Confirmação para alterar promoter!",
      action: [
        {
          label: "CONFIRMAR",
          onClick: () => {
            if (md5(this.state.senha) !== this.state.user.password) {
              return OpenSnack({ message: "Senha inválida!", type: "error" });
            }
            this.setPromoter(item, value);
          },
          color: "secondary"
        },
        { label: "CANCELAR" }
      ],
      input: {
        secureTextEntry: true,
        type: "password",
        onChange: value => this.setState({ senha: value }),
        label: "Senha de confirmação"
      }
    });
  }

  setPromoter(item, value) {
    this.setState({ load: true });
    Usuario.atualizar({ id: item.id, promoter: value }, (res, error) => {
      if (error) alert("Erro ao alterar promoter!");
      this.props.atualizar();
      this.state.item.promoter=res.promoter;
      this.setState({ load: false,item:this.state.item });
    });
  }

  AlterarLimit(){
    OpenDialog({
      title:"Alterar limite",
      description:'Alterar limite de compra diario para este cliente.',
      action:[
        {label:"CONFIRMAR",onClick:()=>{
          Usuario.atualizar({ id: this.state.item.id, limit_compra: this.state.limit_compra }, (res, error) => {
            if (error) {
              return alert("Erro ao alterar limite de compra!");
              this.setState({ limit_compra:0 });
            }
            this.props.atualizar();
            this.state.item.limit_compra=res.limit_compra;
            this.setState({ load: false,item:this.state.item,limit_compra:0 });
            OpenSnack({ message: "Limite de compra alterardo" });
          });
        },color:'secondary'},
        {label:"CANCELAR"},
      ],
      input:{
        type:"numeric",
        onChange:(value)=>this.setState({limit_compra:value}),
        label:"Novo limit"
      }})
    }


  openVendasPromoter(promoter) {
    OpenModal(ModalVendasPromoter, {
      promoter,
      atualizar: () => this.props.atualizar(),
      activity: this
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.item &&
      this.props.item &&
      nextProps.item.id != this.props.item.id
    )
      this.setState({ item: nextProps.item, lista: [] }, () => {
        this.buscar();
      });
  }

  buscar() {
    this.setState({ load: true });
    CaixaFato.listar(
      { ...this.state.parametros, id_usuario: this.state.item.id },
      (dados = { list: [], total: 0 }, error) => {
        this.setState({
          lista: dados.list,
          totalLista: dados.total,
          load: false
        });
      }
    );
  }

  abrirNotificacao() {
    OpenModal(ModalNotif, {
      notificacao: { id_usuario: this.state.item.id },
      titulo: "Enviar notificação para: " + this.state.item.nome
    });
  }

  abrirQr() {
    OpenModal(props => (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={"auto"} item>
          <View type={"Paper"} id="editar" style={styles.view}>
            <View style={styles.head}>
              <Text style={[styleGlobal.SubHeader, { marginRight: "auto" }]}>
                {"Escaneie o qr code"}
              </Text>
              <TouchableOpacity
                style={styles.action}
                onPress={() => {
                  props.closeModal();
                }}
              >
                <Icon fromFontFamily={"Material Design Icons"} name={"close"} />
              </TouchableOpacity>
            </View>
            <QRCode
              style={styles.view4}
              value={"ID_USER:" + this.state.item.id}
              size={250}
            />
          </View>
        </View>
      </View>
    ));
  }

  validarRemover(item, value) {
    OpenDialog({
      title: "Confirmação",
      description:
        "Você deseja apagar este item? Ele será removido e não poderá mais ser usado!",
      action: [
        {
          label: "CONFIRMAR",
          onClick: () => {
            if (md5(this.state.senha) !== this.state.user.password) {
              return OpenSnack({ message: "Senha inválida!", type: "error" });
            }
            this.remover(item);
          },
          color: "secondary"
        },
        { label: "CANCELAR" }
      ],
      input: {
        secureTextEntry: true,
        type: "password",
        onChange: value => this.setState({ senha: value }),
        label: "Senha de confirmação"
      }
    });
  }

  validarItem(item, value) {
    OpenDialog({
      title: "Confirmação",
      description: "Você deseja validar este item?",
      action: [
        {
          label: "CONFIRMAR",
          onClick: () => {
            this.validar(item);
          },
          color: "secondary"
        },
        { label: "CANCELAR" }
      ]
    });
  }

  remover(item, value) {
    this.setState({ load: true });
    CaixaFato.remover(item.id, (res, error) => {
      if (error) alert("Erro ao remover!");
      this.buscar();
    });
  }
  validar(item, value) {
    this.setState({ load: true });
    CaixaFato.baixa({ id_caixa_fato: item.id }, (res, error) => {
      if (error) {
        alert(error.msg);
      } else {
        alert("Validado");
      }
      this.buscar();
    });
  }

  render() {
    return (
      <View type={"Paper"} id="editar" style={styles.view}>
        <View style={styles.head}>
          <Text style={[styleGlobal.SubHeader, { marginRight: "auto" }]}>
            {"Usuario"}
          </Text>
          <TouchableOpacity
            style={styles.action}
            onPress={() => {
              this.abrirQr();
            }}
            variant="outlined"
            tooltip={"QR CODE para vinculo de produtos"}
          >
            <Icon fromFontFamily={"Material Design Icons"} name={"qrcode"} />
          </TouchableOpacity>
          {this.state.regras && this.state.regras.admin || this.state.user.adm ? (
            <TouchableOpacity
              style={styles.action}
              onPress={() => {
                this.abrirNotificacao();
              }}
              variant="outlined"
              tooltip={"Enviar Notificação"}
            >
              <Icon
                fromFontFamily={"Material Design Icons"}
                name={"bell-ring"}
              />
            </TouchableOpacity>
          ) : null}
          {this.state.item.promoter ? (
            <TouchableOpacity
              style={styles.action}
              onPress={() => {
                this.openVendasPromoter(this.state.item);
              }}
              variant="outlined"
              tooltip={"Vendas"}
            >
              <Icon
                style={{}}
                fromFontFamily={"Material Icons"}
                name={"attach_money"}
              />
            </TouchableOpacity>
          ) : null}
          <Switch
            style={styles.switch}
            value={this.state.item.promoter}
            onChange={value => {
              this.validarAlterarPromoter(this.state.item, value);
            }}
            label={"Promoter"}
          />
        </View>
        <ListItem key={"foco"} style={{ width: "100%" }}>
          <Avatar
            style={styles.avatar}
            src={this.state.item.foto || "/img/user.png"}
          />
          <ListItemText
            primary={this.state.item.nome}
            secondary={
              this.state.item.email +
              " | " +
              this.state.item.id +
              " | " +
              moment(this.state.item.criado).format("DD/MM/YYYY HH:mm")
            }
          />
          <Text>{Util.parseMoney(this.state.item.valor_gasto)}</Text>
        </ListItem>
        <View>
        <TouchableOpacity
          style={styles.action2}
          onPress={() => {
            this.AlterarLimit(this.state.item);
          }}
          variant="outlined"
          children={"Limite diario de compra: "+Util.parseMoney(this.state.item.limit_compra)}
          >
        </TouchableOpacity>
        </View>
        <TableSelect
          title={"Compras"}
          tableHead={[
            "Tipo",
            "Nome",
            "Valor",
            "Criado",
            "Codigo",
            "Status",
            "Ações"
          ]}
          tableData={this.state.lista.map(item => [
            item.tipo,
            item.nome,
            Util.parseMoney(item.valor),
            moment(item.criado).format("DD/MM/YYYY HH:mm"),
            item.codigo,
            item.validado
              ? "Validado: " + moment(item.validado).format("DD/MM/YY HH:mm")
              : "Não Validado",
            <div>
              {(this.state.regras &&
                this.state.regras.admin &&
                !item.id_usuario) ||
              this.state.user.adm ? (
                <TouchableOpacity
                  style={styles.action}
                  onPress={() => {
                    this.validarRemover(item);
                  }}
                >
                  <Icon
                    style={{}}
                    fromFontFamily={"Material Icons"}
                    name={"delete"}
                  />
                </TouchableOpacity>
              ) : null}
              {!item.validado ? (
                <TouchableOpacity
                  variant="outlined"
                  children={"Validar"}
                  onPress={() => {
                    this.validarItem(item);
                  }}
                ></TouchableOpacity>
              ) : null}
            </div>
          ])}
          tablePagination
          load={this.state.load}
          count={Number(this.state.totalLista)}
          page={this.state.parametros.page}
          rowsPerPage={this.state.parametros.limit}
          handleChangePage={(event, page) => {
            this.setState(
              {
                parametros: Object.assign(this.state.parametros, { page }),
                load: true
              },
              this.buscar()
            );
          }}
          handleChangeRowsPerPage={event => {
            this.setState(
              {
                parametros: Object.assign(this.state.parametros, {
                  limit: event.target.value,
                  page: 0
                }),
                load: true
              },
              this.buscar()
            );
          }}
        />
      </View>
    );
  }
}

var styles = StyleSheet.create({
  textinput: {
    width: 200,
    marginLeft: "auto",
    color: "rgba(150,150,150,1)",
    borderRadius: 5,
    backgroundColor: "rgba(0,0,0,0.12)",
    padding: 4,
    height: 40,
    fontSize: 15
  },
  view: {
    padding: 20,
    paddingTop: 0
  },
  head: {
    height: 50,
    width: "100%",
    padding: 5
  },
  action: {
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10
  },
  action2: {
    alignItems: "center",
    justifyContent: "center",
    margin:"auto",
    marginRight: 10
  },
  avatar: {
    width: 60,
    height: 60
  }
});
