import { Cloud } from "../infra";


export function salvar(usuario, callback) {
  Cloud.post("trello/card", usuario, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function atualizar(usuario, callback) {
  Cloud.put("trello/card", usuario, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function atualizarOrden(usuario, callback) {
  Cloud.put("trello/card/orden", usuario, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function remover({id}, callback) {
  Cloud.delete("trello/card", {id}, (res, error) => {
    if (callback) callback(res, error);
  });
}


export function listar(input, callback) {
  Cloud.get("trello/card", input, (res, error) => {
    if (callback) callback(res, error);
  });
}
