import React, { Component } from "react";
import ListSubheader from "@material-ui/core/ListSubheader";

import {
  TouchableOpacity,
  View,
  Text,
  TextInput,
  StyleSheet,
  Icon,
  OpenSnack
} from "react-1app";
import * as Ingresso from "../../worker/ingresso";
import styleGlobal from "../../styleGlobal";
import { Util } from "../../infra";
import * as Usuario from "../../worker/usuario";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import ListView from "react-1app/lib/ListView";
import ListItem from "@material-ui/core/ListItem";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
import ListItemText from "@material-ui/core/ListItemText";

export default class EditarIngresso extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ingresso: { ...this.props.ingresso },
      value: 0,
      parametros: {
        limit: 10,
        page: 0,
        orden: "criacao"
      },
      busca: ""
    };
  }

  componentDidMount() {
    this.buscar();
  }

  componentWillUnmount() {}

  componentWillReceiveProps(nextProps) {
    if (this.state.ingresso != nextProps.ingresso) {
      this.setState({ ingresso: nextProps.ingresso });
    }
  }

  buscar() {
    this.setState({ load: true });
    let ids_usuarios=this.props.ingresso.ids_usuarios;
    Usuario.listar(
      {
        ...this.state.parametros,
        ids_usuarios: ids_usuarios&&ids_usuarios[0]&&ids_usuarios || [0]
      },
      (dados = { list: [], total: 0 }, error) => {
        this.setState({
          list: dados.list,
          total: dados.total,
          load: false
        });
      }
    );
  }

  buscar2() {
    this.setState({ load2: true });
    Usuario.listar(
      { limit: 20, busca: this.state.busca },
      (dados = { list: [], total: 0 }, error) => {
        this.setState({
          list2: dados.list.filter(
            a => !(this.props.ingresso.ids_usuarios || []).find(e => a.id == e)
          ),
          load2: false
        });
      }
    );
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View type={"Paper"} style={styles.paper}>
            <View style={styleGlobal.Grid} container spacing={16}>
              <View>
              <ListSubheader style={{ flex: 1 }} component="div">
                {"Usuários com acesso a este ingresso (Ingresso ativo)"}
              </ListSubheader>
              <Tabs
                value={this.state.value}
                onChange={(ii, value) => this.setState({ value })}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Vinculados" />
                <Tab label="Vincular novo" />
              </Tabs>
              </View>
              <ListSubheader style={{ flex: 1 }} component="div">
                {"Se a opção APP estiver ativa, não é necessário marcar nenhum usuário, pois não terá efeito na lista de ingressos."}
                <br/>
                {"É preciso salvar o ingresso para ter validade a alteração."}
              </ListSubheader>

              {this.state.value == 0 && (
                <ListView
                  ListHeaderComponent={() => (
                    <View style={styles.head}>
                      <Text
                        style={[styleGlobal.SubHeader, { marginRight: "auto" }]}
                      >
                        {"Usuarios"}
                      </Text>
                    </View>
                  )}
                  renderItem={({ item, index }) => {
                    return (
                      <ListItem
                        key={index}
                        style={{ width: "100%" }}
                        selected={this.state.index === index}
                      >
                        <Avatar src={item.foto || "/img/user.png"} />
                        <ListItemText
                          primary={item.nome}
                          secondary={
                            item.email +
                            " | " +
                            item.id +
                            " | " +
                            moment(item.criado).format("DD/MM/YYYY HH:mm")
                          }
                        />
                        <Icon
                          name={"delete"}
                          onClick={() => {
                            this.props.ingresso.ids_usuarios = this.props.ingresso.ids_usuarios.filter(
                              a => a != item.id
                            );
                            this.buscar();
                          }}
                        />
                      </ListItem>
                    );
                  }}
                  separator
                  nativo
                  data={this.state.list}
                  tablePagination
                  load={this.state.load}
                  count={Number(this.state.total)}
                  page={this.state.parametros.page}
                  rowsPerPage={this.state.parametros.limit}
                  handleChangePage={(event, page) => {
                    this.setState(
                      {
                        parametros: Object.assign(this.state.parametros, {
                          page
                        }),
                        load: true
                      },
                      this.buscar()
                    );
                  }}
                  handleChangeRowsPerPage={event => {
                    this.setState(
                      {
                        parametros: Object.assign(this.state.parametros, {
                          limit: event.target.value,
                          page: 0
                        }),
                        load: true
                      },
                      this.buscar()
                    );
                  }}
                />
              )}
              {this.state.value == 1 && (
                <ListView
                  ListHeaderComponent={() => (
                    <View style={styles.head}>
                      <Text
                        style={[styleGlobal.SubHeader, { marginRight: "auto" }]}
                      >
                        {"Usuarios Para adiciona"}
                      </Text>
                      <TextInput
                        value={this.state.busca}
                        style={{ width: 300, marginLeft: "auto" }}
                        onChange={value => {
                          this.setState(
                            {
                              busca: value
                            },
                            () => {
                              this.buscar2();
                            }
                          );
                        }}
                        keyboardType={"default"}
                        label={"Buscar por nome, email e id"}
                        onSubmitEditing={() => {
                          this.buscar();
                        }}
                      />
                    </View>
                  )}
                  renderItem={({ item, index }) => {
                    return (
                      <ListItem
                        button
                        key={index}
                        style={{ width: "100%" }}
                        selected={this.state.index === index}
                        onClick={event => {
                          console.log(this.props.ingresso);
                          if (!this.props.ingresso.ids_usuarios)
                            this.props.ingresso.ids_usuarios = [];
                          this.props.ingresso.ids_usuarios.push(item.id);
                          this.state.parametros.page = 0;
                          this.setState(
                            { value: 0, busca: "", list2: [] },
                            () => {
                              this.buscar();
                            }
                          );
                        }}
                      >
                        <Avatar src={item.foto || "/img/user.png"} />
                        <ListItemText
                          primary={item.nome}
                          secondary={
                            item.email +
                            " | " +
                            item.id +
                            " | " +
                            moment(item.criado).format("DD/MM/YYYY HH:mm")
                          }
                        />
                      </ListItem>
                    );
                  }}
                  separator
                  nativo
                  data={this.state.list2}
                  load={this.state.load2}
                />
              )}

              <View style={styles.action}>
                <TouchableOpacity
                  onPress={() => this.props.closeModal()}
                  style={styles.delete}
                  children={"Fechar"}
                  color={"default"}
                  variant="outlined"
                ></TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  action: {
    padding: 10,
    justifyContent: "center"
  },
  paper: {
    padding: 20,
    marginTop: 50
  },
  delete: {
    marginLeft: 20
  }
});
