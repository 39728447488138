import React from 'react';
import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer';
import LineChart from 'recharts/lib/chart/LineChart';
import Line from 'recharts/lib/cartesian/Line';
import XAxis from 'recharts/lib/cartesian/XAxis';
import YAxis from 'recharts/lib/cartesian/YAxis';
import CartesianGrid from 'recharts/lib/cartesian/CartesianGrid';
import Tooltip from 'recharts/lib/component/Tooltip';
import Legend from 'recharts/lib/component/Legend';

const data = [{"dia":"11/06","ingresso":"1"},
{"dia":"11/13","ingresso":"7"},
{"dia":"11/21","ingresso":"2"},
{"dia":"11/22","ingresso":"3"}];

function SimpleLineChart({lista=data,dataKey=[]}) {
  return (
    // 99% per https://github.com/recharts/recharts/issues/172
    <ResponsiveContainer width="99%" height={320}>
      <LineChart data={lista}>
        <XAxis dataKey="dia" stroke="#fff"/>
        <YAxis fill="#fff" stroke="#fff" />
        <CartesianGrid vertical={false} stroke="#fff"  strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        {dataKey.map(line=>(
          <Line type="monotone" fill="#fff" {...line} />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
}

export default SimpleLineChart;
