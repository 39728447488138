import React from "react";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Text from "react-1app/lib/Text";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Icon from "react-1app/lib/Icon";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import * as projetos from "../../worker/projetos";
import * as actions from "../../redux/actions";
import { Cloud } from "../../infra";
import ListSubheader from "@material-ui/core/ListSubheader";
import Hidden from "@material-ui/core/Hidden";
import { OpenModal } from "react-1app/lib/PgModal";
import NovaCasa from "../configuracoes/NovaCasa";

class SimpleListMenu extends React.Component {
  state = {
    anchorEl: null,
    selectedIndex: 1,
    user: this.props.screenProps.store.getState().user,
    projeto: this.props.screenProps.store.getState().projeto,
    projetos: this.props.screenProps.store.getState().projetos || []
  };

  handleClickListItem = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (
        store.projeto != this.state.projeto ||
        store.projetos != this.state.projetos ||
        store.user != this.state.user
      )
        this.setState({
          projetos: store.projetos,
          projeto: store.projeto,
          user: store.user,
          anchorEl: null
        });
    });
  }

  setProjeto(projeto) {
    if (!projeto || projeto.token === Cloud.getTokenEmpresa()) return;
    Cloud.setTokenEmpresa(projeto.token);

    projetos.get(projeto.id, (projeto, error) => {
      this.props.screenProps.store.dispatch(actions.setProjeto(projeto));
    });
  }

  openNew() {
    OpenModal(NovaCasa, {
      atualizar: item => {
        this.setProjeto(item);
        setTimeout(() => {
          window.location.href = "/";
        }, 200);
      }
    });
  }

  render() {
    const { anchorEl } = this.state;
    return [
      <Hidden smDown key={"key_maior"}>
        <Button
          color="inherit"
          aria-owns={anchorEl ? "simple-menu" : undefined}
          aria-haspopup="true"
          variant="outlined"
          style={{ minWidth: 250, margin: 5 }}
          onClick={this.handleClickListItem}
        >
          <Avatar
            src={(this.state.projeto || {}).logo}
            style={{ marginRight: 10 }}
          >
            <Icon name={"image"} />
          </Avatar>
          <Text
            color="inherit"
            children={(this.state.projeto || {}).nome || "Aguarde ..."}
          />
        </Button>
      </Hidden>,
      <Hidden mdUp key={"key_menor"}>
        <Button
          color="inherit"
          aria-owns={anchorEl ? "simple-menu" : undefined}
          aria-haspopup="true"
          variant="outlined"
          style={{ minWidth: 50, margin: 5 }}
          onClick={this.handleClickListItem}
        >
          <Avatar src={(this.state.projeto || {}).logo}>
            <Icon name={"image"} />
          </Avatar>
        </Button>
      </Hidden>,
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={this.handleClose}
      >
        <ListSubheader style={{ flex: 1 }} component="div">
          {"Casas de evento"}
        </ListSubheader>
        {this.state.projetos.map((option, index) => (
          <MenuItem
            key={index}
            disabled={option.id === (this.state.projeto || {}).id}
            selected={option.id === (this.state.projeto || {}).id}
            style={{ marginBottom: 5 }}
            onClick={event => this.setProjeto(option)}
          >
            <ListItemIcon>
              <Avatar src={option.logo}>
                <Icon name={"image"} />
              </Avatar>
            </ListItemIcon>
            <ListItemText inset primary={option.nome} />
          </MenuItem>
        ))}
        {this.state.user.adm ? (
          <MenuItem
            key={"new"}
            style={{ marginBottom: 5 }}
            onClick={event => this.openNew()}
          >
            <ListItemIcon>
              <Avatar>
                <Icon name={"add"} />
              </Avatar>
            </ListItemIcon>
            <ListItemText inset primary={"Nova Casa"} />
          </MenuItem>
        ) : null}
      </Menu>
    ];
  }
}

export default SimpleListMenu;
