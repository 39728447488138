import React from "react";

import { StyleSheet, View, TouchableOpacity } from "react-1app";
import { Util } from "../../infra";
import { getEnderecosCep, getEnderecosGoogle } from "../../worker/endereco";
import Endereco from "./Endereco";
import styleGlobal from "../../styleGlobal";

class ModalEndereco extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      endereco: this.props.endereco ? this.props.endereco : {},
      loadCep: false,
      manual: false
    };

    this.handleChange = (name, mask, limit, font) => event => {
      let endereco = this.state.endereco;
      this.setState({
        endereco: {
          ...endereco,
          [name]: Util.maskFormate(event, mask, limit, font)
        }
      });
    };

    this.handleChangeC = name => event => {
      this.setState({
        manual: event.target.checked
      });
    };
  }

  _getEndereco(cep) {
    if (cep.length > 8) {
      var dados = cep.replace(/[^\d]+/g, "");
      getEnderecosCep(dados, ret => {
        if (ret && !ret.erro) {
          this.setState({
            erroCep: false,
            loadCep: false,
            endereco: {
              ...this.state.endereco,
              cep: ret.cep,
              cidade: ret.cidade,
              rua: ret.rua,
              estado: ret.estado,
              uf: ret.estado,
              bairro: ret.bairro,
              complemento: ret.complemento
            }
          });
        } else {
          this.setState({ erroCep: true, loadCep: false });
        }
      });
    }
  }

  _atualizarCoordenadas(endereco) {
    // var endereco = this.montarEndereco(endereco)
    getEnderecosGoogle(endereco, (lat, lng, dados) => {
      this.setState({
        endereco: {
          ...endereco,
          latitude: lat,
          longitude: lng
        }
      });
    });
  }

  montarEndereco(endereco) {
    var temp = endereco.rua ? endereco.rua + ", " : "";
    temp += endereco.numero ? endereco.numero + ", " : "";
    temp += endereco.bairro ? endereco.bairro + ", " : "";
    temp += endereco.cidade ? endereco.cidade + ", " : "";
    temp += endereco.estado ? endereco.estado + ", " : "";
    temp += endereco.cep ? endereco.cep + ", " : "";
    return temp;
  }

  montarSalvar(endereco) {
    // this._atualizarCoordenadas(endereco)
    var enderecoFormatado = this.montarEndereco(endereco) + "BRASIL";
    endereco.endereco = enderecoFormatado;

    this.props.salvarendereco(endereco);
  }

  // Funções de teste dos campos de preenchimento obrigatório

  testarCampos() {
    var erro = false;
    erro = this.testeCep() || erro;
    erro = this.testeCidade() || erro;
    erro = this.testeEstado() || erro;
    erro = this.testeRua() || erro;
    erro = this.testeBairro() || erro;
    erro = this.testeNumero() || erro;
    return erro;
  }

  testeCep() {
    let endereco = this.state.endereco;
    if (endereco.cep && endereco.cep.length > 8) {
      this.setState({
        erroCep: false
      });
      return false;
    } else {
      this.setState({
        erroCep: true
      });
      return true;
    }
  }

  testeCidade() {
    let endereco = this.state.endereco;
    if (endereco.cidade && endereco.cidade.length > 1) {
      this.setState({
        erroCidade: false
      });
      return false;
    } else {
      this.setState({
        erroCidade: true
      });
      return true;
    }
  }

  testeRua() {
    let endereco = this.state.endereco;
    if (endereco.rua && endereco.rua.length > 1) {
      this.setState({
        erroRua: false
      });
      return false;
    } else {
      this.setState({
        erroRua: true
      });
      return true;
    }
  }

  testeEstado() {
    let endereco = this.state.endereco;
    if (endereco.estado && endereco.estado.length > 1) {
      this.setState({
        erroEstado: false
      });
      return false;
    } else {
      this.setState({
        erroEstado: true
      });
      return true;
    }
  }

  testeBairro() {
    let endereco = this.state.endereco;
    if (endereco.bairro && endereco.bairro.length > 1) {
      this.setState({
        erroBairro: false
      });
      return false;
    } else {
      this.setState({
        erroBairro: true
      });
      return true;
    }
  }

  testeNumero() {
    let endereco = this.state.endereco;
    if (endereco.numero && endereco.numero.length > 1) {
      this.setState({
        erroNumero: false
      });
      return false;
    } else {
      this.setState({
        erroNumero: true
      });
      return true;
    }
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View type={"Paper"} style={styles.paper}>
            <View container justify="center" spacing={8}>
              <Endereco
                endereco={this.state.endereco}
                onChange={endereco => {
                  this.setState({ endereco });
                }}
              />

              <View
                item
                xs={6}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <TouchableOpacity
                  variant="outlined"
                  children={"Salvar"}
                  onClick={() => {
                    this.montarSalvar(this.state.endereco);
                    this.props.closeModal();
                  }}
                ></TouchableOpacity>
              </View>
              <View
                item
                xs={6}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <TouchableOpacity
                  variant="outlined"
                  children={"Fechar"}
                  color={"default"}
                  onClick={() => {
                    this.props.closeModal();
                  }}
                ></TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  paper: {
    padding: 20,
    marginTop: 50
  }
});
export default ModalEndereco;
