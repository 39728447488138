import * as types from "./actionTypes";
import { loadUser } from "../worker/login";
import cookie from "react-cookies";

export default function app(state = initState, action = {}) {
  console.log(action.type);
  switch (action.type) {
    case types.LOGOUT:
      delete state.user;
      state.empresaConfig = {};
      return state;

    case types.LOGIN:
      // state.user = action.user;
      return Object.assign(state, { user: { ...action.user } });

    case types.EMPRESA_CONFIG:
      state.empresaConfig = action.empresaConfig;
      state.empresaConfig.fotos = state.empresaConfig.fotos
        ? state.empresaConfig.fotos
        : [{}, {}, {}];
      return state;

    case types.SET_THEMA:
      cookie.save("theme", action.theme);
      return Object.assign(state, { theme: action.theme });

    case "PROJETOS":
      return Object.assign(state, { projetos: action.data || [] });

    case "PROJETO":
      return Object.assign(state, {
        empresa: action.data || {},
        projeto: action.data || {},
        regras: (action.data || {}).regras || {}
      });
    case "EMPRESA":
      return Object.assign(state, {
        empresa: action.data || {},
        projeto: action.data || {}
      });

    default:
      return state;
  }
}

const initState = {
  user: loadUser(),
  empresaConfig: {},
  theme: cookie.load("theme") || "dark",
  empresa: {},
  projetos: [],
  projeto: {},
  regras: {}
};
