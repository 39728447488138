let host = "https://party-api-controle.1app.com.br/v1";

// host = "http://192.168.0.104:7000/v1";

let token_api = "89d40b8b3d404bb53bhe443b65b14";
var token_user = "";
var token_marketplace = "";
let preBoby = { plataforma: "dash" };

if (window.location.hostname !== "localhost") {
  console.log = function() {};
  host = "https://party-api-controle.1app.com.br/v1";
}else {
  window.dev=true;
}

function motarHead(headers) {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    "x-request-id": token_api,
    "token-user": token_user,
    "token-marketplace": token_marketplace
  };
}

export default {
  getHost() {
    return host;
  },
  getToken() {
    return token_api;
  },
  setTokenUser(token) {
    token_user = token;
  },
  setTokenEmpresa(token) {
    token_marketplace = token;
  },
  getTokenEmpresa() {
    return token_marketplace;
  },
  getTokenUser() {
    return token_user;
  },
  post(metodo, data, retorno) {
    var url = host + "/" + metodo;
    // console.log(url);
    var config = {
      method: "POST",
      headers: motarHead(),
      body: JSON.stringify({ ...data, ...preBoby })
    };

    this.onFetch(url, config, retorno);
  },
  get(metodo, data, retorno) {
    data = { ...data, ...preBoby };

    var url = host + "/" + metodo;
    if (data) {
      url += "?";
      var lista = Object.keys(data);
      for (var i = 0; i < lista.length; i++) {
        var item = lista[i];
        if (
          data[item] + "" === "undefined" ||
          data[item] + "" === "null" ||
          data[item] + "" === "" ||
          data[item] + "" === "false"
        )
          continue;
        if (i !== 0) {
          url += "&";
        }
        // url += "" + item + "=" + encodeURI(data[item]);
        url +=
          "" +
          item +
          "=" +
          (this.isObject(data[item]) || this.isArray(data[item])
            ? JSON.stringify(data[item])
            : encodeURI(data[item]));
      }
    }
    console.log(url);
    var config = {
      method: "GET",
      headers: motarHead()
    };
    this.onFetch(url, config, retorno);
  },
  put(metodo, data, retorno) {
    var url = host + "/" + metodo;
    // console.log(url);
    var config = {
      method: "PUT",
      headers: motarHead(),
      body: JSON.stringify({ ...data, ...preBoby })
    };

    this.onFetch(url, config, retorno);
  },
  delete(metodo, data, retorno) {
    var url = host + "/" + metodo;
    // console.log(url);
    var config = {
      method: "DELETE",
      headers: motarHead(),
      body: JSON.stringify({ ...data, ...preBoby })
    };

    this.onFetch(url, config, retorno);
  },
  onFetch(url = "", config = {}, retorno = console.log) {
    fetch(url, config)
      .then(response => {
        this.onResolverResponse(response, (sucess, error) => {
          if (error) console.log(error);
          retorno(sucess, error);
        });
      })
      .catch(error => {
        if (retorno) retorno(undefined, error);
      });
  },
  onResolverResponse(response, retorno) {
    if (!response || !response.json)
      return retorno(undefined, { msg: "not JSON" });
    var p1 = response.json();
    p1.then((responseData, error) => {
      if (response.status !== 200) {
        retorno(undefined, responseData);
      } else if (error) {
        retorno(undefined, error);
      } else {
        retorno(responseData);
      }
    });
  },
  isObject(val) {
    return typeof val === "object";
  },
  isArray(object) {
    if (object && JSON.stringify(object) === "[]") {
      return true;
    }
    if (object && object.constructor && object.constructor === Array) {
      return true;
    } else {
      return false;
    }
  }
};
