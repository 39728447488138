import React from "react";
import Grafico from "../../components/GraficoBar";
import {
  Table,
  View,
  TouchableOpacity,
  OpenModal,
  Icon,
  Text
} from "react-1app";
import { Util } from "../../infra";
import moment from "moment";
import withStyles from "@material-ui/core/styles/withStyles";
import { OpenSnack } from "react-1app/lib/SnackBar";
import styleGlobal from "../../styleGlobal";
import { json2excel } from "js2excel";
import * as Produtos from "../../worker/produtos";

class BodyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      evento: this.props.evento || {},
      list: [],
      listH: [],
      listG: []
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.evento && this.props.evento)
      this.setState(
        {
          evento: nextProps.evento,
          list: [],
          load: true
        },
        () => {
          this.buscar();
        }
      );
    return true;
  }
  componentDidMount() {
    this.buscar();
  }
  buscar() {
    this.setState({ load: true });
    if (!this.props.evento.id) return;
    Produtos.relatorioTotal(
      { id_evento: this.props.evento.id },
      (data = [], error) => {
        let list = data.filter(e => Number(e.vendido));
        let totalizador = {
          nome: "Total",
          app: 0,
          pdv: 0,
          vendido: 0,
          total: 0
        };
        for (var i = 0; i < list.length; i++) {
          // list[i]
          totalizador.app += Number(list[i].app);
          totalizador.pdv += Number(list[i].pdv);
          totalizador.vendido += Number(list[i].vendido);
          totalizador.total += Number(list[i].total);
        }
        let { listG, listH } = this.ajusteGrafico(list);
        this.setState({
          listG,
          listH,
          load: false,
          list: [...list, {}, totalizador],
          data
        });
      }
    );
  }

  ajusteGrafico(list) {
    let listH = [
      { dataKey: "pdv", fill: "#8884d8" },
      { dataKey: "app", fill: "#82ca9d" }
    ];
    let listG = list.map(a => ({ ...a, name: a.nome }));
    return { listG, listH };
  }

  exportToExcel() {
    var data = this.state.data.map(item => ({
      Nome: item.nome,
      Total: item.total,
      App: item.app,
      Pdv: item.pdv,
      Valor: Util.parseMoney(item.vendido),
    }));
    console.log(data);
    try {
      json2excel({
        data,
        name: "Lista de produtos",
        formateDate: "yyyy/mm/dd"
      });
    } catch (e) {
      console.log(e);
      console.error("export error");
      alert("Erro ao criar");
    }
  }

  render() {
    return [
      <View
        type={"Paper"}
        classes={{
          root: this.props.classes.cardHeader
        }}
        style={{ padding: 10, marginBottom: 20 }}
      >
        <Grafico dataKey={this.state.listH} lista={this.state.listG} />
      </View>,
      <Text style={{ margin: 10, fontSize: 12 }}>
        {`
          OBS: Este relatório contabiliza no período entre o início e fim do evento definido no cadastro de evento:
No APP representa a validação de produtos vendidos no app e entregue no período.
No PDV representa vendas produtos no período.
`}
      </Text>,
      <Table
        tableHead={["Nome", "Vendido", "APP", "PDV", "V. Total"]}
        tableData={this.state.list.map(item => [
          item.nome,
          item.total,
          item.app,
          item.pdv,
          item.vendido && Util.parseMoney(item.vendido)
        ])}
        title={"Lista de resultado dos produtos"}
        actionBar={
          <TouchableOpacity
            children={"Exportar dados"}
            variant={"outlined"}
            load={this.state.load2}
            onPress={() => {
              this.exportToExcel();
            }}
          />
        }
        load={this.state.load}
      />
    ];
  }
}

let styles = tema => ({
  cardHeader: {
    display: "flex",
    marginRight: 15,
    marginLeft: 15,
    background: tema.palette.primary.main,
    boxShadow:
      "0 12px 20px -10px rgba(0, 0, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 0, 0, 0.2)"
    // paddingRight: 10,
  }
});
// export default BodyModal;
export default withStyles(styles)(BodyModal);
