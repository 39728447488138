import React from "react";
import ListSubheader from "@material-ui/core/ListSubheader";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Icon from "react-1app/lib/Icon";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListView from "react-1app/lib/ListView";
import * as Pdv from "../../worker/pdv";
import moment from "moment";
import { Util } from "../../infra";
import CxResumoFecha from "./CxResumoFecha";
import { OpenModal } from "react-1app/lib/PgModal";

export default class IngressoView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {},
      load: true,
      list: [],
      listaCaixa: [],
      parametros: {
        limit: 100,
        page: 0,
        id_empresa: this.props.screenProps.store.getState().projeto.id,
        id_caixa_padrao: this.props.caixa_padrao.id
      },
      caixa_padrao: this.props.caixa_padrao || {},
      projeto: this.props.screenProps.store.getState().projeto || {}
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.projeto != this.state.projeto)
        this.setState(
          {
            projeto: store.projeto,
            parametros: {
              ...this.state.parametros,
              id_empresa: store.projeto.id
            },
            anchorEl: null
          },
          () => {
            this.buscar();
          }
        );
    });
    this.buscar();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.caixa_padrao &&
      this.props.caixa_padrao &&
      nextProps.caixa_padrao.id != this.props.caixa_padrao.id
    )
      this.setState(
        {
          caixa_padrao: nextProps.caixa_padrao,
          list: [],
          caixa_padrao: nextProps.caixa_padrao,
          parametros: {
            ...this.state.parametros,
            id_caixa_padrao: nextProps.caixa_padrao.id
          },
          load: true
        },
        () => {
          this.buscar();
        }
      );
    return true;
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  buscar() {
    if (
      !this.state.parametros.id_caixa_padrao ||
      !this.state.parametros.id_empresa
    )
      return this.setState({ list: [], load: false });
    Pdv.listarCaixas(this.state.parametros, (dados = [], error) => {
      console.log(dados);
      this.setState({
        list: dados,
        load: false
      });
    });
  }

  abrirTotal() {
    var resumo = this.props.caixa_padrao.resumo;
    this.moverParaOFinal("SUPRIMENTO", resumo);
    this.moverParaOFinal("SANGRIA", resumo);
    this.moverParaOFinal("DINHEIRO", resumo);
    this.moverParaOFinal("CREDITO", resumo);
    this.moverParaOFinal("DEBITO", resumo);
    this.moverParaOFinal("REIMPRESSOES", resumo);
    this.moverParaOFinal("INGRESSOS", resumo);
    this.moverParaOFinal("BAR", resumo);
    this.moverParaOFinal("VALOR EM CAIXA", resumo);

    this.openRelatorio({ ...this.props.caixa_padrao, totalizador: true });
  }
  moverParaOFinal(tag, resumo) {
    var foco = null;
    for (let i = 0; i < resumo.length; i++) {
      let item = resumo[i];
      if (item.nome == tag) {
        foco = item;
        resumo.splice(i, 1);
        break;
      }
    }
    if (foco) resumo.push(foco);
  }

  openRelatorio(item) {
    if (!item.resumo) return alert("Resumo do caixa não declarado");
    OpenModal(CxResumoFecha, {
      item,
      totalizador: item.totalizador,
      lista: item.resumo,
      atualizar: () => this.buscar()
    });
  }

  getName(item) {
    return (
      moment(item.criado).format("DD-MM") +
      " " +
      (item.tipo || "pdv").toUpperCase() +
      " com " +
      item.nome_usuario
    );
  }

  getTitleTotal() {
    return "TOTALIZADOR " + Util.parseMoney(this.state.caixa_padrao.total);
  }

  render() {
    return (
      <View type={"Paper"}>
        <ListView
          load={this.state.load}
          ListHeaderComponent={() => (
            <View style={styles.head}>
              <ListSubheader component="div">
                {"Caixas que foram abertos com este parão"}
              </ListSubheader>
              <TouchableOpacity
                children={this.getTitleTotal()}
                style={styles.add}
                variant="contained"
                onPress={() => {
                  this.abrirTotal();
                }}
              />
            </View>
          )}
          renderItem={({ item, index }) => {
            return (
              <ListItem
                button
                key={index}
                style={{ width: "100%" }}
                selected={this.state.index === index}
                onClick={event => this.openRelatorio(item)}
              >
                <Avatar src={item.img}>
                  <Icon name={"account_balance_wallet"} />
                </Avatar>
                <ListItemText
                  primary={this.getName(item)}
                  secondary={
                    moment(item.criado).fromNow() +
                    ` | caixa: ${
                      item.fechamento
                        ? "FECHADO | valor:" +
                          Util.parseMoney(item.valor_vendas)
                        : "ABERTO"
                    }`
                  }
                />
                <Icon color="default" name={"visibility"} style={styles.icon} />
              </ListItem>
            );
          }}
          separator
          data={this.state.list}
        />
      </View>
    );
  }
}

const styles = {
  root: {
    width: 500
  },
  icon: {
    margin: 15
  },
  cell: {
    width: "100%"
  },
  head: {
    height: 50,
    width: "100%",
    padding: 5
  },
  image: {
    maxWidth: 600,
    maxHeight: 600 / (16 / 9),
    height: "100vh",
    margin: "auto",
    borderRadius: 5,
    background: "rgba(0,0,0,0.1)"
  },
  gridEdit: {
    flex: 1,
    maxWidth: 800
  },
  view91: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: null,
    marginLeft: 5,
    marginRight: 5,
    borderRadius: 2,
    padding: 5,
    height: 30
  },
  view: {
    padding: 20,
    paddingTop: 0
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  add: {
    marginLeft: "auto"
  },
  delete: {
    marginLeft: 20
  }
};
