let messaging = null;
try {
  if (window.firebase && window.firebase.messaging) {
    messaging = window.firebase.messaging();
  }
} catch (ex) {
  console.log(ex);
}

export function init(callback) {
  if (messaging) {
    messaging
      .requestPermission()
      .then(function() {
        console.log("Notification permission granted.");
        callback();
      })
      .catch(function(err) {
        console.log("Unable to get permission to notify.", err);
      });
  }
}

export function getToken(callback) {
  if (messaging) {
    messaging
      .getToken()
      .then(function(currentToken) {
        if (currentToken) {
          // console.log(currentToken);
          if (callback) callback(currentToken);
        } else {
          callback(
            null,
            "No Instance ID token available. Request permission to generate one."
          );
          console.log(
            "No Instance ID token available. Request permission to generate one."
          );
        }
      })
      .catch(function(err) {
        console.log("An error occurred while retrieving token. ", err);
      });
  }
}

if (messaging) {
  messaging.onMessage(function(payload) {
    console.log("Message received. ", payload);
    var opcoes = {
      body: payload.notification.body,
      icon: "img/logo.png"
    };
    var n = new Notification(payload.notification.title, opcoes);
  });
}
