import { Cloud } from "../infra";


export function salvar(ingresso, callback){
  Cloud.post('agenda/notificacao', ingresso, (res,error) => {
    if(callback) callback(res,error);
  })
}

export function atualizar(ingresso, callback){
  Cloud.put('agenda/notificacao/' + ingresso.id, ingresso, (res,error) => {
    if(callback) callback(res,error);
  })
}

export function remover(id, callback){
  Cloud.delete('agenda/notificacao/' + id, {}, (res,error) => {
    if(callback) callback(res,error);
  })
}

export function listar(input, callback){
  Cloud.get('agenda/notificacao', input, (res,error) => {
    if(callback) callback(res,error);
  })
}
