import { Cloud } from "../infra";

export function salvar(usuario, callback) {
  Cloud.post("usuarios", usuario, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function atualizar(usuario, callback) {
  Cloud.put("usuarios/" + usuario.id, usuario, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function remover(id, callback) {
  Cloud.delete("usuarios/" + id, {}, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function listar(input, callback) {
  console.log(input);
  Cloud.get("usuarios", input, (res, error) => {
    if (callback) callback(res, error);
  });
}



export function recuperarSenha(email, callback) {
  Cloud.put("usuarios/esqueceu/senha/" + email, {}, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function cartoes(input,callback){
  Cloud.get('usuarios/cartoes' , input, (res=[],error) => {
    if(callback) callback(res,error);
  })
}
