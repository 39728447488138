import React, { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import TextInput from "react-1app/lib/TextInput";
import SelectInput from "react-1app/lib/SelectInput";
import Text from "react-1app/lib/Text";
import DatePicker from "react-1app/lib/DatePicker";
import View from "react-1app/lib/View";
import InputAdornment from "@material-ui/core/InputAdornment";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import { OpenSnack, StyleSheet } from "react-1app";
import { Cloud } from "../../infra";
import styleGlobal from "../../styleGlobal";
import Chip from "@material-ui/core/Chip";
import Switch from "react-1app/lib/Switch";
import * as WorkerConta from "../../worker/conta";
import Endereco from "./Endereco";
import Alert from "react-1app/lib/Alert";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";

export default class Conta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parametros: {},
      conta: {
        endereco: {},
        payment_plan: "1",
        porc_ingresso: "10",
        porc_bebida: "5"
        //       ...{ endereco:
        //  { cep: '96020000',
        //    cidade: 'Pelotas',
        //    rua: 'Rua General Osório',
        //    estado: 'RS',
        //    uf: 'RS',
        //    bairro: 'Centro',
        //    numero: '1205' },
        // payment_plan: '9',
        // porc_ingresso: '11',
        // porc_bebida: '6',
        // tipo_cadastro: 'PJ',
        // nome: 'Mariane oliveira de souza',
        // email: 'marianeoliveira@hotmail.com',
        // telefone: '(53) 9813-39358',
        // nacimento: '1992-12-28T15:51:00.000Z',
        // bank: '748',
        // account_type: 'C',
        // agency: '0663',
        // account: '25455',
        // account_digit: '8',
        // plataforma: 'dash',
        // id_empresa: 23,
        // doc: '23.626.155/0001-33',
        // trade_name: 'Hoax party' }
      },
      criar: false,
      load: true
    };
  }

  componentDidMount() {
    this.buscar();
  }

  buscar() {
    this.setState({ load: true });
    WorkerConta.listar(this.state.parametros, (dados = {}, error) => {
      console.log(dados, error);
      this.setState({
        getnet: dados,
        criar: !dados || !dados.status,
        bank_accounts:dados.empresa&&dados.empresa.dados&&dados.empresa.dados&&dados.empresa.dados.bank_accounts&&dados.empresa.dados.bank_accounts.unique_account,
        porc_ingresso:dados&&dados.empresa&&dados.empresa.chave&&dados.empresa.chave.porc_ingresso||0,
        porc_bebida:dados&&dados.empresa&&dados.empresa.chave&&dados.empresa.chave.porc_bebida||0,
        load: false
      });
    });
  }

  alterarPorcentagem(){
    let {porc_ingresso,porc_bebida}=this.state;
    WorkerConta.alterarPorcentagem({porc_ingresso,porc_bebida}, (dados = {}, error) => {
      if (error) Alert.alert("Error", error.msg||error.error||JSON.stringify(error));
      this.setState({
        load: false
      });
      OpenSnack({ message: "Salvo com sucesso", type: "success" });
      this.buscar();
    });
  }

  criarConta() {
    // console.log(this.state.conta);
    this.setState({ load: true });
    WorkerConta.salvar(
      this.state.conta,
      (dados = { list: [], total: 0 }, error) => {
        this.setState({ load: false });
        if (error) Alert.alert("Error", error.msg||error.error||JSON.stringify(error));
        OpenSnack({ message: "Criado com sucesso", type: "success" });
        this.buscar();
      }
    );
  }

  onChange = tag => value => {
    this.setState({ conta: { ...this.state.conta, [tag]: value } });
  };

  componentWillUnmount() {}

  render() {
    return (
      <View type={"Paper"} style={{ padding: 10 }}>
        {this.state.load ? <LinearProgress /> : null}
        {this.state.getnet &&
        (this.state.getnet.report || this.state.getnet.status) ? (
          <View style={styleGlobal.Grid} container spacing={16}>
            <View style={styleGlobal.Grid} item xs={12}>
              <Typography variant="body1" gutterBottom>
                <pre>
                  <code>
                    {JSON.stringify(
                      {
                        status: this.state.getnet.status,
                        subseller_id: this.state.getnet.subseller_id,
                        ...(this.state.getnet.report || {}),
                        conta:this.state.bank_accounts||{}
                      },
                      null,
                      4
                    )}
                  </code>
                </pre>
              </Typography>
            </View>
            <View style={styleGlobal.Grid} item xs={6}>
              <TextInput
                value={this.state.porc_ingresso}
                onChange={value=>{
                  this.setState({porc_ingresso:value})
                }}
                keyboardType={"default"}
                label={"Comissão do Ingresso"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Text>{"%"}</Text>
                    </InputAdornment>
                  )
                }}
              />
            </View>
            <View style={styleGlobal.Grid} item xs={6}>
              <TextInput
                value={this.state.porc_bebida}
                onChange={value=>{
                  this.setState({porc_bebida:value})
                }}
                keyboardType={"default"}
                label={"Comissão do Bebida"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Text>{"%"}</Text>
                    </InputAdornment>
                  )
                }}
              />
            </View>
            <View
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TouchableOpacity
                variant="outlined"
                children={"Alterar Porcentagem"}
                onClick={() => {
                  this.alterarPorcentagem();
                }}
              ></TouchableOpacity>
            </View>
          </View>
        ) : null}

        {this.state.criar ? (
          <View style={styleGlobal.Grid} container spacing={16}>
            <View style={styleGlobal.Grid} item xs={12}>
              <Text style={styles.title}>{"Dados Pessoais"}</Text>
            </View>
            <View style={styleGlobal.Grid} item xs={12}>
              <SelectInput
                value={this.state.conta.tipo_cadastro}
                onChange={this.onChange("tipo_cadastro")}
                keyboardType={"default"}
                label={"Tipo de Cadastro"}
                key_label={"label"}
                key_value={"value"}
                list={[
                  { label: "Pessoa Física", value: "PF" },
                  { label: "Pessoa Jurídica", value: "PJ" }
                ]}
              />
            </View>
            {this.state.conta.tipo_cadastro ? (
              <>
                <View style={styleGlobal.Grid} item xs={12}>
                  <TextInput
                    value={this.state.conta.nome}
                    onChange={this.onChange("nome")}
                    keyboardType={"default"}
                    label={
                      this.state.conta.tipo_cadastro == "PF"
                        ? "Nome"
                        : "Nome da empresa"
                    }
                  />
                </View>
                {this.state.conta.tipo_cadastro == "PJ" ? (
                  <View style={styleGlobal.Grid} item xs={12}>
                    <TextInput
                      value={this.state.conta.trade_name}
                      onChange={this.onChange("trade_name")}
                      keyboardType={"default"}
                      label={"Nome fantasia"}
                    />
                  </View>
                ) : null}
                <View style={styleGlobal.Grid} item xs={12}>
                  <TextInput
                    value={this.state.conta.doc}
                    onChange={this.onChange("doc")}
                    keyboardType={"default"}
                    label={
                      "Documento " +
                      (this.state.conta.tipo_cadastro == "PJ"
                        ? "(CNPJ)"
                        : "(CPF)")
                    }
                    mask={
                      this.state.conta.tipo_cadastro == "PJ"
                        ? "99.999.999/9999-99"
                        : "999.999.999-99"
                    }
                  />
                </View>
                <View
                  style={styleGlobal.Grid}
                  item
                  xs={this.state.conta.tipo_cadastro == "PJ" ? 8 : 12}
                >
                  <TextInput
                    value={this.state.conta.email}
                    onChange={this.onChange("email")}
                    keyboardType={"default"}
                    label={"Email"}
                  />
                </View>
                {this.state.conta.tipo_cadastro == "PF" ? (
                  <View style={styleGlobal.Grid} item xs={6}>
                    <DatePicker
                      type={"date"}
                      value={this.state.conta.nacimento || null}
                      onChange={this.onChange("nacimento")}
                      keyboardType={"default"}
                      label={"Data de nacimento"}
                    />
                  </View>
                ) : null}
                <View
                  style={styleGlobal.Grid}
                  item
                  xs={this.state.conta.tipo_cadastro == "PJ" ? 4 : 6}
                >
                  <TextInput
                    value={this.state.conta.telefone}
                    onChange={this.onChange("telefone")}
                    keyboardType={"default"}
                    label={"Telefone"}
                    mask={"(99) 9999-99999"}
                  />
                </View>
                {this.state.conta.tipo_cadastro == "PF" ? (
                  <View style={styleGlobal.Grid} item xs={12}>
                    <TextInput
                      value={this.state.conta.mae}
                      onChange={this.onChange("mae")}
                      keyboardType={"default"}
                      label={"Nome da mãe"}
                    />
                  </View>
                ) : null}
                <View style={styleGlobal.Grid} item xs={12}>
                  <Text style={styles.title}>{"Dados da conta"}</Text>
                </View>

                <View style={styleGlobal.Grid} item xs={8}>
                  <TextInput
                    value={this.state.conta.bank}
                    onChange={this.onChange("bank")}
                    keyboardType={"default"}
                    label={"Banco"}
                  />
                </View>
                <View style={styleGlobal.Grid} item xs={4}>
                  <SelectInput
                    value={this.state.conta.account_type}
                    onChange={this.onChange("account_type")}
                    keyboardType={"default"}
                    label={"Tipo de conta"}
                    key_label={"label"}
                    key_value={"value"}
                    list={[
                      { label: "Conta corrente", value: "C" },
                      { label: "Conta poupança", value: "P" }
                    ]}
                  />
                </View>
                <View style={styleGlobal.Grid} item xs={8}>
                  <TextInput
                    value={this.state.conta.agency}
                    onChange={this.onChange("agency")}
                    type={"numeric"}
                    label={"Agencia"}
                    mask={"99999999999999"}
                  />
                </View>
                <View style={styleGlobal.Grid} item xs={4}>
                  <TextInput
                    value={this.state.conta.agency_digit}
                    onChange={this.onChange("agency_digit")}
                    type={"numeric"}
                    label={"Digito (se tiver)"}
                    mask={"99"}
                  />
                </View>
                <View style={styleGlobal.Grid} item xs={8}>
                  <TextInput
                    value={this.state.conta.account}
                    onChange={this.onChange("account")}
                    keyboardType={"default"}
                    label={"Conta"}
                  />
                </View>
                <View style={styleGlobal.Grid} item xs={4}>
                  <TextInput
                    value={this.state.conta.account_digit}
                    onChange={this.onChange("account_digit")}
                    keyboardType={"default"}
                    label={"Digito"}
                  />
                </View>
                <View style={styleGlobal.Grid} item xs={12}>
                  <Text style={styles.title}>{"Dados de Endereço"}</Text>
                </View>
                <View style={styleGlobal.Grid} item xs={12}>
                  <Endereco
                    {...this.props}
                    onChange={this.onChange("endereco")}
                    endereco={this.state.conta.endereco}
                    notGeo
                  />
                </View>
                <View style={styleGlobal.Grid} item xs={12}>
                  <Text style={styles.title}>{"Cifigurações"}</Text>
                </View>
                <View style={styleGlobal.Grid} item xs={12}>
                  <SelectInput
                    value={this.state.conta.payment_plan}
                    onChange={this.onChange("payment_plan")}
                    keyboardType={"default"}
                    label={"Período de recebimento"}
                    key_label={"label"}
                    key_value={"value"}
                    list={[
                      { label: "30 Dias", value: "1" },
                      { label: "7 Dias", value: "9" }
                    ]}
                  />
                </View>
                <View style={styleGlobal.Grid} item xs={6}>
                  <TextInput
                    value={this.state.conta.porc_ingresso}
                    onChange={this.onChange("porc_ingresso")}
                    keyboardType={"default"}
                    label={"Comissão do Ingresso"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Text>{"%"}</Text>
                        </InputAdornment>
                      )
                    }}
                  />
                </View>
                <View style={styleGlobal.Grid} item xs={6}>
                  <TextInput
                    value={this.state.conta.porc_bebida}
                    onChange={this.onChange("porc_bebida")}
                    keyboardType={"default"}
                    label={"Comissão do Bebida"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Text>{"%"}</Text>
                        </InputAdornment>
                      )
                    }}
                  />
                </View>
                <View
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <TouchableOpacity
                    variant="outlined"
                    children={"Criar"}
                    onClick={() => {
                      this.criarConta();
                    }}
                  ></TouchableOpacity>
                </View>
              </>
            ) : null}
          </View>
        ) : null}
      </View>
    );
  }
}

var styles = StyleSheet.create({
  viewcategorias: {
    flexWrap: "wrap",
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 5,
    borderColor: "gray",
    padding: 10
  },
  title: {
    marginTop: 20,
    marginLeft: 20
  }
});
