import { Cloud } from "../infra";


export function salvar(ingresso, callback){
  Cloud.post('ingressos', ingresso, (res,error) => {
    if(callback) callback(res,error);
  })
}

export function atualizar(ingresso, callback){
  Cloud.put('ingressos/' + ingresso.id, ingresso, (res,error) => {
    if(callback) callback(res,error);
  })
}

export function remover(id, callback){
  Cloud.delete('ingressos/' + id, {}, (res,error) => {
    if(callback) callback(res,error);
  })
}

export function listar(id, id_evento, callback){
  Cloud.get('ingressos', {id_ingresso: id, id_evento}, (res,error) => {
    if(callback) callback(res,error);
  })
}


export function statusVenda( id_ingresso, callback){
  Cloud.get('ingressos/status/venda', {id_ingresso}, (res,error) => {
    if(callback) callback(res,error);
  })
}

export function relatorioVenda( {id_evento}, callback){
  Cloud.get('ingressos/venda/relatorio', {id_evento}, (res,error) => {
    if(callback) callback(res,error);
  })
}


export function relatorioTotalDia( {id_evento}, callback){
  Cloud.get('ingressos/relatorio/grafico', {id_evento}, (res,error) => {
    if(callback) callback(res,error);
  })
}
