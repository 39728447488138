import React from "react";
import ListItem from "@material-ui/core/ListItem";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "react-1app/lib/Icon";
import Switch from "react-1app/lib/Switch";
import ListSubheader from "@material-ui/core/ListSubheader";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import TextInput from "react-1app/lib/TextInput";
// import DatePicker from "react-1app/lib/DatePicker";
import DatePicker from "react-1app/lib/DatePicker";
import { OpenSnack } from "react-1app/lib/SnackBar";
import moment from "moment";
import ListView from "react-1app/lib/ListView";
import { OpenModal } from "react-1app/lib/PgModal";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import * as Pdv from "../../worker/pdv";
import { Util } from "../../infra";
import CaixaAberto from "./CaixaAberto";
import ListItemCaixa from "../itemCaixa";
import NovoPdv from "./NovoPdv";
import AlterarCaixaAPP from "../pdv/AlterarCaixaAPP.js";
import PdvImagens from "../pdv/Imagens.js";
import styleGlobal from "../../styleGlobal";
import ModalVideoAjuda from "../ModalVideoAjuda";
import Image from "react-1app/lib/Image";

class Caixas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {},
      load: true,
      total: 0,
      value: 0,
      parametros: {
        limit: 10,
        page: 0,
        id_empresa: this.props.screenProps.store.getState().projeto.id,
        paginacao: true
      },
      projeto: this.props.screenProps.store.getState().projeto || {},
      list: []
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.projeto != this.state.projeto)
        this.setState(
          {
            projeto: store.projeto,
            item: {},
            value: 0,
            parametros: {
              ...this.state.parametros,
              id_empresa: store.projeto.id
            },
            anchorEl: null
          },
          () => {
            this.buscar();
          }
        );
    });
    this.buscar();
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  buscar() {
    Pdv.listar(
      this.state.parametros,
      (dados = { list: [], total: 0 }, error) => {
        this.setState({
          list: dados.list,
          total: dados.total,
          load: false
        });
      }
    );
  }

  getCaixa() {
    Pdv.get(this.state.item.id, (caixa, error) => {
      if (error) return alert(error.msg);
      console.log(caixa);
      if (caixa) this.setState({ load: false, item: caixa });
    });
  }

  salvar() {
    if (this.state.item.id && this.state.item.id != "new") {
      Pdv.atualizar(this.state.item, (caixa, error) => {
        this.onRes(caixa, error, "atualizar");
      });
    } else {
    }
  }

  onRes(caixa, error, tag) {
    if (error) {
      OpenSnack({ message: "Erro ao " + tag + " caixa!", type: "error" });
    } else {
      this.setState({ item: caixa });
      this.buscar();
      OpenSnack({
        message: "Caixa ao " + tag + " com sucesso!",
        type: "success"
      });
    }
  }

  remover() {
    if (window.confirm("Deseja remover este caixa padrão?")) {
      Pdv.remover(this.state.item, (caixa, error) => {
        if (error) {
          OpenSnack({ message: "Erro ao salvar caixa!", type: "error" });
        } else {
          this.setState({ item: {}, index: null });
          this.buscar();
          OpenSnack({ message: "Caixa removido!", type: "success" });
        }
      });
    }
  }

  novoCaixa() {
    OpenModal(NovoPdv, {
      caixa: {
        id_empresa: this.state.projeto.id,
        data_termino: moment()
          .add(4, "days")
          .toJSON(),
        data_inicio: moment().toJSON()
      },
      activity:this,
      historico: this.state.list,
      atualizar: () => this.buscar()
    });
  }

  editarImage() {
    OpenModal(PdvImagens, {
      redimencionar: true,
      alterarImagem: base => {
        this.state.item.logo_print = base;
        this.setState({ open: false });
      },
      logo_print:this.state.item.logo_print
    });
  }

  getImage() {
    return "data:image/png;base64," + this.state.item.logo_print;
  }

  render() {
    return (
      <View container spacing={16}>
        <View {...styleGlobal.gridListDimacao} item>
          <View type={"Paper"} style={styles.root}>
            <ListView
              load={this.state.load}
              ListHeaderComponent={() => (
                <View style={styles.head}>
                  <ListSubheader component="div">
                    {"CAIXAS PADRÕES"}
                  </ListSubheader>
                  <TouchableOpacity
                    children={"Ajuda"}
                    style={styles.add}
                    variant="default"
                    onPress={() => {
                      OpenModal(ModalVideoAjuda, {
                        url: "https://www.youtube.com/embed/jS5p3rjSPH0"
                      });
                    }}
                  />
                  <TouchableOpacity
                    children={"Novo"}
                    style={{ marginLeft: 10 }}
                    variant="outlined"
                    onPress={() => {
                      this.novoCaixa();
                    }}
                  />
                </View>
              )}
              renderItem={({ item, index }) => {
                return (
                  <ListItem
                    button
                    key={index}
                    style={{ width: "100%" }}
                    selected={this.state.index === index}
                    onClick={event =>
                      this.setState({ index, item }, () => {
                        this.getCaixa();
                        setTimeout(() => {
                          window.focusElementById("editar");
                        }, 100);
                      })
                    }
                  >
                    <Avatar
                      src={item.img}
                      style={{
                        backgroundColor:
                          moment(item.data_termino) > moment()||item.continuo
                            ? "springgreen"
                            : "yellow"
                      }}
                    >
                      <Icon name={"shopping_cart"} />
                    </Avatar>
                    <ListItemText
                      primary={item.nome}
                      secondary={"Vendas: " + Util.parseMoney(item.total)}
                    />
                    <Icon color="default" name={"create"} />
                  </ListItem>
                );
              }}
              separator
              data={this.state.list}
              count={Number(this.state.total)}
              page={this.state.parametros.page}
              rowsPerPage={this.state.parametros.limit}
              tablePagination
              handleChangePage={(event, page) => {
                this.setState(
                  {
                    parametros: Object.assign(this.state.parametros, { page }),
                    load: true
                  },
                  this.buscar()
                );
              }}
              handleChangeRowsPerPage={event => {
                this.setState(
                  {
                    parametros: Object.assign(this.state.parametros, {
                      limit: event.target.value,
                      page: 0
                    }),
                    load: true
                  },
                  this.buscar()
                );
              }}
            />
          </View>
        </View>
        <View {...styleGlobal.gridEditDimacao} item>
          {this.state.item && this.state.item.id ? (
            <View type={"Paper"} id="editar" style={styles.view}>
              <View style={styles.head}>
                <ListSubheader style={{ flex: 1 }} component="div">
                  {"CAIXA PADRÃO"}
                </ListSubheader>
                <Tabs
                  value={this.state.value}
                  onChange={(ii, value) => this.setState({ value })}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab label="Info" />
                  <Tab
                    label="Produtos"
                    disabled={
                      !this.state.item.id || this.state.item.id == "new"
                    }
                  />
                  <Tab
                    label="Caixas App"
                    disabled={
                      !this.state.item.id || this.state.item.id == "new"
                    }
                  />
                </Tabs>
              </View>
              {this.state.value == 0 ? (
                <View style={styles.view2} xs={12} item>
                  <View style={styles.action}>
                    <TouchableOpacity
                      onPress={() => this.editarImage()}
                      style={{ margin: "auto" }}
                      variant="outlined"
                      >
                      {this.state.item.logo_print ? (
                        <Image
                          style={styles.image}
                          resizeMode={"contain"}
                          source={{ uri: this.getImage() }}
                          resizeMode={"stretch"}
                          />
                      ) : null}
                      {!this.state.item.logo_print
                        ? "Clique aqui para adicionar"
                        : null}
                      </TouchableOpacity>
                    </View>
                  <TextInput
                    value={this.state.item.nome}
                    onChange={value => {
                      this.setState({
                        item: { ...this.state.item, nome: value }
                      });
                    }}
                    label={"Nome"}
                  />
                  <Switch
                    style={styles.switch}
                    value={this.state.item.continuo}
                    onChange={value => {
                      this.setState({
                        item: { ...this.state.item, continuo: value }
                      });
                    }}
                    label={"Contínuo"}
                  />
                  <DatePicker
                    style={styles.datepicker}
                    value={this.state.item.data_inicio}
                    onChange={value => {
                      this.setState({
                        item: { ...this.state.item, data_inicio: value }
                      });
                    }}
                    type={"date"}
                    label={"Data de Inicio"}
                    disabled={this.state.item.continuo}
                  />
                  <DatePicker
                    style={styles.datepicker}
                    value={this.state.item.data_termino}
                    onChange={value => {
                      this.setState({
                        item: { ...this.state.item, data_termino: value }
                      });
                    }}
                    label={"Data termino"}
                    type={"date"}
                    disabled={this.state.item.continuo}
                  />
                  <View style={styles.action}>
                    <TouchableOpacity
                      onPress={() => this.salvar()}
                      children={"Salvar"}
                      variant="outlined"
                    />
                    <TouchableOpacity
                      onPress={() => this.remover()}
                      disabled={
                        !this.state.item.id || this.state.item.id == "new"
                      }
                      style={styles.delete}
                      children={"Apagar"}
                      color={"secondary"}
                      variant="outlined"
                    />
                  </View>
                  {this.state.item.id && this.state.item.id != "new" ? (
                    <CaixaAberto
                      {...this.props}
                      caixa_padrao={this.state.item}
                    />
                  ) : null}
                </View>
              ) : null}
              {this.state.value == 1 ? (
                <ListItemCaixa
                  {...this.props}
                  historico={this.state.list}
                  caixa_padrao={this.state.item}
                />
              ) : null}
              {this.state.value == 2 ? (
                <AlterarCaixaAPP
                  {...this.props}
                  caixa_padrao={this.state.item}
                />
              ) : null}
            </View>
          ) : null}
        </View>
      </View>
    );
  }
}

const styles = {
  icon: {
    margin: 15
  },
  root: {},
  gridList: {},
  view2: {
    width: "100%"
  },
  head: {
    height: 60,
    width: "100%",
    padding: 5
  },
  image: {
    width: 100,
    height: 100,
    margin: "auto",
    borderRadius: 5,
    background: "rgba(0,0,0,0.1)"
  },
  gridEdit: {},
  view: {
    padding: 20,
    paddingTop: 0
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  add: {
    marginLeft: "auto"
  },
  delete: {
    marginLeft: 20
  }
};

export default Caixas;
