import React, { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import TextInput from "react-1app/lib/TextInput";
import View from "react-1app/lib/View";
import InputAdornment from "@material-ui/core/InputAdornment";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import { OpenSnack,StyleSheet } from "react-1app";
import { Cloud } from "../../infra";
import styleGlobal from "../../styleGlobal";
import Chip from "@material-ui/core/Chip";
import Switch from "react-1app/lib/Switch";

export default class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {tag: ""};
  }

  deleteTag(item) {
    this.props.empresa.categorias = this.props.empresa.categorias.filter(
      tag => tag != item
    );
    this.setState({ tag: "" });
  }

  rendercategorias() {
    return this.props.empresa.categorias.map(tag => {
      return (
        <Chip
          label={tag}
          color="primary"
          onDelete={() => this.deleteTag(tag)}
          style={{ margin: 5 }}
        />
      );
    });
  }

  render() {
    return (
      <View type={"Paper"} style={{ padding: 10 }}>
        <View style={styleGlobal.Grid} container spacing={16}>
          <View style={styleGlobal.Grid} item xs={12}>
            <TextInput
              value={Cloud.getTokenEmpresa()}
              disabled={true}
              keyboardType={"default"}
              label={"Token da empresa"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CopyToClipboard
                      text={Cloud.getTokenEmpresa()}
                      onCopy={() => {
                        OpenSnack({ message: "Copiado" });
                      }}
                    >
                      <TouchableOpacity children={"Copiar"}></TouchableOpacity>
                    </CopyToClipboard>
                  </InputAdornment>
                )
              }}
            />
          </View>
          <View style={styleGlobal.Grid} item xs={12}>
            <View style={styles.viewcategorias}>
              <TextInput
                value={this.state.tag}
                onChange={value => {
                  this.setState({ tag: value });
                }}
                onSubmitEditing={() => {
                  if (!this.props.empresa.categorias) {
                    this.props.empresa.categorias = [];
                  }
                  if (!this.state.tag) {
                    return OpenSnack({
                      message: "Entre com a categoria para adicioná-la!",
                      type: "error"
                    });
                  }
                  if (this.props.empresa.categorias.find(tag => tag === this.state.tag)) {
                    OpenSnack({
                      message: "Categoria já existente!",
                      type: "error"
                    });

                    return this.setState({ tag: "" });
                  }
                  this.props.empresa.categorias.push(this.state.tag);
                  this.setState({ tag: "" });
                }}
                placeholder={"Nova categoria"}
              />
              {this.props.empresa.categorias ? this.rendercategorias() : null}
            </View>
          </View>
          <View style={styleGlobal.Grid} item xs={12} md={4}>
            <TextInput
              value={this.props.empresa.mostrar_disponivel}
              onChange={value => {
                this.props.empresa.mostrar_disponivel = value;
                this.setState({ item: this.state.item });
              }}
              mask={"9999999999999999"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <View style={{ width: 200 }}>
                      <Switch
                        value={this.props.empresa.mostrar_disponivel+''=='0'}
                        onChange={value => {
                          // console.log(value);
                          if(value){
                          this.props.empresa.mostrar_disponivel="0"
                        }else {
                          this.props.empresa.mostrar_disponivel=''
                        }
                          this.setState({ item: this.state.item });
                        }}
                        label={"Nunca mostrar"}
                      />
                    </View>
                  </InputAdornment>
                )
              }}
              label={"Mostrar estoque de ingressos e bebidas a partir"}
            />
          </View>
          <View style={styleGlobal.Grid} item xs={12} md={4}>
            <TextInput
              value={this.props.empresa.limit_compra_user}
              onChange={value => {
                this.props.empresa.limit_compra_user = value;
                this.setState({ item: this.state.item });
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {"R$"}
                  </InputAdornment>
                )
              }}
              type={"number"}
              label={"Limit de compra por usuário (Default)"}
            />
          </View>
          <View style={styleGlobal.Grid} item xs={12} md={4}>
            <TextInput
              value={this.props.empresa.limit_compra_ingresso_evento}
              onChange={value => {
                this.props.empresa.limit_compra_ingresso_evento = value;
                this.setState({ item: this.state.item });
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {"Quantidade"}
                  </InputAdornment>
                )
              }}
              type={"number"}
              mask={"9999999999999999"}
              label={"Limit compra ingressos por usuário (Default)"}
            />
          </View>
          <View style={styleGlobal.Grid} item xs={12}>
            <TextInput
              value={this.props.empresa.dominio}
              onChange={value => {
                this.props.empresa.dominio = value;
                this.setState({ item: this.state.item });
              }}
              keyboardType={"default"}
              label={"Dominio site"}
            />
          </View>
          <View style={styleGlobal.Grid} item xs={12} sm={6}>
            <TextInput
              value={this.props.empresa.tema_light}
              onChange={value => {
                this.props.empresa.tema_light = value;
                this.setState({ item: this.state.item });
              }}
              keyboardType={"default"}
              label={"Tema claro cor principal"}
            />
          </View>
          <View style={styleGlobal.Grid} item xs={12} sm={6}>
            <TextInput
              value={this.props.empresa.tema_dark}
              onChange={value => {
                this.props.empresa.tema_dark = value;
                this.setState({ item: this.state.item });
              }}
              keyboardType={"default"}
              label={"Tema escuro cor principal"}
            />
          </View>
          <View style={styleGlobal.Grid} item xs={12} sm={6}>
            <TextInput
              value={this.props.empresa.android}
              onChange={value => {
                this.props.empresa.android = value;
                this.setState({ item: this.state.item });
              }}
              keyboardType={"default"}
              label={"Link loja android"}
            />
          </View>
          <View style={styleGlobal.Grid} item xs={12} sm={6}>
            <TextInput
              value={this.props.empresa.ios}
              onChange={value => {
                this.props.empresa.ios = value;
                this.setState({ item: this.state.item });
              }}
              keyboardType={"default"}
              label={"Link loja ios"}
            />
          </View>
        </View>
      </View>
    );
  }
}


var styles = StyleSheet.create({
  viewcategorias: {
    flexWrap: "wrap",
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 5,
    borderColor: "gray",
    padding: 10
  }
});
