import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import TextInput from "react-1app/lib/TextInput";
import Grid from "react-1app/lib/View";
import { OpenSnack } from "react-1app/lib/SnackBar";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import * as WorkerLogin from "../../worker/login";
import * as Actions from "../../redux/actions";

class MiniDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { user: { email: "", senha: "" }, load: false };
  }

  componentDidMount() {
    if (this.props.screenProps.store.getState().user)
      this.props.navigation.navigate("/");
  }

  logar() {
    this.setState({ load: true });
    WorkerLogin.login(this.state.user, (user = {}, error) => {
      if (error) {
        OpenSnack({ message: error.msg || "Erro ao logar", type: "error" });
        return this.setState({ load: false });
      }
      this.props.screenProps.store.dispatch(Actions.logar(user));
      setTimeout( ()=> {
        this.props.navigation.navigate("/");
      }, 10);
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid id='main' container component="main" className={classes.root}>
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Login no painel
            </Typography>
            <form className={classes.form} noValidate>
              <TextInput
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Login"
                name="email"
                autoComplete="email"
                autoFocus
                value={this.state.user.email}
                onChange={value => {
                  this.setState({ user: { ...this.state.user, email: value } });
                }}
              />
              <TextInput
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
                value={this.state.user.senha}
                onChange={value => {
                  this.setState({ user: { ...this.state.user, senha: value } });
                }}
              />

              <TouchableOpacity
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                load={this.state.load}
                onPress={() => {
                  this.logar();
                }}
              >
                Login
              </TouchableOpacity>

            </form>
          </div>
        </Grid>
      </Grid>
    );
  }
}

const useStyles = theme => ({
  root: {
    height: "100vh",
    // height:'-webkit-fill-available',
    flexGrow: 1,
    overflow: "auto",
  },
  image: {
    backgroundImage:
      "url(https://abrilexame.files.wordpress.com/2017/07/festa-e1520603959879.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    margin: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing.unit * 1,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit * 1
  },
  submit: {
    margin: "auto",
    marginTop: 15
  }
});

export default withStyles(useStyles, { withTheme: true })(MiniDrawer);
