import React, { Component } from "react";

import View from "react-1app/lib/View";
import StyleSheet from "react-1app/lib/StyleSheet";
import Modal from "react-1app/lib/Modal";
import TextInput from "react-1app/lib/TextInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import { OpenSnack } from "react-1app/lib/SnackBar";
import { Util } from "../../infra";
import * as Entrada from "../../worker/entrada";
import Switch from "react-1app/lib/Switch";

export default class AddEntrada extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
      saida: this.props.entrada.quantidade < 0
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  isErro(dados) {
    let erro = "";
    if (!dados.descricao) erro = "Adicione um descrição";
    if (!dados.quantidade) erro = "Adicione um quantidade";
    if (!dados.valor) erro = "Adicione um valor";
    return erro;
  }

  salvar() {
    let erro = this.isErro(this.props.entrada);
    if (erro) return OpenSnack({ message: erro, type: "error" });
    let quantidade = this.props.entrada.quantidade;
    if (this.state.saida && quantidade > 0) {
      quantidade = quantidade * -1;
    }
    if (this.props.entrada.id) {
      Entrada.atualizar({...this.props.entrada,quantidade}, (item, error) => {
        if (error) {
          OpenSnack({ message: error.msg || "Erro ao salvar!", type: "error" });
        } else {
          this.setState({ entrada: item, load: false,saida:false });
          this.props.atualizar();
          this.props.closeModal();
          OpenSnack({
            message: "Atualizado com sucesso!",
            type: "success"
          });
        }
      });
    } else {
      Entrada.salvar({...this.props.entrada,quantidade}, (item, error) => {
        if (error) {
          OpenSnack({ message: error.msg || "Erro ao salvar!", type: "error" });
        } else {
          this.setState({ entrada: item, load: false,saida:false });
          this.props.atualizar();
          this.props.closeModal();
          OpenSnack({ message: "Salvo com sucesso!", type: "success" });
        }
      });
    }
  }

  remover() {
    Entrada.remover(this.props.entrada.id, (item, error) => {
      if (error) {
        OpenSnack({ message: error.msg || "Erro ao deletar!", type: "error" });
      } else {
        this.setState({ item, load: false });
        this.props.atualizar();
        this.props.closeModal();
        OpenSnack({ message: "Deletado com sucesso!", type: "success" });
      }
    });
  }

  render() {
    return (
      <Modal
        style={styles.modal}
        onRequestClose={() => {
          this.props.closeModal();
        }}
        visible={this.props.open}
        maxWidth={"sm"}
        bts={[
          {
            text: "DELETAR",
            action: () => {
              if (this.state.ingresso.id) {
                this.deletar();
              } else {
                this.props.closeModal();
              }
            }
          },
          {
            text: "SALVAR",
            action: () => {
              this.salvar();
            }
          }
        ]}
        screenProps={this.props.screenProps}
        navigation={this.props.navigation}
        activity={this}
      >
        <View style={styles.view6}>
          <TextInput
            style={styles.textinput3}
            value={this.props.entrada.quantidade}
            onChange={value => {
              this.props.entrada.quantidade = Util.maskFormate(value, "doub");
              this.setState({ item: this.state.item });
            }}
            keyboardType={"numeric"}
            label={"Quantidade"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <View style={{ width: 200 }}>
                    <Switch
                      value={this.state.saida}
                      onChange={value => {
                        this.state.saida = value;
                        this.setState({ item: this.state.item });
                      }}
                      label={"Saida do estoque"}
                    />
                  </View>
                </InputAdornment>
              )
            }}
          />
        </View>
        <View style={styles.view6}>
          <TextInput
            style={styles.textinput3}
            value={this.props.entrada.valor}
            onChange={value => {
              this.props.entrada.valor = Util.maskFormate(value, "money");
              this.setState({ item: this.state.item });
            }}
            keyboardType={"numeric"}
            label={"Valor"}
          />
        </View>
        <View style={styles.view6}>
          <TextInput
            style={styles.textinput3}
            value={this.props.entrada.descricao}
            onChange={value => {
              this.props.entrada.descricao = value;
              this.setState({ item: this.state.item });
            }}
            keyboardType={"default"}
            label={"Descrição"}
          />
        </View>
      </Modal>
    );
  }
}

var styles = StyleSheet.create({
  tela: {
    alignSelf: "stretch",
    marginTop: 20,
    backgroundColor: "rgba(255,255,255,1)",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    flex: 1
  },
  view: {
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    borderRadius: 5,
    backgroundColor: "rgba(238,238,238,1)",
    padding: 10
  },
  view1: {
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    padding: 5
  },
  button2: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    width: 40
  },
  icon2: {
    color: "rgba(150,150,145,1)",
    fontSize: 20
  },
  button1: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    width: 40
  },
  icon: {
    color: "rgba(150,150,145,1)",
    fontSize: 20
  },
  view8b: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    flexWrap: "nowrap"
  },
  bottom: {
    alignSelf: "auto",
    justifyContent: "center",
    alignItems: "center",
    height: 30,
    flexDirection: "column",
    flexWrap: "nowrap",
    width: 30
  },
  icon5: {
    color: "rgba(150,150,145,1)",
    fontSize: 25
  },
  icon7: {
    color: "rgba(69,191,85,1)",
    fontSize: 20
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal"
  },
  campanha: {
    alignSelf: "stretch",
    flex: 1,
    backgroundColor: "rgba(255,255,255,1)"
  },
  cell: {
    flexDirection: "row",
    alignSelf: "stretch",
    flex: 1,
    padding: 5,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    backgroundColor: "rgba(255,255,255,1)"
  },
  view6: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: 3,
    backgroundColor: null,
    marginRight: 8,
    borderRadius: 5,
    minWidth: 50,
    flex: 1
  },
  switch: {
    alignSelf: "auto"
  },
  view5: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "rgba(255,255,255,1)",
    padding: 3,
    borderRadius: 5
  },
  text2: {
    textAlign: "left",
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    fontWeight: "normal"
  },
  text3: {
    textAlign: "left",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    fontWeight: "normal",
    fontSize: 12
  },
  view9: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    padding: 5
  },
  text6: {
    textAlign: "left",
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    fontWeight: "bold"
  }
});
