import Cloud from "../infra/Cloud";



export function salvar(bebida, callback){
  Cloud.post('empresas/config/pagamentos', bebida, (res,error) => {
    if(callback) callback(res,error);
  })
}

export function atualizar(bebida, callback){
  Cloud.put('empresas/config/pagamentos', bebida, (res,error) => {
    if(callback) callback(res,error);
  })
}

export function alterarPorcentagem(bebida, callback){
  Cloud.put('empresas/comissoes', bebida, (res,error) => {
    if(callback) callback(res,error);
  })
}

export function listar(bebida, callback){
  Cloud.get('empresas/config/pagamentos', bebida, (res,error) => {
    if(callback) callback(res,error);
  })
}
