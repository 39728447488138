import React, { Component } from "react";
import View from "react-1app/lib/View";
import { OpenModal } from "react-1app/lib/PgModal";
import Text from "react-1app/lib/Text";
import StyleSheet from "react-1app/lib/StyleSheet";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Icon from "react-1app/lib/Icon";
import Avatar from "@material-ui/core/Avatar";
import Hidden from "@material-ui/core/Hidden";
import * as actions from "../../redux/actions";
import styleGlobal from "../../styleGlobal";
import MenuEmpresa from "./MenuEmpresa";
import ModalNotif from "../notificacao";
import { Firebase } from "../../infra";
import * as Equipe from "../../worker/equipe_new";
import {Util} from '../../infra';
import NavLink from "react-router-dom/NavLink";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      user: this.props.screenProps.store.getState().user,
      regras: this.props.screenProps.store.getState().regras,
      install: false
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var { user, regras } = this.props.screenProps.store.getState();
      if (user != this.state.user || regras != this.state.regras)
        this.setState({ user, regras });
    });
    if (window.onInstaller)
      this.pwa = window.onInstaller(install => {
        this.setState({ install });
      });
      this.initN();
  }

  initN(){
    Firebase.init(()=>{
      Firebase.getToken(token=>{
        Equipe.tokenUsuario({token,os:'dash'},console.log)
      })
    })
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
    if (this.pwa) this.pwa();
  }

  sair() {
    this.props.screenProps.store.dispatch(actions.logout());
    this.props.navigation.navigate("/login");
  }

  abrirNotificacao() {
    this.props.navigation.navigate("/agenda/notificacao");

    // OpenModal(ModalNotif, {});
  }

  render() {
    return (
      <View style={styles.content}>
        <TouchableOpacity
          style={styleGlobal.buttonIcon}
          tooltip={"Início"}
          color="inherit"
          onPress={() => {
            this.props.navigation.navigate("/");
          }}
        >
          <Icon name={"home"} />
          <Hidden smDown key={"key_maior"}>
            <Text color="inherit" style={styles.text}>
              {"Dashboard"}
            </Text>
          </Hidden>
        </TouchableOpacity>
        <View style={styles.view4} />
          {this.state.user && this.state.user.adm ? (
            <TouchableOpacity
              icon
              color="inherit"
              onPress={()=>{
                this.props.navigation.navigate("/relatorio/venda/adm");
              }}
            >
              <Icon fromFontFamily={"Material Icons"} name={"timeline"} />
            </TouchableOpacity>
          ) : null}
          {this.state.user && this.state.user.adm ? (
            <TouchableOpacity
              icon
              color="inherit"
              onPress={()=>{
                this.props.navigation.navigate("/relatorio/adm");
              }}
            >
              <Icon fromFontFamily={"Material Icons"} name={"dashboard"} />
            </TouchableOpacity>
          ) : null}
        <MenuEmpresa {...this.props}></MenuEmpresa>
        {this.state.install ? (
          <TouchableOpacity
            style={{ ...styleGlobal.buttonIcon, margin: 5 }}
            variant="outlined"
            onPress={() => {
              this.state.install();
            }}
            color="inherit"
          >
            <Avatar style={{ color: "white", background: "rgba(0,0,0,0)" }}>
              <Icon
                fromFontFamily={"Material Design Icons"}
                name={"cellphone-arrow-down"}
                color="inherit"
              />
            </Avatar>
            <Hidden smDown key={"key_maior"}>
              {"Instalar app"}
            </Hidden>
          </TouchableOpacity>
        ) : null}
        {this.state.regras && this.state.regras.admin||this.state.user&&this.state.user.adm ? (
          <TouchableOpacity
            icon
            onPress={() => {
              this.abrirNotificacao();
            }}
            color="inherit"
          >
            <Icon fromFontFamily={"Material Design Icons"} name={"bell-ring"} />
          </TouchableOpacity>
        ) : null}
        <TouchableOpacity
          icon
          style={styleGlobal.buttonIcon}
          onPress={() => {
            Util.web("https://ajuda.4party.app/kb/771226");
          }}
          tooltip={"Ajuda"}
          color="inherit"
        >
          <Icon icon={"help"} />
        </TouchableOpacity>
        <TouchableOpacity
          icon
          style={styleGlobal.buttonIcon}
          onPress={() => {
            this.sair();
          }}
          tooltip={"Sair"}
          color="inherit"
        >
          <Icon icon={"exit_to_app"} />
        </TouchableOpacity>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: null,
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    flex: 1
  },
  text: {
    textAlign: "left",
    fontWeight: "700",
    fontSize: 17,
    marginLeft: 20
  },
  view4: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-end",
    flexDirection: "row"
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 50,
    paddingBottom: 2
  }
});
