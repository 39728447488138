import React, { Component } from "react";
import ListSubheader from "@material-ui/core/ListSubheader";

import {
  TouchableOpacity,
  View,
  TextInput,
  StyleSheet,
  Alert,
  OpenSnack,
  DatePicker,
  Switch
} from "react-1app";
import * as Ingresso from "../../worker/ingresso";
import styleGlobal from "../../styleGlobal";
import { Util } from "../../infra";
import { teste_salvar_ingresso } from "../../worker/teste_salvar";
import Dialog from "@material-ui/core/Dialog";
import EditarIngUsuario from "./EditarIngUsuarios";
import withStyles from "@material-ui/core/styles/withStyles";

class EditarIngresso extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ingresso: { ...this.props.ingresso }
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  salvar() {
    let teste = teste_salvar_ingresso(this.state.ingresso);
    if (teste) {
      OpenSnack({ message: teste, type: "error" });
      return;
    }
    if (this.state.ingresso.id) {
      Ingresso.atualizar(this.state.ingresso, (ingresso, error) => {
        if (error) {
          OpenSnack({
            message: "Erro ao editar!",
            type: "error"
          });
        } else {
          this.setState({ ingresso, load: false });
          this.props.atualizar();
          OpenSnack({
            message: "Ingresso atualizado com sucesso!",
            type: "success"
          });
          this.props.closeModal();
        }
      });
    } else {
      Ingresso.salvar(this.state.ingresso, (ingresso, error) => {
        if (error) {
          OpenSnack({
            message: error.msg,
            type: "error"
          });
        } else {
          this.setState({ ingresso, load: false });
          this.props.atualizar();
          OpenSnack({
            message: "Ingresso salvo com sucesso!",
            type: "success"
          });
          this.props.closeModal();
        }
      });
    }
  }

  deletar() {
    Alert.alert("Atenção", "Deseja excluir este item?", [
      { text: "Não", onPress: null },
      {
        text: "Sim",
        onPress: () => {
          this.remover(this.state.ingresso.id);
        }
      }
    ]);
  }

  remover(id) {
    Ingresso.remover(id, (result, error) => {
      if (error) {
        OpenSnack({ message: error.msg, type: "error" });
      } else {
        this.props.atualizar();
        this.props.closeModal();
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.ingresso != nextProps.ingresso) {
      this.setState({ ingresso: nextProps.ingresso });
    }
  }

  isDisabled() {
    return this.props.notEdit;
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View type={"Paper"} style={styles.paper}>
            <View style={styleGlobal.Grid} container spacing={16}>
              <ListSubheader style={{ flex: 1 }} component="div">
                {"Ingresso"}
              </ListSubheader>

              <View style={styleGlobal.Grid} xs={12} item>
                <TextInput
                  style={styles.textinput}
                  value={this.state.ingresso.nome}
                  onChange={value => {
                    this.state.ingresso.nome = value;
                    this.setState({ ingresso: this.state.ingresso });
                  }}
                  keyboardType={"default"}
                  label={"Nome"}
                  disabled={this.isDisabled()}
                />
              </View>
              <View style={styleGlobal.Grid} xs={12} item>
                <TextInput
                  style={styles.textinput}
                  value={this.state.ingresso.descricao}
                  onChange={value => {
                    this.state.ingresso.descricao = value;
                    this.setState({ ingresso: this.state.ingresso });
                  }}
                  keyboardType={"default"}
                  multiline={true}
                  label={"Descrição"}
                  disabled={this.isDisabled()}
                />
              </View>
              <View style={styleGlobal.Grid} xs={6} item>
                <TextInput
                  style={styles.textinput2}
                  value={this.state.ingresso.valor}
                  onChange={value => {
                    this.state.ingresso.valor = Util.maskFormate(
                      value,
                      "money"
                    );
                    this.setState({ ingresso: this.state.ingresso });
                  }}
                  keyboardType={"default"}
                  disabled={this.state.ingresso.free}
                  label={"Valor de Venda"}
                  disabled={this.isDisabled()}
                />
              </View>
              <View style={styleGlobal.Grid} xs={6} item>
                <TextInput
                  style={styles.textinput3}
                  value={this.state.ingresso.quantidade}
                  onChange={value => {
                    this.state.ingresso.quantidade = value;
                    this.setState({ ingresso: this.state.ingresso });
                  }}
                  keyboardType={"default"}
                  label={"Quantidade"}
                  disabled={this.isDisabled()}
                />
              </View>
              <View style={styleGlobal.Grid} xs={6} item>
                <TextInput
                  style={styles.textinput3}
                  value={this.state.ingresso.quant}
                  onChange={value => {
                    this.state.ingresso.quant = value;
                    this.setState({ ingresso: this.state.ingresso });
                  }}
                  keyboardType={"default"}
                  label={"Quantidade de pessoas por ingresso"}
                  disabled={this.isDisabled()}
                />
              </View>
              <View style={styleGlobal.Grid} xs={6} item>
                <DatePicker
                  style={styles.datepicker}
                  value={this.state.ingresso.data_hora_validade}
                  onChange={value => {
                    this.state.ingresso.data_hora_validade = value;
                    this.setState({ ingresso: this.state.ingresso });
                  }}
                  datePickerModeAndroid={"calendar"}
                  label={"Data e Hora validade"}
                  disabled={this.isDisabled()}
                />
              </View>
              <View style={styleGlobal.Grid} xs={6} item>
                <DatePicker
                  style={styles.datepicker}
                  value={this.state.ingresso.data_hora_inicio}
                  onChange={value => {
                    this.state.ingresso.data_hora_inicio = value;
                    this.setState({ ingresso: this.state.ingresso });
                  }}
                  datePickerModeAndroid={"calendar"}
                  label={"Data e Hora Inicio de Vendas"}
                  disabled={this.isDisabled()}
                />
              </View>
              <View style={styleGlobal.Grid} xs={6} item>
                <DatePicker
                  style={styles.datepicker}
                  value={this.state.ingresso.data_hora_fim}
                  onChange={value => {
                    this.state.ingresso.data_hora_fim = value;
                    this.setState({ ingresso: this.state.ingresso });
                  }}
                  datePickerModeAndroid={"calendar"}
                  label={"Data e Hora Limite de Vendas"}
                  disabled={this.isDisabled()}
                />
              </View>
              <Switch
                style={styles.switch}
                value={this.state.ingresso.free}
                onChange={value => {
                  this.state.ingresso.free = value;
                  if (value) {
                    this.state.ingresso.valor = 0;
                  }
                  this.setState({ ingresso: this.state.ingresso });
                }}
                label={"Ingresso Free"}
                disabled={this.isDisabled()}
              />
              <Switch
                style={styles.switch}
                value={this.state.ingresso.ativo}
                onChange={value => {
                  this.state.ingresso.ativo = value;
                  this.setState({ ingresso: this.state.ingresso });
                }}
                label={"Ingresso Ativo"}
                disabled={this.isDisabled()}
              />
              <Switch
                style={styles.switch}
                value={this.state.ingresso.pdv}
                onChange={value => {
                  this.state.ingresso.pdv = value;
                  this.setState({ ingresso: this.state.ingresso });
                }}
                label={"PDV"}
                disabled={this.isDisabled()}
              />
              <Switch
                style={styles.switch}
                value={this.state.ingresso.app}
                onChange={value => {
                  this.state.ingresso.app = value;
                  this.setState({ ingresso: this.state.ingresso });
                }}
                label={"APP"}
                disabled={this.isDisabled()}
              />
              <Switch
                style={styles.switch}
                value={this.state.ingresso.promoter}
                onChange={value => {
                  this.state.ingresso.promoter = value;
                  this.setState({ ingresso: this.state.ingresso });
                }}
                label={"Promoter"}
                disabled={this.isDisabled()}
              />
              <TouchableOpacity
                onPress={() => this.setState({open:true})}
                children={"Usuario vinculados"}
                variant="contained"
              ></TouchableOpacity>
              <View style={styles.action}>
                <TouchableOpacity
                  onPress={() => this.salvar()}
                  children={"Salvar"}
                  variant="outlined"
                ></TouchableOpacity>
                <TouchableOpacity
                  onPress={() => this.deletar()}
                  disabled={!this.state.ingresso.id}
                  style={styles.delete}
                  children={"Apagar"}
                  color={"secondary"}
                  variant="outlined"
                ></TouchableOpacity>
                <TouchableOpacity
                  onPress={() => this.props.closeModal()}
                  style={styles.delete}
                  children={"Fechar"}
                  color={"default"}
                  variant="outlined"
                ></TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
        <Dialog
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          scroll={"body"}
          fullScreen
          aria-labelledby="scroll-dialog-title"
          PaperProps={
            this.props.classes && {
              classes: { root: this.props.classes.root }
            }
          }
        >
          <EditarIngUsuario
            {...this.props}
            ingresso={this.state.ingresso}
            closeModal={() => {
              this.setState({ open: false });
            }}
          />
        </Dialog>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  action: {
    padding: 10,
    justifyContent: "center"
  },
  paper: {
    padding: 20,
    marginTop: 50
  },
  content: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  text: {
    textAlign: "center",
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    fontWeight: "normal"
  },
  view4: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  imageupload: {
    height: 250,
    width: 250,
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    borderRadius: 5,
    backgroundColor: "rgba(242,242,242,1)"
  },
  textinput: {
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    textAlign: "left",
    fontWeight: "normal",
    marginTop: 10
  },
  textinput2: {
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    textAlign: "left",
    fontWeight: "normal",
    marginTop: 10
  },
  textinput3: {
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    textAlign: "left",
    fontWeight: "normal",
    marginTop: 10
  },
  view3: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    marginTop: 10
  },
  datepicker: {
    color: "rgba(0,0,0,1)",
    alignSelf: "auto",
    marginTop: 15
  },
  textinput31: {
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    textAlign: "left",
    fontWeight: "normal",
    marginTop: 10
  },
  view5: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 50,
    marginTop: 10
  },
  switch: {
    alignSelf: "auto"
  },
  button: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column"
  },
  text2: {
    textAlign: "center",
    color: "#fff",
    alignSelf: "stretch",
    fontWeight: "normal"
  },
  delete: {
    marginLeft: 20
  }
});
const styles2 = theme => ({
  root: {
    backgroundColor: "rgba(0,0,0,0.2)"
  }
});
export default withStyles(styles2)(EditarIngresso)
