export function teste_salvar_bebida({nome, valor, quantidade, descricao}){
    let erro = ""
    if(!descricao) erro = "Informe a descrição da Bebida"
    if(!quantidade) erro = "Informe a quantidade da Bebida"
    if(!valor) erro = "Informe o valor da Bebida"
    if(!nome) erro = "Informe o nome da Bebida"
    return erro
}

export function teste_salvar_evento({imagem, nome, descricao, data_hora, data_hora_fim, limite_casa }){
    let erro = ""
    if(!limite_casa) erro = "Informe o Limite da Casa"
    if(!data_hora_fim) erro = "Informe a Data de Termino da exposição do Evento"
    if(!data_hora) erro = "Informe a Data de Inicio do Evento"
    if(!descricao) erro = "Informe a descrição do Evento"
    if(!nome) erro = "Informe o nome da Evento"
    if(!imagem) erro = "Insira a imagem do Evento"
    return erro
}

export function teste_salvar_ingresso({nome, valor, quantidade, data_hora_inicio, data_hora_fim, free }){
    let erro = ""
    if(!data_hora_fim) erro = "Informe a Data e Hora de Termino da Venda do Ingresso"
    if(!data_hora_inicio) erro = "Informe a Data e Hora de Inicio da Venda do Ingresso"
    if(!quantidade) erro = "Informe a quantidade do Ingresso"
    if(!free && !valor) erro = "Informe o valor do Ingresso"
    if(!nome) erro = "Informe o nome do Ingresso"
    return erro
}

export function teste_salvar_equipe({email, nome, senha, admin, bilheteiro, porteiro}){
    let erro = ""
    if(!admin && !bilheteiro && !porteiro) erro = "Informe a função do Cadastro"
    if(!senha) erro = "Informe a senha do Cadastro"
    if(!nome) erro = "Informe o nome do Cadastro"
    if(!email) erro = "Informe o email do Cadastro"
    return erro
}
