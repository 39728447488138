import React, { useState } from "react";
import Board from "react-trello";
import {
  View,
  OpenSnack,
  TouchableOpacity,
  StyleSheet,
  TextInput,
  Text,
  Icon
} from "react-1app";
import { OpenModal } from "react-1app/lib/PgModal";
import styleGlobal from "../../styleGlobal";
import moment from "moment";
import * as Trello from "../../worker/trello";
import * as TrelloCard from "../../worker/trello_card";
import { Cloud } from "../../infra";
import LinearProgress from "@material-ui/core/LinearProgress";
import EditarCard from "./EditarCard";

let SocketIO = require("socket.io-client");

export default class App extends React.Component {
  state = {
    lanes: [],
    user: this.props.screenProps.store.getState().user || {},
    empresa: this.props.screenProps.store.getState().empresa,
    load: true
  };
  socket = SocketIO("https://party-socket.1app.com.br", {});

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.empresa != this.state.empresa) {
        this.setState({ empresa: store.empresa }, () => {
          this.reconecta();
          this.buscarDados();
        });
      }
    });
    this.onSocket();
    this.buscarDados();
    this.init();
  }

  onSocket() {
    this.socket.on("disconnect", reason => {
      console.log("disconnect");
      this.reconectar();
    });
    this.socket.on("connect", reason => {
      console.log("connect");
      this.reconecta();
      clearInterval(this.loop);
    });
  }

  loop = null;
  reconectar() {
    clearInterval(this.loop);
    this.loop = setInterval(() => {
      console.log("reconectar");
      if (this.socket.connected === true) clearInterval(this.loop);
      else if (!this.socket.connecting) this.socket.connect();
    }, 15000);
  }

  reconecta() {
    let { user, empresa } = this.state;
    console.log(" on_reconectar");
    this.socket.emit("reconectar", {
      id: user.id,
      id_empresa: empresa.id,
      tipo: "dash"
    });
  }

  init() {
    let { user, empresa } = this.state;
    this.socket.on("on_trello_coluna", lane => {
      let { lanes } = this.state;
      if (lane.status == 3) return;

      let oldLane = lanes.find(e => e.id == lane.id);
      if (oldLane) {
        for (var variavel in lane) {
          oldLane[variavel] = lane[variavel];
        }
      } else {
        lanes.push(lane);
        console.log("push");
      }
      this.setState({ lanes });
      console.log(lane, oldLane, "on_trello_coluna");
    });
    this.socket.on("on_trello_card", card => {
      let { lanes } = this.state;
      if (card.status == 3) return;
      let lane = lanes.find(e => e.id == card.id_trello_coluna) || {};
      let oldCard = (lane.cards || []).find(e => e.id == card.id);
      console.log(card, oldCard, lane, "on_trello_card");
      if (oldCard) {
        for (var variavel in card) {
          oldCard[variavel] = card[variavel];
        }
      } else {
        lane.cards.push(card);
      }
      this.setState({ lanes });
    });
    this.socket.on("on_trello_orden", card => {
      console.log("on_trello_orden");
      this.buscarDados();
    });
  }

  buscarDados() {
    Trello.listar({}, (lanes = [], error) => {
      if (error || !lanes) return;
      this.setState({ lanes, load: false });
    });
  }

  atualizar = newdata => {
    Trello.atualizar(newdata, (data = { lanes: [] }, error) => {
      if(error||!data) OpenSnack({ message: "Erro ao atualidado", type: "error" });
      else OpenSnack({ message: 'Atualidado', type: "success" });
    });
  };
  salvar = newdata => {
    Trello.salvar(newdata, (data, error) => {
      if(error||!data) OpenSnack({ message: "Erro ao salvar", type: "error" });
      else OpenSnack({ message: 'Salvo', type: "success" });
    });
  };
  remover = newdata => {
    Trello.remover(newdata, (data = { lanes: [] }, error) => {
      // this.setState({ data: newdata });
    });
  };

  salvarcard = newdata => {
    TrelloCard.salvar(newdata, (data = { lanes: [] }, error) => {
      if(error||!data) OpenSnack({ message: "Erro ao salvar", type: "error" });
      else OpenSnack({ message: 'Salvo', type: "success" });
        });
  };
  atualizarcard = newdata => {
    TrelloCard.atualizar(newdata, (data = {}, error) => {
      if(error||!data) OpenSnack({ message: "Erro ao atualidado", type: "error" });
      else OpenSnack({ message: 'Atualidado', type: "success" });
    });
  };
  atualizarOrden = list => {
    Trello.atualizarOrden({ list }, (data = { lanes: [] }, error) => {
      // this.setState({ data: newdata });
    });
  };
  atualizarOrdenCard = list => {
    TrelloCard.atualizarOrden({ list }, (data = { lanes: [] }, error) => {
      // this.setState({ data: newdata });
    });
  };
  removercard = newdata => {
    TrelloCard.remover(newdata, (data = { lanes: [] }, error) => {
      // this.setState({ data: newdata });
    });
  };
  editarCard = (id_card, t, id_lane) => {
    let { lanes } = this.state;
    let item = ((lanes.find(a => a.id == id_lane) || {}).cards || []).find(
      e => e.id == id_card
    );

    console.log(item);
    if (!item) return;
    OpenModal(EditarCard, {
      card: item,
      atualizar: card => {
        this.atualizarcard(item);
      },
      remover: card => {
        this.removercard(item);
      }
    });
  };

  onCardMoveAcrossLanes = (fromLaneId, toLaneId, cardId, index) => {
    setTimeout(() => {
      let { lanes } = this.state;
      let l = lanes.filter(a => a.id == fromLaneId || a.id == toLaneId);
      let c = [
        ...l[0].cards.map((a, orden) => ({
          id: a.id,
          orden,
          id_trello_coluna: a.laneId
        })),
        ...((l[1] || {}).cards || []).map((a, orden) => ({
          id: a.id,
          orden,
          id_trello_coluna: a.laneId
        }))
      ];
      this.atualizarOrdenCard(c);
    }, 15);
  };
  handleLaneDragEnd = (oldO, newO, lane) => {
    // console.log(newO, lane);
    setTimeout(() => {
      let { lanes } = this.state;
      let l = lanes.map((e, orden) => ({ id: e.id, orden }));
      this.atualizarOrden(l);
    }, 10);
  };
  onCardDelete = (cardId, laneId) => {
    this.removercard({ id: cardId });
    this.onCardMoveAcrossLanes(laneId, laneId);
  };
  onCardAdd = (card, laneId) => {
    this.salvarcard({ ...card, id_trello_coluna: laneId });
  };
  onLaneUpdate = (laneId, data) => {
    this.atualizar({ ...data, id: laneId });
  };
  onLaneDelete = laneId => {
    this.remover({ id: laneId });
    this.handleLaneDragEnd();
    console.log(laneId);
  };
  onLaneAdd = (params, ...ee) => {
    setTimeout(() => {
      let { lanes } = this.state;
      let orden = lanes.length - 1;
      let lane = lanes[orden];
      this.salvar({ ...lane, orden });
    }, 15);
  };

  render() {
    if (this.state.load) return <LinearProgress />;
    return (
      <Board
        data={{ lanes: this.state.lanes }}
        style={{ backgroundColor: "rgba(0,0,0,0)"}}
        draggable
        id="EditableBoard1"
        onDataChange={({ lanes }) => {
          console.log(lanes);
          this.setState({ lanes });
        }}
        onCardDelete={this.onCardDelete}
        onCardAdd={this.onCardAdd}
        onCardClick={this.editarCard}
        onCardMoveAcrossLanes={this.onCardMoveAcrossLanes}
        onLaneUpdate={this.onLaneUpdate}
        onLaneDelete={this.onLaneDelete}
        onLaneAdd={this.onLaneAdd}
        handleLaneDragEnd={this.handleLaneDragEnd}
        editable
        canAddLanes
        editLaneTitle
        hideCardDeleteIcon
        t={t => traducao[t] || console.log(t)}
      />
    );
  }
}

let traducao = {
  "Add another lane": "Adicionar coluna",
  "placeholder.title": "Nome",
  "button.Add lane": "Adicionar",
  "button.Cancel": "Cancelar",
  "button.Add card": "Adicionar",
  "placeholder.description": "Descrição",
  "Click to add card": "Adicionar card",
  "Delete lane": "Deletar",
  "Lane actions": "Ações"
};

var styles = StyleSheet.create({
  tela: {
    padding: 20,
    marginTop: 50
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1,
    marginRight: "auto"
  },
  atc: {
    width: "100%"
  },
  atividade: {
    padding: 20,
    width: "100%"
  },
  action: {
    padding: 10,
    justifyContent: "center",
    alignItem: "center"
  },
  delete: {
    marginLeft: 20
  }
});
