import React, { Component } from "react";

import {
  View,
  TextInput,
  StyleSheet,
  Modal,
  Icon,
  TouchableOpacity
} from "react-1app";
import styleGlobal from "../../styleGlobal";
import InputAdornment from "@material-ui/core/InputAdornment";
import ModalEndereco from "./ModalEndereco";
// import ModalFacebook from "./ModalFacebook";
import { OpenModal } from "react-1app/lib/PgModal";

export default class Dados extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}
  openEndereco(){
    OpenModal(ModalEndereco, {
      ...this.props,
      endereco: this.props.empresa.endereco,
      decricao: "Adicione um endereço para a empresa",
      salvarendereco: endereco => {
        this.props.empresa.endereco = endereco;
        this.setState({
          legend: this.props.empresa,
          ModalEnderecoOpen: false
        });
      }
    });
  }
  render() {
    return (
      <View style={styleGlobal.Grid} container spacing={16}>
        <View style={styleGlobal.Grid} item xs={12}>
          <TextInput
            style={styles.textinput31}
            value={this.props.empresa.nome}
            onChange={value => {
              this.props.empresa.nome = value;
              this.setState({ item: this.state.item });
            }}
            keyboardType={"default"}
            label={"Nome"}
          />
        </View>
        <View style={styleGlobal.Grid} item xs={12} sm={6}>
          <TextInput
            style={styles.textinput31}
            value={this.props.empresa.cnpj}
            onChange={value => {
              this.props.empresa.cnpj = value;
              this.setState({ item: this.state.item });
            }}
            keyboardType={"default"}
            label={"CNPJ"}
            disabled={true}
            mask={"99.999.999/9999-99"}
          />
        </View>
        <View style={styleGlobal.Grid} item xs={12} sm={6}>
          <TextInput
            style={styles.textinput31}
            value={this.props.empresa.telefone}
            onChange={value => {
              this.props.empresa.telefone = value;
              this.setState({ item: this.state.item });
            }}
            keyboardType={"default"}
            label={"Telefone"}
            mask={"(99) 99999-9999"}
          />
        </View>
        <View style={styleGlobal.Grid} item xs={12}>
          <TextInput
            style={styles.textinput31}
            value={this.props.empresa.descricao}
            onChange={value => {
              this.props.empresa.descricao = value;
              this.setState({ item: this.state.item });
            }}
            keyboardType={"default"}
            multiline={true}
            label={"Descrição"}
          />
        </View>
        <View style={styleGlobal.Grid} item xs={12} sm={6}>
          <TextInput
            style={styles.textinput31}
            value={this.props.empresa.site}
            onChange={value => {
              this.props.empresa.site = value;
              this.setState({ item: this.state.item });
            }}
            keyboardType={"default"}
            multiline={true}
            label={"Link Pagina Web"}
          />
        </View>
        <View style={styleGlobal.Grid} item xs={12} sm={6}>
          <TextInput
            style={styles.textinput31}
            value={this.props.empresa.facebook}
            onChange={value => {
              this.props.empresa.facebook = value;
              this.setState({ item: this.state.item });
            }}
            keyboardType={"default"}
            multiline={true}
            label={"Link Pagina Facebook"}
          />
        </View>
        <View style={styleGlobal.Grid} item xs={12} sm={6}>
          <TextInput
            style={styles.textinput31}
            value={this.props.empresa.instagram}
            onChange={value => {
              this.props.empresa.instagram = value;
              this.setState({ item: this.state.item });
            }}
            keyboardType={"default"}
            multiline={true}
            label={"Link Pagina Instagram"}
          />
        </View>
        <View style={styleGlobal.Grid} item xs={12} sm={6}>
          <TextInput
            style={styles.textinput31}
            value={this.props.empresa.twitter}
            onChange={value => {
              this.props.empresa.twitter = value;
              this.setState({ item: this.state.item });
            }}
            keyboardType={"default"}
            multiline={true}
            label={"Link Pagina Twitter"}
          />
        </View>
        <View style={styleGlobal.Grid} item xs={12}>
          <TextInput
            style={styles.textinput31}
            fullWidth
            margin="normal"
            value={
              this.props.empresa.endereco
                ? this.props.empresa.endereco.endereco
                : ""
            }
            keyboardType={"default"}
            label={"Endereco"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <TouchableOpacity
                    icon
                    onClick={() => {
                      this.openEndereco({ open: true });
                    }}
                  >
                    <Icon name={"border_color"} />
                  </TouchableOpacity>
                </InputAdornment>
              ),
              disabled: true
            }}
          />
        </View>
        <View style={styleGlobal.Grid} item xs={12} sm={6}>
          <TextInput
            style={styles.textinput31}
            value={this.props.empresa.validade_bebida}
            onChange={value => {
              this.props.empresa.validade_bebida = value;
              this.setState({ item: this.state.item });
            }}
            keyboardType={"default"}
            multiline={true}
            label={"Validate das bebidas"}
          />
        </View>
        <View style={styleGlobal.Grid} item xs={12} sm={6}>
          <TextInput
            style={styles.textinput31}
            value={this.props.empresa.chat}
            onChange={value => {
              this.props.empresa.chat = value;
              this.setState({ item: this.state.item });
            }}
            keyboardType={"default"}
            multiline={true}
            label={"Chat para contato"}
          />
        </View>
        <View style={styleGlobal.Grid} item xs={12} sm={6} />
        <Modal visible={this.state.open} maxWidth={"sm"}>
          <ModalEndereco
            {...this.props}
            endereco={this.props.empresa.endereco}
            decricao="Adicione um endereço para a empresa"
            closeModal={() => {
              this.setState({ open: false });
            }}
            salvarendereco={endereco => {
              this.props.empresa.endereco = endereco;
              this.setState({
                legend: this.props.empresa,
                ModalEnderecoOpen: false
              });
            }}
          />
        </Modal>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  textinput31: {
    alignSelf: "stretch",
    textAlign: "left",
    fontWeight: "normal",
    marginTop: 20
  }
});
