import React from "react";
import ListSubheader from "@material-ui/core/ListSubheader";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Icon from "react-1app/lib/Icon";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListView from "react-1app/lib/ListView";
import * as Pdv from "../../worker/pdv";
import * as Ingresso from "../../worker/ingresso";
import moment from "moment";
import { OpenSnack } from "react-1app/lib/SnackBar";
import { OpenDialogOption } from "react-1app/lib/DialogOptions";
import { OpenModal } from "react-1app/lib/PgModal";
import EditarIngresso from "./EditarIngresso";
import Text from "react-1app/lib/Text";
import { Util } from "../../infra";
import ModalVideoAjuda from "../ModalVideoAjuda";

export default class IngressoView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {},
      load: true,
      list: [],
      listaCaixa: [],
      loadAivar: true,
      loadDesativar: false
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.projeto != this.state.projeto)
        this.setState({ projeto: store.projeto }, () => {
          this.buscar();
          this.buscarDados();
        });
    });
    this.buscar();
    this.buscarDados();
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  buscar() {
    Ingresso.listar(null, this.props.evento.id, (dados = [], error) => {
      this.setState({
        list: dados,
        load: false
      });
    });
  }
  buscarDados() {
    var empresa = this.props.screenProps.store.getState().projeto;
    Pdv.listar({ id_empresa: empresa.id }, (dados = [], error) => {
      this.setState({ listaCaixa: dados, loadAivar: false });
    });
  }

  salvar() {
    if (this.state.item.id && this.state.item.id !== "new") {
      Ingresso.atualizar(this.state.item, (evento, error) => {
        this.onRes(evento, error, "atualizar");
      });
    } else {
      Ingresso.salvar(this.state.item, (evento, error) => {
        this.onRes(evento, error, "salvar");
      });
    }
  }

  onRes(evento, error, tag) {
    if (error) {
      OpenSnack({ message: "Erro ao " + tag + " produto!", type: "error" });
    } else {
      this.setState({ item: evento });
      this.buscar();
      OpenSnack({
        message: "Produto ao " + tag + " com sucesso!",
        type: "success"
      });
    }
  }

  remover() {
    if (window.confirm("Deseja remover este item?")) {
      Ingresso.remover(this.state.item.id, (evento, error) => {
        if (error) {
          OpenSnack({ message: "Erro ao salvar evento!", type: "error" });
        } else {
          this.setState({ item: {} });
          this.buscar();
          OpenSnack({ message: "Produto removido!", type: "success" });
        }
      });
    }
  }

  selectCaixa() {
    if (this.props.evento.id_caixa_padrao) return alert("Caixa esta ativo");
    OpenDialogOption({
      title: "Selecione o caixa para o evento",
      description: "",
      texto: "",
      action: [
        ...this.state.listaCaixa
          .filter(e => e.continuo || e.data_termino >= moment().toJSON())
          .map(item => ({
            label: item.nome,
            onPress: () => this.ativarApp(item.id),
            icon: "account_balance_wallet"
          })),
        {
          label: "Cancelar",
          onPress: () => console.log("Cancelar"),
          icon: "close",
          separa: "Não alterar"
        }
      ]
    });
  }

  desativarCaixaApp(callback) {
    this.setState({ loadDesativar: true });
    OpenSnack({ message: "Aguarde! Desativado caixa." });
    Pdv.desativarApp({ id_evento: this.props.evento.id }, (caixa, error) => {
      if (error)
        return OpenSnack({
          message: error.msg || "Erro ao ativar!",
          type: "error"
        });
      this.props.evento.id_caixa_padrao = null;
      this.props.evento.id_caixa = null;
      this.props.atualizar();
      OpenSnack({ message: "Desativado com sucesso!", type: "success" });
      if (callback) callback();
      this.setState({ loadDesativar: false });
    });
  }

  ativarApp(id_caixa_padrao) {
    this.setState({ loadAivar: true });
    OpenSnack({ message: "Aguarde! Ativando caixa." });
    Pdv.ativarAppIngresso(
      { id: id_caixa_padrao, id_evento: this.props.evento.id },
      (caixa, error) => {
        if (error)
          return OpenSnack({
            message: error.msg || "Erro ao ativar!",
            type: "error"
          });
        this.props.evento.id_caixa_padrao = id_caixa_padrao;
        this.props.evento.id_caixa = caixa.id;
        OpenSnack({ message: "Ativado com sucesso!", type: "success" });
        this.props.atualizar();
        this.setState({ loadAivar: false });
      }
    );
  }

  openEditar(
    item = {
      data_hora_inicio: moment().toJSON(),
      data_hora_fim: this.props.evento.data_hora,
      data_hora_validade: this.props.evento.data_hora_fim,
      quant: 1,
      id_evento: this.props.evento.id
    }
  ) {
    OpenModal(EditarIngresso, {
      ingresso: item,
      atualizar: () => this.buscar()
    });
  }

  getTags(item) {
    return [
      { label: "ATIVO", ativo: item.ativo },
      { label: "APP", ativo: item.app },
      { label: "PDV", ativo: item.pdv }
    ].map(a => (
      <View
        style={{
          ...styles.view91,
          backgroundColor: a.ativo ? "rgb(0, 194, 0)" : "rgb(200, 200, 200)"
        }}
      >
        <Text style={styles.text6}>{a.label}</Text>
      </View>
    ));
  }

  render() {
    return (
      <View container spacing={16}>
        <View xs={12} item>
          <View type={"Paper"}>
            <View>
            <ListSubheader component="div">
              {"Caixa de venda ingresso"}
            </ListSubheader>
            <TouchableOpacity
              children={"Ajuda"}
              style={styles.add}
              variant="default"
              onPress={() => {
                OpenModal(ModalVideoAjuda,{url:"https://www.youtube.com/embed/XYQBTvTG8gI"});
              }}
            />
          </View>
            <ListSubheader component="div">
              {"OBS: A venda de ingresso só começara com um caixa aberto"}
            </ListSubheader>
            <View style={{ width: "100%", padding: 20 }}>
              <TouchableOpacity
                variant="outlined"
                style={{ fontSize: 20, flex: 1, width: "100%" }}
                onPress={() => {
                  this.selectCaixa();
                }}
                load={this.state.loadAivar}
                children={
                  (this.state.listaCaixa[0] &&
                    (
                      this.state.listaCaixa.find(
                        caixa => caixa.id == this.props.evento.id_caixa_padrao
                      ) || {}
                    ).nome) ||
                  "Selecionar Caixa"
                }
              />
              {this.props.evento.id_caixa ? (
                <TouchableOpacity
                  variant="outlined"
                  color={"secondary"}
                  load={this.state.loadDesativar}
                  style={{ marginLeft: 20, fontSize: 20 }}
                  onPress={() => {
                    if (
                      window.confirm("Deseja desativar caixa dos ingresso?")
                    ) {
                      this.desativarCaixaApp();
                    }
                  }}
                  children={"Desativar Caixa"}
                />
              ) : null}
            </View>
          </View>
        </View>
        <View xs={12} item>
          <View type={"Paper"}>
            <ListView
              load={this.state.load}
              ListHeaderComponent={() => (
                <View style={styles.head}>
                  <ListSubheader component="div">{"Ingresso"}</ListSubheader>
                  <TouchableOpacity
                    children={"Novo"}
                    style={styles.add}
                    variant="outlined"
                    onPress={() => {
                      this.openEditar();
                    }}
                  />
                </View>
              )}
              renderItem={({ item, index }) => {
                return (
                  <ListItem
                    button
                    key={index}
                    style={{ width: "100%", flexWrap: "wrap" }}
                    selected={this.state.index === index}
                    onClick={event => this.openEditar(item)}
                  >
                    <Avatar src={item.img}>
                      <Icon name={"confirmation_number"} />
                    </Avatar>
                    <ListItemText
                      primary={item.nome}
                      secondary={
                        "Inicio da venda: " +
                        moment(item.data_hora_inicio).fromNow() +
                        (item.promoter ? " | PROMOTER" : "")
                      }
                    />
                    <View style={{ alignSelf: "auto", padding: 10 }}>
                      {this.getTags(item)}
                      <Text style={{ width: 100, justifyContent: "flex-end" }}>
                        {Util.parseMoney(item.valor)}
                      </Text>
                    </View>
                  </ListItem>
                );
              }}
              separator
              data={this.state.list}
            />
          </View>
        </View>
      </View>
    );
  }
}

const styles = {
  root: {
    width: 500
  },
  text6: { fontSize: 12 },
  icon: {
    margin: 15
  },
  cell: {
    width: "100%"
  },
  head: {
    height: 50,
    width: "100%",
    padding: 5
  },
  image: {
    maxWidth: 600,
    maxHeight: 600 / (16 / 9),
    height: "100vh",
    margin: "auto",
    borderRadius: 5,
    background: "rgba(0,0,0,0.1)"
  },
  gridEdit: {
    flex: 1,
    maxWidth: 800
  },
  view91: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: null,
    marginLeft: 5,
    marginRight: 5,
    borderRadius: 2,
    padding: 5
    // height:30
  },
  obs: {
    fontSize: 14,
    margin: 12,
    color: "rgba(150,150,150,1)"
  },
  view: {
    padding: 20,
    paddingTop: 0
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  add: {
    marginLeft: "auto"
  },
  delete: {
    marginLeft: 20
  }
};
