import { Cloud } from "../infra";

export function salvar(evento, user, callback) {
  Cloud.post("eventos", evento, (res, error) => {
    // console.log("RESPOSTA", res);
    if (callback) callback(res, error);
  });
}

export function criar(evento, callback) {
  Cloud.post("eventos/criar", evento, (res, error) => {
    // console.log("RESPOSTA", res);
    if (callback) callback(res, error);
  });
}

export function importar(data, callback) {
  // console.log(data)
  Cloud.post("eventos/importar/facebook", data, (res, error) => {
    if (callback) callback(res, error);
  });
}
export function atualizar(evento, user, callback) {
  Cloud.put("eventos/" + evento.id, evento, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function remover(id, callback) {
  Cloud.delete("eventos/" + id, {}, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function listar(parametros, callback) {
  Cloud.get("eventos", parametros, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function listarPassados({ limit, page, publico }, callback) {
  Cloud.get("eventos/passados", { limit, page, publico }, (res, error) => {
    if (callback) callback(res, error);
  });
}
