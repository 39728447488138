import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import { createStore } from "redux";
import reducers from "./redux/reducer";
import { Provider } from "react-redux";
import { pages } from "./index.pages.js";
import { Cloud, Firebase } from "./infra";
import File from "react-1app/lib/File";
import ImageUpload from "react-1app/lib/ImageUpload";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import createBrowserHistory from "history/createBrowserHistory";
import Load from "./views/Load";
import NotFound from "./views/NotFound";
import InitApp from "react-1app/lib/InitApp";
import { CloseModal } from "react-1app/lib/PgModal";
import Dash from "./views/dash";
import withStyles from "@material-ui/core/styles/withStyles";
import * as projetos from "./worker/projetos";
import * as login from "./worker/login";
import * as actions from "./redux/actions";

var customHistory = createBrowserHistory();
require("moment/locale/pt-br");
// var moment = require("moment");
// moment.locale("pt-br");
var store = createStore(reducers);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: store.getState().user,
      empresaConfig: store.getState().empresaConfig,
      palette: store.getState().palette,
      load: false,
      theme: store.getState().theme
    };
    ImageUpload.setHost(Cloud.getHost());
    File.setHost(Cloud.getHost());
    ImageUpload.setToken(Cloud.getToken());
    File.setToken(Cloud.getToken());
    customHistory.block((location, action) => {
      CloseModal(true);
    });
    console.log("INIT APP");
  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      var { user, theme, projeto } = store.getState();
      if (
        user !== this.state.user ||
        theme !== this.state.theme ||
        projeto !== this.state.projeto
      ) {
        if (user !== this.state.user) this.listProjetos();
        this.setState({ user, theme, projeto });
      }
    });
    this.listProjetos();
  }

  listProjetos() {
    projetos.listarAtivos((lista = [], error) => {
      if (!lista) lista = [];
      var projeto =
        lista.find(e => e.id === Number(login.loadEmpresa())) || lista[0];
      store.dispatch({ type: "PROJETOS", data: lista });
      this.setState({ lista: lista ? lista : [], load: false, projeto });
      this.setProjeto(projeto);
    });
  }

  setProjeto(projeto) {
    if (!projeto || projeto.token === Cloud.getTokenEmpresa()) return;
    Cloud.setTokenEmpresa(projeto.token);
    projetos.get(projeto.id, (projeto, error) => {
      store.dispatch(actions.setProjeto(projeto));
    });
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  rotas(privado) {
    let { user, regras } = store.getState();
    let rotas = pages.filter(
      e =>
        (user &&
          privado &&
          ((user.id &&
            e.permissao &&
            (regras[e.permissao] || e.permissao === "user")) ||
            (window.dev && e.permissao === "test") ||
            user.adm&&e.permissao !=="realatorioIngresso")) ||
        (!e.permissao && !privado)
    );
    if (privado && rotas[0] && rotas[0].path !== "/")
      rotas.push({ ...rotas[0], path: "/", menu: false });
    return rotas;
  }

  render() {
    return (
      <MuiThemeProvider theme={themes[this.state.theme]}>
        <Provider ref={"pro"} store={store}>
          <Router ref={v => (this.router = v)} history={customHistory}>
            <Switch>
              {this.rotas(false).map((rota, key) => (
                <PrivateRoute
                  exact
                  key={"key_" + key + "_rota"}
                  path={rota.path}
                  rotas={this.rotas()}
                  store={store}
                  aberto={!rota.permissao}
                  user={store.getState().user}
                  component={rota.component}
                  load={this.state.load}
                />
              ))}
              {!this.state.user || !this.state.user.id ? (
                <Redirect to="/login" />
              ) : null}
              <Route component={NotFound} exact path="/not-found" />
              {!Cloud.getTokenEmpresa() && <Load />}
              {this.rotas(true).map((rota, key) => (
                <PrivateRoute
                  exact
                  key={"key_" + key + "_rota"}
                  path={rota.path}
                  rotas={this.rotas(true)}
                  store={store}
                  aberto={!rota.permissao}
                  user={store.getState().user}
                  component={rota.component}
                  load={this.state.load}
                />
              ))}
              <Redirect to="/not-found" />
              :null
            </Switch>
            <InitApp
              {...this.props}
              screenProps={{ store }}
              OnOpen={() => {
                customHistory.push({
                  pathname: "",
                  search: "?editar=true",
                  state: { some: "state" }
                });
              }}
              OnClose={isKeyBack => {
                if (!isKeyBack) customHistory.goBack();
              }}
            />
          </Router>
        </Provider>
      </MuiThemeProvider>
    );
  }
}

let ccc = {
  overrides: {
    MuiListSubheader: {
      root: {
        lineHeight: "normal",
        height: 48,
        alignItems: "center",
        display: "flex"
      }
    }
  }
};
const themes = {
  light: createMuiTheme({
    spacing: 4,
    palette: {
      type: "light",
      primary: {
        main: "#5e3e9a"
      }
    },
    ...ccc
  }),
  dark: createMuiTheme({
    spacing: 4,
    palette: {
      type: "dark",
      primary: {
        main: "#8f6fcc"
      },
      secondary: {
        main: "#ff6e40"
      }
    },
    ...ccc
  })
};

const PrivateRout = ({ component: Component, classes, rotas, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (Cloud.getTokenUser() && rest.load) return <Load {...props} />;
      let navigation = {
        navigate: (path, state = {}) =>
          props.history.push({ pathname: path, state }),
        goBack: () => props.history.goBack()
      };
      if (rest.aberto)
        return (
          <div className={classes.root}>
            <Component
              {...props}
              screenProps={{ store: rest.store }}
              navigation={navigation}
            />
          </div>
        );
      return (
        <ContentBody
          {...props}
          rotas={rotas}
          screenProps={{ store: rest.store }}
          navigation={navigation}
        >
          <Component
            {...props}
            screenProps={{ store: rest.store }}
            navigation={navigation}
          />
        </ContentBody>
      );
    }}
  />
);

function Body({ children, classes, rotas = [], ...props }) {
  return (
    <div className={classes.root}>
      <Dash {...props} rotas={rotas.filter(a => a.menu)} />
      <main id="main" className={classes.content}>
        {children}
      </main>
    </div>
  );
}

const styles = theme => ({
  root: {
    display: "flex",
    width: "100%",
    backgroundColor: theme.palette.background.default,
    height: "-webkit-fill-available",
    height: "100%"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
    marginTop: 60,
    paddingTop: 35
  }
});
const PrivateRoute = withStyles(styles)(PrivateRout);
const ContentBody = withStyles(styles)(Body);
export default App;
