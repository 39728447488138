import React from "react";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";

export default function Halop(props) {
  return (
    <View container justify="center" spacing={16}>
      <TouchableOpacity
        onPress={() => props.closeModal()}
        style={{ margin: 20, marginLeft: "auto" }}
        children={"Fechar"}
        color={"default"}
        variant="contained"
      ></TouchableOpacity>
      <View xs={12} item></View>
      <View xs={12} ms={10} md={8} lg={6} item>
        <View
          style={{
            padding: 20,
            marginTop: 10
          }}
          type={"Paper"}
        >
          <iframe
            width="100%"
            height="500"
            src={props.url}
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </View>
      </View>
    </View>
  );
}
