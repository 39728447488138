import React, { Component } from "react";
import StyleSheet from "react-1app/lib/StyleSheet";
import View from "react-1app/lib/View";
import Text from "react-1app/lib/Text";
import Icon from "react-1app/lib/Icon";
import TextInput from "react-1app/lib/TextInput";
import styleGlobal from "../../styleGlobal";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Switch from "react-1app/lib/Switch";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListView from "react-1app/lib/ListView";
import ListItem from "@material-ui/core/ListItem";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import md5 from "md5";
import * as User from "../../worker/equipe_new";
import * as WorkerLogin from "../../worker/login";
import { OpenSnack } from "react-1app/lib/SnackBar";

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.screenProps.store.getState().user || {},
      projetos: this.props.screenProps.store.getState().projetos || []
    };
    console.log(this.state.user);
  }
  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (
        store.user != this.state.user ||
        store.projetos != this.state.projetos
      )
        this.setState({ projetos: store.projetos, user: store.user });
    });
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  onChange = tag => value => {
    this.setState({ user: { ...this.state.user, [tag]: value } });
  };

  salvar() {
    let { user, alterarSenha, senhaOld, senha, senha2 } = this.state;
    if (alterarSenha) {
      if (!senhaOld || md5(senhaOld) != user.password)
        return alert("Verifique senha atual");
      if (!senha || !senha2 || senha != senha2)
        return alert("Verifique nova senha e a confirmação de senha");
      user.password = md5(senha);
    }
    User.atualizarUsuario(user, (new_user, error) => {
      if (error) {
        OpenSnack({ message: error.msg || "Erro ao atualizar", type: "error" });
      } else {
        this.props.screenProps.store.dispatch({
          type: "LOGIN",
          user: {...user,...new_user}
        });
        WorkerLogin.cacheUser({...user,...new_user});
        OpenSnack({
          message: "Atualizado com sucesso",
          type: "success"
        });
        this.setState({
          alterarSenha: false,
          senhaOld: "",
          senha: "",
          senha2: ""
        });
      }
    });
  }

  render() {
    return (
      <View container spacing={16}>
        <View {...styleGlobal.gridListDimacao} item>
          <View type={"Paper"} style={styles.root}>
            <Text>{"Perfil"}</Text>
            <TextInput
              value={this.state.user.nome}
              onChange={this.onChange("nome")}
              keyboardType={"default"}
              label={"Nome"}
            />
            <TextInput
              value={this.state.user.login}
              onChange={this.onChange("login")}
              keyboardType={"default"}
              label={"Login"}
            />
            <Switch
              style={styles.switch}
              value={this.state.alterarSenha}
              onChange={value => {
                this.setState({ alterarSenha: value });
              }}
              label={"Alterar senha"}
            />
            {this.state.alterarSenha ? (
              <TextInput
                value={this.state.senhaOld}
                type="password"
                onChange={value => {
                  this.setState({ senhaOld: value });
                }}
                keyboardType={"default"}
                label={"Senha atual"}
              />
            ) : null}
            {this.state.alterarSenha ? (
              <TextInput
                value={this.state.senha}
                type="password"
                onChange={value => {
                  this.setState({ senha: value });
                }}
                keyboardType={"default"}
                label={"Nova senha"}
              />
            ) : null}
            {this.state.alterarSenha ? (
              <TextInput
                value={this.state.senha2}
                type="password"
                onChange={value => {
                  this.setState({ senha2: value });
                }}
                keyboardType={"default"}
                label={"Confirmação de senha"}
              />
            ) : null}
            <View style={styles.action}>
              <TouchableOpacity
                onPress={() => this.salvar()}
                children={"Salvar"}
                variant="outlined"
              ></TouchableOpacity>
            </View>
          </View>
        </View>
        <View {...styleGlobal.gridEditDimacao} item>
          <View type={"Paper"} >
            <ListView
              ListHeaderComponent={() => (
                <View style={styles.head}>
                  <ListSubheader component="div">
                    {"Casas de evento vinculadas"}
                  </ListSubheader>
                </View>
              )}
              renderItem={({ item, index }) => {
                return (
                  <ListItem
                    key={index}
                    style={{ width: "100%" }}
                    onClick={event =>
                      this.setState({ item, value: 0, index }, () => {
                        setTimeout(() => {
                          window.focusElementById("editar");
                        }, 100);
                      })
                    }
                  >
                    <Avatar src={item.logo}>
                      <Icon name={"image"} />
                    </Avatar>
                    <ListItemText inset primary={item.nome} />
                  </ListItem>
                );
              }}
              separator
              data={this.state.projetos}
            />
          </View>
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  root: {
    padding: 20
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  add: {
    marginLeft: "auto"
  },
  delete: {
    marginLeft: 20
  }
});
