import React, { Component } from "react";

import {
  View,
  Progress,
  SelectInput,
  Text,
  TextInput,
  StyleSheet,
  TouchableOpacity,
  OpenSnack
} from "react-1app";
import PdvNovoPdvProdutos from "../pdv/NovoPdvProdutos.js";
import * as Pdv from "../../worker/pdv";
import styleGlobal from "../../styleGlobal";

export default class Produtos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      historico: [...this.props.historico],
      produtos: null,
      caixa: this.props.caixa || {}
    };
  }

  clonar() {
    let lista = this.state.produtos.filter(i => i.add);
    console.log(lista);

    Pdv.clonarProdutos(
      { id_caixa_padrao: this.props.id_caixa_padrao, lista },
      (caixa, error) => {
        console.log(caixa, error);
        this.onRes(caixa, error, "clonar produtos");
      }
    );
  }

  onRes(caixa, error, tag) {
    if (error) {
      OpenSnack({ message: "Erro ao " + tag + " caixa!", type: "error" });
    } else {
      this.props.atualizar();
      this.props.closeModal();
      OpenSnack({
        message: "Caixa ao " + tag + " com sucesso!",
        type: "success"
      });
    }
  }

  setCaixaHistorico(value) {
    if (value && value > 0) {
      this.pesquisar(value);
    } else {
      this.setState({ produtos: [] });
    }
  }

  pesquisar(id_caixa) {
    var empresa = this.props.screenProps.store.getState().projeto;
    if (!empresa || !id_caixa) return;
    var data = {
      id_empresa: empresa.id,
      id_caixa_padrao: id_caixa,
      limit: 1000
    };
    this.setState({ produtos: null, load: true });
    Pdv.listarProdutos(data, (data=[], error) => {
      if (error) alert(error.msg);
      this.setState({ produtos: data,load:false });
    });
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View style={styles.tela} type={"Paper"}>
            <Text style={styles.label}>
              {"CLONAR PRODUTOS PARA O CAIXA PADRÃO"}
            </Text>
            <SelectInput
              style={styles.selectinput}
              value={this.state.caixa.id_pai}
              key_value="id"
              key_label="nome"
              onChange={(value, data, index) => {
                console.log(value);
                this.setCaixaHistorico(value);
              }}
              list={this.state.historico}
              label={"Caixas anteriores para copiar"}
            />

            {this.state.produtos && !this.state.load ? (
              <View style={styles.view2}>
                <PdvNovoPdvProdutos
                  style={styles.fragment}
                  produtos={this.state.produtos}
                  screenProps={this.props.screenProps}
                  navigation={this.props.navigation}
                  activity={this}
                />
              </View>
            ) : null}

            {this.state.load ? (
              <View style={styles.view3}>
                <Progress style={styles.progress} />
              </View>
            ) : null}
            <View style={styles.action}>
              <TouchableOpacity
                onPress={() => this.clonar()}
                children={"Copiar"}
                variant="outlined"
              ></TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.props.closeModal()}
                style={styles.delete}
                children={"Fechar"}
                color={"default"}
                variant="outlined"
              ></TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  tela: {
    padding: 20,
    marginTop: 50
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  },
  textinput1: {
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    textAlign: "left",
    fontWeight: "normal"
  },
  selectinput: {},
  view2: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  fragment: {
    alignSelf: "stretch",
    flex: 1
  },
  view3: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  progress: {
    width: 35,
    height: 35
  },
  text: {
    textAlign: "center",
    color: "rgba(255,255,255,1)",
    alignSelf: "stretch",
    fontWeight: "bold"
  }
});
