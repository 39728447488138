import React, { Component } from "react";

import {
  TouchableOpacity,
  View,
  Text,
  StyleSheet,
  OpenSnack,
  TextInput
} from "react-1app";
import * as Notificacao from "../../worker/notificacoes";
import styleGlobal from "../../styleGlobal";
import InputAdornment from "@material-ui/core/InputAdornment";
import md5 from "md5";

export default class NotificacaoClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.screenProps.store.getState().user,
      notificacao: {
        ...this.props.notificacao,
        title: "",
        body: ""
      },
      contTitle: 35,
      contText: 150,
      limite: {
        title: 35,
        body: 150
      },
      load: false
    };
  }

  confirmarNotificacao() {
    let senha = prompt(
      "Confirmar envio de notificação? Para confirmar digite sua senha."
    );
    if (senha && md5(senha) == this.state.user.password) {
      this.enviarNotificacao();
    } else {
      OpenSnack({
        message: "Senha inválida!",
        type: "error"
      });
    }
  }

  enviarNotificacao() {
    this.setState({ load: true });
    Notificacao.enviarNotificacao(
      { ...this.state.notificacao, type: 1 },
      (retorno, erro) => {
        this.setState({ load: false });
        if (erro) {
          return OpenSnack({
            message: erro.msg || "Erro ao enviar notificação...",
            type: "error"
          });
        }
        OpenSnack({
          message: "Notificação enviada",
          type: "success"
        });
        this.props.closeModal();
      }
    );
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View style={styles.tela} type={"Paper"}>
            <Text style={styles.txNotif}>
              {this.props.titulo||"Enviar notificação para todos os usuários"}
            </Text>
            <View style={styles.view5}>
              <TextInput
                value={this.state.notificacao.title}
                onChange={value => {
                  this.state.notificacao.title = value;
                  this.state.contTitle = this.state.limite.title - value.length;

                  this.setState({
                    notificacao: this.state.notificacao,
                    contTitle: this.state.contTitle
                  });
                }}
                keyboardType={"default"}
                label={"Título"}
                limit={this.state.limite.title}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Text style={styles.text3}>{this.state.contTitle}</Text>
                    </InputAdornment>
                  )
                }}
              />
            </View>
            <View style={styles.view51}>
              <TextInput
                value={this.state.notificacao.body}
                onChange={value => {
                  this.state.notificacao.body = value;
                  this.state.contText = this.state.limite.body - value.length;
                  this.setState({
                    notificacao: this.state.notificacao,
                    contText: this.state.contText
                  });
                }}
                keyboardType={"default"}
                multiline={true}
                limit={this.state.limite.text}
                label={"Texto"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Text style={styles.text31}>{this.state.contText}</Text>
                    </InputAdornment>
                  )
                }}
              />
            </View>
            <View style={styles.action}>
              <TouchableOpacity
                onPress={() => {
                  this.confirmarNotificacao();
                }}
                load={this.state.load}
                children={"Enviar"}
                variant="outlined"
              ></TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.props.closeModal()}
                style={styles.delete}
                children={"Fechar"}
                color={"default"}
                variant="outlined"
              ></TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  tela: {
    padding: 20,
    marginTop: 50
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  },
  txNotif: {
    fontSize: 20,
    textAlign: "center",
    marginBottom: 10
  },
  view5: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row"
  },

  text3: {
    textAlign: "center",
    color: "rgba(150,150,145,1)",
    justifyContent: "flex-end",
    alignSelf: "stretch",
    fontWeight: "normal"
  },
  view51: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row"
  },
  text31: {
    textAlign: "center",
    color: "rgba(150,150,145,1)",
    justifyContent: "flex-end",
    alignSelf: "stretch",
    fontWeight: "normal"
  }
});
