import React, { Component } from "react";

import * as Usuario from "../../worker/usuario";
import Switch from "react-1app/lib/Switch";
import Icon from "react-1app/lib/Icon";
import Avatar from "@material-ui/core/Avatar";
import TextInput from "react-1app/lib/TextInput";
import Text from "react-1app/lib/Text";
import StyleSheet from "react-1app/lib/StyleSheet";
import moment from "moment";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import View from "react-1app/lib/View";
import { Util } from "../../infra";
import Popper from "@material-ui/core/Popper";
import Button from "@material-ui/core/Button";
import SelectInput from "react-1app/lib/SelectInput";
import ListView from "react-1app/lib/ListView";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import styleGlobal from "../../styleGlobal";
import PerfilUser from "./Perfil";
import Cartoes from "./Cartoes";

export default class Listar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      load: true,
      total: 0,
      parametros: {
        limit: 10,
        page: 0,
        promoter: false,
        busca: "",
        orden: "criacao"
      },
      open: false,
      promoter: {},
      projeto: this.props.screenProps.store.getState().projeto || {},
      user: this.props.screenProps.store.getState().user || {},
      senha: "",
      ordens: [
        { nome: "Criação", value: "criacao" },
        { nome: "Mais gastou", value: "gasto" }
      ]
    };
    console.log(this.state.user);
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.projeto != this.state.projeto)
        this.setState({ projeto: store.projeto, anchorEl: null }, () => {
          this.buscar();
        });
    });
    this.buscar();
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }
  buscar() {
    this.setState({ load: true });
    Usuario.listar(
      this.state.parametros,
      (dados = { list: [], total: 0 }, error) => {
        this.setState({
          list: dados.list,
          total: dados.total,
          load: false
        });
      }
    );
  }


  render() {
    return (
      <View container spacing={16}>
        <View {...styleGlobal.gridListDimacao} item>
          <View type={"Paper"} style={styles.root}>
            <ListView
              ListHeaderComponent={() => (
                <View style={styles.head}>
                  <Text
                    style={[styleGlobal.SubHeader, { marginRight: "auto" }]}
                  >
                    {"Usuarios"}
                  </Text>
                  <Button
                    aria-describedby={
                      this.state.open ? "simple-popper" : undefined
                    }
                    onClick={event => {
                      this.setState({
                        anchorEl: event.currentTarget,
                        open: !this.state.open
                      });
                    }}
                  >
                    <Icon name={"filter_list"} />
                  </Button>

                  <Popper
                    id={this.state.open ? "simple-popper" : undefined}
                    open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    placement={"bottom-end"}
                  >
                    <View type={"Paper"} style={{ padding: 10, width: 330 }}>
                      <Text>{"Filtro"}</Text>
                      <TextInput
                        value={this.state.parametros.busca}
                        onChange={value => {
                          this.setState({
                            parametros: {
                              ...this.state.parametros,
                              busca: value
                            }
                          });
                        }}
                        keyboardType={"default"}
                        label={"Buscar por nome, email e id"}
                        onSubmitEditing={() => {
                          this.buscar();
                        }}
                      />
                      <Switch
                        style={styles.switch}
                        value={this.state.parametros.promoter}
                        onChange={value => {
                          this.setState({
                            parametros: {
                              ...this.state.parametros,
                              promoter: value
                            }
                          });
                        }}
                        label={"Promoters"}
                      />
                      <SelectInput
                        style={styles.selectinput2}
                        value={this.state.parametros.orden}
                        onChange={(value, data, index) => {
                          this.setState({
                            parametros: {
                              ...this.state.parametros,
                              orden: value
                            }
                          });
                        }}
                        label={"Orden"}
                        list={this.state.ordens}
                      />
                      <View style={styles.action}>
                        <TouchableOpacity
                          children={"Filtrar"}
                          variant="outlined"
                          onPress={() => {
                            this.buscar();
                            this.setState({ open: false });
                          }}
                        />
                        <TouchableOpacity
                          children={"Limpar"}
                          variant="outlined"
                          onPress={() => {
                            this.setState(
                              {
                                open: false,
                                parametros: {
                                  ...this.state.parametros,
                                  promoter: false,
                                  order: false,
                                  busca: "",
                                  orden: "criacao"
                                }
                              },
                              () => {
                                this.buscar();
                              }
                            );
                          }}
                        />
                      </View>
                    </View>
                  </Popper>
                </View>
              )}
              renderItem={({ item, index }) => {
                return (
                  <ListItem
                    button
                    key={index}
                    style={{ width: "100%" }}
                    selected={this.state.index === index}
                    onClick={event =>
                      this.setState({ index, item }, () => {
                        setTimeout(() => {
                          window.focusElementById("editar");
                        }, 100);
                      })
                    }
                  >
                    <Avatar src={item.foto || "/img/user.png"} />
                    <ListItemText
                      primary={item.nome}
                      secondary={
                        item.email +
                        " | " +
                        item.id +
                        " | " +
                        moment(item.criado).format("DD/MM/YYYY HH:mm")
                      }
                    />
                    <Text>{Util.parseMoney(item.valor_gasto)}</Text>
                  </ListItem>
                );
              }}
              separator
              nativo
              data={this.state.list}
              tablePagination
              load={this.state.load}
              count={Number(this.state.total)}
              page={this.state.parametros.page}
              rowsPerPage={this.state.parametros.limit}
              handleChangePage={(event, page) => {
                this.setState(
                  {
                    parametros: Object.assign(this.state.parametros, { page }),
                    load: true
                  },
                  this.buscar()
                );
              }}
              handleChangeRowsPerPage={event => {
                this.setState(
                  {
                    parametros: Object.assign(this.state.parametros, {
                      limit: event.target.value,
                      page: 0
                    }),
                    load: true
                  },
                  this.buscar()
                );
              }}
            />
          </View>
        </View>
        <View {...styleGlobal.gridEditDimacao} item>
          {this.state.item && this.state.item.id ? (
            <PerfilUser
              {...this.props}
              item={this.state.item}
              atualizar={() => this.buscar()}
            />
          ) : null}
          {this.state.item && this.state.item.id ? (
            <Cartoes
              {...this.props}
              item={this.state.item}
              atualizar={() => this.buscar()}
            />
          ) : null}
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  textinput: {
    width: 200,
    marginLeft: "auto",
    color: "rgba(150,150,150,1)",
    borderRadius: 5,
    backgroundColor: "rgba(0,0,0,0.12)",
    padding: 4,
    height: 40,
    fontSize: 15
  },
  view: {
    padding: 20,
    paddingTop: 0
  },
  action: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  head: {
    height: 50,
    width: "100%",
    padding: 5
  },
  action: {
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10
  },
  avatar: {
    width: 60,
    height: 60
  }
});
