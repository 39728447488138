import React from "react";
import moment from "moment";
import { Util } from "../../infra";
import StyleSheet from "react-1app/lib/StyleSheet";
import Table from "react-1app/lib/Table";
import * as Pdv from "../../worker/pdv";


export default class Pagamentos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      lista: [],
      parametros: {
        text: "",
        limit: 10,
        page: 0,
        paginacao:true
      },
      totalLista: 0,
      caixa:this.props.caixa,
      load:true,
      entrada:{}
    };
  }

  componentDidMount(){
    this.buscarDados();

  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.caixa&&this.props.caixa&&nextProps.caixa.id!=this.props.caixa.id)
    this.setState({caixa:nextProps.caixa,load:true},()=>{
      this.buscarDados();
    });
    return true;
  }

  buscarDados(caixa) {
    if (!this.state.caixa || !this.state.caixa.id)
    return this.setState({ lista: [], restante: null });
    if (!caixa) caixa = this.props.caixa;
    var empresa = this.props.screenProps.store.getState().projeto;

    var data = {
      caixa: {codigo_caixa:caixa.codigo_caixa},
      id_empresa: empresa.id,
      codigo_caixa: caixa.codigo,
      ...this.state.parametros
    };
    Pdv.listarVendas(data,(doados = {list:[],total:0}, error) => {
        if (error) return console.log(error);
        this.setState({ lista: doados.list,load:false,restante: doados.restante, totalLista: doados.total });
      });
    }

    nova(entrada){
      this.setState({ entrada:{id_produto:this.state.produto.id,...entrada}, open: true });
    }

    getValor(item) {
      var valor = Util.parseMoney(item.valor);
      if (item.reimpressao)
        return item.reimpressao + " REIMPRESSOES " + valor;
      return valor;
    }

    getDescricao(item) {
      if (!item.descricao)
        return moment(item.criado).fromNow();
      return (
        item.id_local +
        " - ticket " +
        item.descricao +
        " " +
        item.forma_pagamento +
        " " +
        moment(item.criado).fromNow()
      );
    }
    render() {
      return (
        <Table
          title={"HISTÓRICO DE VENDAS"}
          load={this.state.load}
          tableHead={[
            "Data",
            "Quantidade",
            "Descrição",
            "Valor",
          ]}
          tableData={this.state.lista.map((item, index) => [
            moment(item.criado_data).format("DD/MM/YY HH:mm"),
            item.nome,
            this.getDescricao(item),
            this.getValor(item),
          ])}
          data={this.state.list}
          tablePagination
          load={this.state.load}
          count={Number(this.state.totalLista)}
          page={this.state.parametros.page}
          rowsPerPage={this.state.parametros.limit}
          handleChangePage={(event, page) => {
            this.setState(
              {
                parametros: Object.assign(this.state.parametros, { page }),
                load: true
              },
              this.buscarDados()
            );
          }}
          handleChangeRowsPerPage={event => {
            this.setState(
              {
                parametros: Object.assign(this.state.parametros, {
                  limit: event.target.value,
                  page: 0
                }),
                load: true
              },
              this.buscarDados()
            );
          }}
          />
      );
    }
  }

  var styles = StyleSheet.create({
    view:{
      width: 250,
      alignItems: "center",
      justifyContent: "space-between"
    }
  });
