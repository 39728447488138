import React from "react";
import ChartistGraph from "react-chartist";
import moment from "moment";
import StatsCard from "./Cards/StatsCard.js";
import ChartCard from "./Cards/ChartCard.js";
import { Util } from "../../infra";
import Grid from "react-1app/lib/View";
import * as Relatorios from "../../worker/relatorios";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Table } from "react-1app";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "./Cards/charts";
let height = 170;
export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      empresa: {},
      loadEstatisca: false,
      formatEstatistica: [],
      code: "",
      cupom: {},
      dadosPeriodo: {},
      dadosStatus: {},
      dadosSemanais: [],
      dadosMensais: [],
      usuariosMensais: [],
      projeto: this.props.screenProps.store.getState().projeto,
      open: false,
      load: true,
      rebimento: []
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.projeto != this.state.projeto)
        this.setState(
          { projeto: store.projeto, anchorEl: null, open: false, load: true },
          () => {
            this.buscarDados();
          }
        );
    });
    this.buscarDados();
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  buscarDados() {
    Relatorios.totalizadoresPriodo({}, (resposta = {}, error) => {
      this.setState({ dadosPeriodo: resposta });
    });
    // console.log(this.state.projeto);
    if (this.state.projeto.gateway_pagamento === 2) {
      Relatorios.getSaldoGet({}, (rebimento = [], error) => {
        console.log(rebimento);
        this.setState({ rebimento });
      });
    }
    Relatorios.getDadosSemanais({}, (resposta = [], error) => {
      Relatorios.getDadosMensais({}, (resposta2 = [], error) => {
        Relatorios.getUsuariosMensais({}, (resposta3 = [], error) => {
          this.setState({
            dadosSemanais: resposta,
            dadosMensais: resposta2,
            usuariosMensais: resposta3,
            load: false
          });
        });
      });
    });
  }

  getHeight(list, name, add) {
    add = add ? add : 30;
    let init = 0;
    if (list && list[0]) {
      let newL = list.map(d => ({ iTotal: d[name] }));
      for (var i = 0; i < newL.length; i++) {
        let t = Number(newL[i].iTotal);
        // console.log(newL[i]);
        if (t > init) init = t;
      }
    }
    return init + init * 0.05;
  }

  retornarPorcentagem(atual = 0, anterior = 0) {
    let total = ((atual - anterior) / anterior) * 100;
    return Number(total.toFixed(2)) || 0;
  }

  retornarPorcentagemConversao(atual = 0, anterior = 0) {
    let total = (atual / anterior) * 100;
    return total.toFixed(2);
  }

  calculaDiferencaConversao() {
    let { concluido_atual, concluido_anterior, total_atual, total_anterior } =
      this.state.dadosPeriodo.conversao || {};
    return (
      Number(
        this.retornarPorcentagem(
          this.retornarPorcentagemConversao(concluido_atual, total_atual),
          this.retornarPorcentagemConversao(concluido_anterior, total_anterior)
        )
      ) || 0
    );
  }

  calculaValorAtualConversao() {
    let { concluido_atual, total_atual } =
      this.state.dadosPeriodo.conversao || {};
    return (
      Number(this.retornarPorcentagemConversao(concluido_atual, total_atual)) ||
      0
    );
  }

  invertePorcentagemNegativa(porcentagem) {
    return Number((porcentagem < 0 && porcentagem * -1) || porcentagem) || 0;
  }

  tratarListSemanal(lista = []) {
    let data = { labels: [], series: [] };
    data.labels = lista.map(item => moment(item.mes, "YYYY-MM-DD").set("hour",12).format("ddd"));
    // console.log(lista.map(item => "17/" + item.mes));
    data.series = [lista.map(item => item.total)];

    return data;
  }

  tratarList(lista = []) {
    let data = { labels: [], series: [] };
    data.labels = lista.map(item =>
      moment(item.mes + "/05", "YYYY-MM-DD").format("MMM")
    );
    // console.log(lista.map(item => "17/" + item.mes));
    data.series = [lista.map(item => item.total)];

    return data;
  }

  tratarDadosCias(lista) {
    let data = { labels: [], series: [] };
    let newLista = lista.filter(i => i.venda);
    data.labels = newLista.map((x, i) => i + 1 + "");
    data.dados = newLista.map(item => ({ name: item.name, value: item.venda }));
    // console.log(lista.map(item => "17/" + item.mes));
    data.series = newLista.map(item => item.venda || 0);

    return data;
  }

  render() {
    return (
      <Grid container style={{ marginTop: -30 }}>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={6} md={3}>
            <StatsCard
              icon={"people"}
              iconColor="darkgray"
              title="Clientes cadastrados"
              description={this.state.dadosPeriodo.user || 0}
              statIcon={"attach_money"}
              statIconColor={"white"}
              statText={
                (this.state.dadosPeriodo.user_compraram || 0) + " Já compraram"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {false && this.state.projeto.gateway_pagamento === 2 ? (
              <StatsCard
                icon={"local_grocery_store"}
                iconColor="darkgray"
                title="Saldo a receber"
                description={
                  (this.state.dadosPeriodo.pedidos_pagos || {}).total || 0
                }
                statIcon={"attach_money"}
                statIconColor={"white"}
                statText={"Este saldo caira automaticamente na sua conta"}
              />
            ) : null}
            {true || this.state.projeto.gateway_pagamento !== 2 ? (
              <StatsCard
                icon={"local_grocery_store"}
                iconColor="darkgray"
                title="Pedidos Pagos"
                description={
                  (this.state.dadosPeriodo.pedidos_pagos || {}).total || 0
                }
                statIcon={"local_grocery_store"}
                statIconColor={"white"}
                statText={
                  ((this.state.dadosPeriodo.pedidos_pagos || {}).total_atual ||
                    0) + " Última semana"
                }
              />
            ) : null}
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <StatsCard
              icon={"attach_money"}
              iconColor="darkgray"
              title="Total transicionado"
              description={
                !this.state.open ? (
                  <TouchableOpacity
                    children="Ver"
                    onPress={() => {
                      this.setState({ open: true });
                    }}
                  />
                ) : (
                  Util.parseMoney(
                    (this.state.dadosPeriodo.total_transicionado || {}).total ||
                      0
                  )
                )
              }
              statIcon={"attach_money"}
              statIconColor={"white"}
              statText={
                (this.state.open
                  ? Util.parseMoney(
                      (this.state.dadosPeriodo.total_transicionado || {})
                        .total_atual || 0
                    )
                  : "VER") + " Última semana"
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <StatsCard
              icon={"filter_list"}
              iconColor="darkgray"
              title="Taxa de conversão no app"
              description={this.calculaValorAtualConversao() + " %"}
              statIcon={
                this.calculaDiferencaConversao() >= 0
                  ? "arrow_drop_up"
                  : "arrow_drop_down"
              }
              statIconColor={
                this.calculaDiferencaConversao() >= 0 ? "green" : "red"
              }
              statText={
                this.invertePorcentagemNegativa(
                  this.calculaDiferencaConversao()
                ) + " % Última semana"
              }
            />
          </Grid>
          <Grid item xs={12}>
            {this.state.load ? <LinearProgress /> : null}
          </Grid>
        </Grid>
        <Grid container spacing={16}>
          <Grid item xs={12} md={6} lg={4}>
            <ChartCard
              chart={
                <ChartistGraph
                  className="ct-chart"
                  data={this.tratarListSemanal(this.state.dadosSemanais)}
                  type="Line"
                  options={{
                    ...dailySalesChart.options,
                    high: this.getHeight(this.state.dadosSemanais, "total"),
                    height
                  }}
                  listener={dailySalesChart.animation}
                />
              }
              chartColor="darkgray"
              title="Valor de compras por dia"
              text="Baseado na última semana"
              statIcon={"access_time"}
              statIconColor="darkgray"
              statText={
                "Atualizado em " +
                moment().format("DD") +
                " de " +
                moment().format("MMMM") +
                " de " +
                moment().format("YYYY")
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ChartCard
              chart={
                <ChartistGraph
                  className="ct-chart"
                  data={this.tratarList(this.state.dadosMensais)}
                  type="Bar"
                  options={{
                    ...emailsSubscriptionChart.options,
                    high: this.getHeight(this.state.dadosMensais, "total"),
                    height
                  }}
                  responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                  listener={emailsSubscriptionChart.animation}
                />
              }
              chartColor="darkgray"
              title="Valor de compras por mês"
              text="Baseado nos últimos 12 meses"
              statIcon={"access_time"}
              statIconColor="darkgray"
              statText={
                "Atualizado em " +
                moment().format("DD") +
                " de " +
                moment().format("MMMM") +
                " de " +
                moment().format("YYYY")
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ChartCard
              chart={
                <ChartistGraph
                  className="ct-chart"
                  data={this.tratarList(this.state.usuariosMensais)}
                  type="Line"
                  options={{
                    ...completedTasksChart.options,
                    high: this.getHeight(this.state.usuariosMensais, "total"),
                    height
                  }}
                  listener={completedTasksChart.animation}
                />
              }
              chartColor="darkgray"
              title="Novos usuários por mês"
              text="Baseado nos últimos 12 meses"
              statIcon={"access_time"}
              statIconColor="darkgray"
              statText={
                "Atualizado em " +
                moment().format("DD") +
                " de " +
                moment().format("MMMM") +
                " de " +
                moment().format("YYYY")
              }
            />
          </Grid>
          {this.state.projeto.gateway_pagamento === 2 ? (
            <Grid item xs={12} md={6}>
              <Table
                tableHead={["Data", "Valor"]}
                title={"Valores a receber em sua conta"}
                tableData={this.state.rebimento.map(item => [
                  moment(item.data_liberacao).format("DD/MM/YYYY"),
                  Util.parseMoney(item.sum)
                ])}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    );
  }
}
