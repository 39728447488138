import React from "react";
import ListSubheader from "@material-ui/core/ListSubheader";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Icon from "react-1app/lib/Icon";
import Switch from "react-1app/lib/Switch";
import TextInput from "react-1app/lib/TextInput";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListView from "react-1app/lib/ListView";
import * as IngressoPadrao from "../../worker/ingresso_padrao";
import { OpenSnack } from "react-1app/lib/SnackBar";
import { Util } from "../../infra";
import styleGlobal from "../../styleGlobal";
import ModalVideoAjuda from "../ModalVideoAjuda";
import { OpenModal } from "react-1app/lib/PgModal";

class Produtos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {},
      load: true,
      totalLista: 0,
      list: [],
      parametros: {
        limit: 400,
        page: 0,
        paginacao: true
      }
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.projeto != this.state.projeto)
        this.setState({ projeto: store.projeto }, () => {
          this.buscar();
        });
    });
    this.buscar();
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  buscar() {
    IngressoPadrao.listar(
      this.state.parametros,
      (dados = { list: [], total: 0 }, error) => {
        this.setState({
          list: dados.list,
          total: dados.total,
          load: false
        });
      }
    );
  }

  salvar() {
    if (this.state.item.id && this.state.item.id !== "new") {
      IngressoPadrao.atualizar(this.state.item, (evento, error) => {
        this.onRes(evento, error, "atualizar");
      });
    } else {
      IngressoPadrao.salvar(this.state.item, (evento, error) => {
        this.onRes(evento, error, "salvar");
      });
    }
  }

  onRes(evento, error, tag) {
    if (error) {
      OpenSnack({ message: "Erro ao " + tag + " o Ingresso!", type: "error" });
    } else {
      this.setState({ item: evento });
      this.buscar();
      OpenSnack({
        message: "Ingresso " + tag + " com sucesso!",
        type: "success"
      });
    }
  }

  remover() {
    if (window.confirm("Deseja remover este item?")) {
      IngressoPadrao.remover(this.state.item.id, (evento, error) => {
        if (error) {
          OpenSnack({ message: "Erro ao salvar o Ingresso!", type: "error" });
        } else {
          this.setState({ item: {} });
          this.buscar();
          OpenSnack({ message: "Ingresso removido!", type: "success" });
        }
      });
    }
  }

  render() {
    return (
      <View container spacing={16}>
        <View {...styleGlobal.gridListDimacao} item>
          <View type={"Paper"} style={styles.root}>
            <ListView
              ListHeaderComponent={() => (
                <View style={styles.head}>
                  <ListSubheader component="div">
                    {"Ingressos Padrões"}
                  </ListSubheader>
                  <TouchableOpacity
                    children={"Ajuda"}
                    style={styles.add}
                    variant="default"
                    onPress={() => {
                      OpenModal(ModalVideoAjuda,{url:"https://www.youtube.com/embed/eNEeGnjceHI"});
                    }}
                  />
                  <TouchableOpacity
                    children={"Novo"}
                    style={{marginLeft:10}}
                    variant="outlined"
                    onPress={() => {
                      this.setState(
                        { item: { id: "new" }, index: null },
                        () => {
                          setTimeout(() => {
                            window.focusElementById("editar");
                          }, 100);
                        }
                      );
                    }}
                  />
                </View>
              )}
              renderItem={({ item, index }) => {
                return (
                  <ListItem
                    button
                    key={index}
                    style={{ width: "100%" }}
                    selected={this.state.index === index}
                    onClick={event =>
                      this.setState({ index, item }, () => {
                        setTimeout(() => {
                          window.focusElementById("editar");
                        }, 100);
                      })
                    }
                  >
                    <Avatar src={item.img}>
                      <Icon name={"confirmation_number"} />
                    </Avatar>
                    <ListItemText
                      primary={item.nome}
                      secondary={"Valor: " + Util.parseMoney(item.valor)}
                    />
                    <Icon color="default" name={"created"} />
                  </ListItem>
                );
              }}
              separator
              nativo
              data={this.state.list}
              tablePagination
              load={this.state.load}
              count={Number(this.state.total)}
              page={this.state.parametros.page}
              rowsPerPage={this.state.parametros.limit}
              handleChangePage={(event, page) => {
                this.setState(
                  {
                    parametros: Object.assign(this.state.parametros, { page }),
                    load: true
                  },
                  this.buscar()
                );
              }}
              handleChangeRowsPerPage={event => {
                this.setState(
                  {
                    parametros: Object.assign(this.state.parametros, {
                      limit: event.target.value,
                      page: 0
                    }),
                    load: true
                  },
                  this.buscar()
                );
              }}
            />
          </View>
        </View>
        <View {...styleGlobal.gridEditDimacao} item>
          {this.state.item && this.state.item.id ? (
            <View type={"Paper"} id="editar" style={styles.view}>
              <View style={styles.head}>
                <ListSubheader component="div">
                  {"Ingresso Padrão"}
                </ListSubheader>
              </View>
              <ListSubheader>
                {
                  "OBS: Este ingressos são padrões para facilitar a criação de eventos"
                }
              </ListSubheader>
              <TextInput
                value={this.state.item.nome}
                onChange={value => {
                  this.setState({ item: { ...this.state.item, nome: value } });
                }}
                label={"Nome"}
              />
              <TextInput
                value={this.state.item.descricao}
                onChange={value => {
                  this.setState({
                    item: { ...this.state.item, descricao: value }
                  });
                }}
                label={"Descrição"}
                multiline={true}
              />
              <TextInput
                value={this.state.item.valor}
                onChange={value => {
                  this.setState({
                    item: {
                      ...this.state.item,
                      valor: Util.maskFormate(value, "money")
                    }
                  });
                }}
                label={"Valor de venda"}
              />
              <TextInput
                value={this.state.item.quantidade}
                onChange={value => {
                  this.state.item.quantidade = value;
                  this.setState({ item: this.state.item });
                }}
                keyboardType={"default"}
                label={"Quantidade"}
              />
              <TextInput
                value={this.state.item.quant}
                onChange={value => {
                  this.state.item.quant = value;
                  this.setState({ item: this.state.item });
                }}
                keyboardType={"default"}
                label={"Quantidade de pessoas por ingresso"}
              />
              <Switch
                style={styles.switch}
                value={this.state.item.free}
                onChange={value => {
                  this.state.item.free = value;
                  if (value) {
                    this.state.item.valor = 0;
                  }
                  this.setState({ item: this.state.item });
                }}
                label={"Ingresso Free"}
              />
              <Switch
                style={styles.switch}
                value={this.state.item.ativo}
                onChange={value => {
                  this.state.item.ativo = value;
                  this.setState({ item: this.state.item });
                }}
                label={"Ingresso Ativo"}
              />
              <Switch
                style={styles.switch}
                value={this.state.item.pdv}
                onChange={value => {
                  this.state.item.pdv = value;
                  this.setState({ item: this.state.item });
                }}
                label={"PDV"}
              />
              <Switch
                style={styles.switch}
                value={this.state.item.app}
                onChange={value => {
                  this.state.item.app = value;
                  this.setState({ item: this.state.item });
                }}
                label={"APP"}
              />
              <Switch
                style={styles.switch}
                value={this.state.item.promoter}
                onChange={value => {
                  this.state.item.promoter = value;
                  this.setState({ item: this.state.item });
                }}
                label={"Promoter"}
              />
              <View style={styles.action}>
                <TouchableOpacity
                  onPress={() => this.salvar()}
                  children={"Salvar"}
                  variant="outlined"
                />
                {this.state.item.id !== "new" ? (
                  <TouchableOpacity
                    onPress={() => this.remover()}
                    style={styles.delete}
                    children={"Apagar"}
                    color={"secondary"}
                    variant="outlined"
                  />
                ) : null}
              </View>
            </View>
          ) : null}
        </View>
      </View>
    );
  }
}

const styles = {
  root: {},
  icon: {
    margin: 15
  },
  cell: {
    width: "100%"
  },
  head: {
    height: 50,
    width: "100%",
    padding: 5
  },
  image: {
    maxWidth: 600,
    maxHeight: 600 / (16 / 9),
    height: "100vh",
    margin: "auto",
    borderRadius: 5,
    background: "rgba(0,0,0,0.1)"
  },
  gridEdit: {},
  view: {
    padding: 20,
    paddingTop: 0
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  add: {
    marginLeft: "auto"
  },
  delete: {
    marginLeft: 20
  }
};

export default Produtos;
