import React, { Component,useState } from "react";
import moment from "moment";
import TextInput from "react-1app/lib/TextInput";
import { MuiPickersUtilsProvider, TimePicker, DatePicker,DateTimePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import "moment/locale/pt-br";
import MomentUtils from "@date-io/moment";
var contador = 0;

function App({type,...props}) {
  // const [selectedDate, handleDateChange] = useState(new Date());
const Picker=getType(type)
  return (
    <MuiPickersUtilsProvider libInstance={moment}  utils={MomentUtils} locale={"pt-br"}>
      <Picker fullWidth ampm={false} {...props}  style={{marginTop:20,...props.style}}/>
    </MuiPickersUtilsProvider>
  );
}

function getType(type) {
  switch (type) {
    case "time":
      return TimePicker;
    case "date":
      return DatePicker;
    case "datetime-local":
      return DateTimePicker;
    default:
      return DateTimePicker;
  }
}


export class DatePickerTemp extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: this.humanize(props.value) };
  }
  getMask() {
    switch (this.props.type) {
      case "time":
        return "99:99";
      case "date":
        return "99/99/9999";
      case "datetime-local":
        return "99/99/9999 - 99:99";
      default:
        return "99/99/9999 - 99:99";
    }
  }

  placeholder() {
    switch (this.props.type) {
      case "time":
        return "HH:mm";
      case "date":
        return "DD/MM/YYYY";
      case "datetime-local":
        return "DD/MM/YYYY - HH:mm";
      default:
        return "DD/MM/YYYY - HH:mm";
    }
  }

  humanize(value){
    if(!value)return "";
    return moment(value).format(this.placeholder())
  }

  componentWillReceiveProps(props){
    if(props.value!=this.props.value)
    this.setState({value:this.humanize(props.value)})
  }

  onChange(value,teste,force){
    let date=moment(value,this.placeholder()).toJSON();
    console.log(date, "onBlur");
    if(teste&&date||date||force)
    this.props.onChange(date||this.props.value);
    this.setState({value});
  }

  render() {
    return (
      <TextInput
      placeholder={this.placeholder()}
        {...this.props}
        type="muneric"
        value={this.state.value}
        onChange={value => {
          this.onChange(value,true);
        }}
        mask={this.getMask()}
        onBlur={()=>{
          this.onChange(this.state.value,false,true);
        }}
      />
    );
  }
}

export const DatePickerUm = props => {
  let id = props.id || "DateImput" + contador++;
  return (
    <TextInput
      InputLabelProps={{
        shrink: true
      }}
      {...props}
      type={props.type || "datetime-local"}
      value={getValue(props)}
      onChange={value => {
        value = props.type == "time" ? moment(value,"HH:mm").format("HH:mm:ssZ") : moment(value).toJSON();
        if (props.onChange) props.onChange(value);
      }}
    />
  );
};

function getValue(props) {
  if (!props.value) return "";
  if (props.type == "time") return moment(props.value, "HH:mm:ssZ").format("HH:mm");
  if (props.type == "date") return moment(props.value).format("YYYY-MM-DD");
  return moment(props.value).format("YYYY-MM-DDTHH:mm");
}

// export DatePickerUm;
export default App;
