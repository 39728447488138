import React, { Component } from "react";

import {
  TouchableOpacity,
  View,
  Icon,
  ListView,
  Text,
  StyleSheet,
  PhotosUpload,
  OpenSnack,
  OpenDialog,
  SelectInput
} from "react-1app";
import ListItem from "@material-ui/core/ListItem";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import styleGlobal from "../../styleGlobal";
import * as facebook from "../../worker/integracao_facebook";
import * as Evento from "../../worker/evento";
import LinearProgress from "@material-ui/core/LinearProgress";

export default class Fotos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      evento: this.props.evento || {},
      listaAlbuns: [],
      lista: [],
      albuns: [],
      id_selecionado: "",
      load: true
    };
    this.state.user = this.props.screenProps.store.getState().user;
  }

  componentDidMount() {
    this.carregaPaginas();
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  carregaPaginas() {
    facebook.getPages((data, error) => {
      if (error)
        OpenSnack({
          message: error&&error.msg?error.msg+'':"error",
          type: "error"
        });
      if (data && data.lista)
        for (let i = 0; i < data.lista.length; i++) {
          let item = data.lista[i];
          item.nome = item.name;
          item.text = item.name;
        }
      this.setState({ lista: (data && data.lista) || [], load: false });
    });
  }

  getPage() {
    var id = this.state.id_selecionado;
    for (let i = 0; i < this.state.lista.length; i++) {
      let item = this.state.lista[i];
      if (item.id + "" == id + "") return item;
    }
  }

  buscarAlbuns() {
    this.setState({ loadPg: true });
    var page = this.getPage();
    if (page)
      facebook.getAlbunsPagina(page.id, page.access_token, data => {
        this.setState({ albuns: data.data, id_page: page.id, loadPg: false });
      });
  }

  validarLimparFotos() {
    OpenDialog({
      title: "Fotos",
      description: "Confirmação para desvincular as fotos deste evento!",
      action: [
        {
          label: "CONFIRMAR",
          onClick: () => {
            this.limparFotos();
          },
          color: "secondary"
        },
        { label: "CANCELAR" }
      ]
    });
  }

  limparFotos() {
    Evento.atualizar(
      { id: this.state.evento.id, fotos: [] },
      this.state.user,
      (evento, error) => {
        if (error) {
          OpenSnack({ message: "Erro ao editar!", type: "error" });
        } else {
          this.setState({ evento });
          this.props.atualizar();
          OpenSnack({
            message: "Fotos desvinculadas!",
            type: "success"
          });
          this.props.closeModal();
        }
      }
    );
  }

  atualizar() {
    Evento.atualizar(
      { id: this.state.evento.id, fotos: this.state.evento.fotos },
      this.state.user,
      (evento, error) => {
        if (error) {
          OpenSnack({ message: "Erro ao editar!", type: "error" });
        } else {
          this.setState({ evento });
          this.props.atualizar();
          OpenSnack({
            message: "Fotos salvas com sucesso!",
            type: "success"
          });
          this.props.closeModal();
        }
      }
    );
  }

  vincular(item) {
    this.setState({ loadPg: true });
    facebook.getImagensDoAlbum(
      item.id,
      this.state.evento.id,
      this.getPage().access_token,
      (retorno, erro) => {
        if (retorno) {
          OpenSnack({ message: "Vinculado com sucesso", type: "success" });
          this.props.atualizar();
          this.props.closeModal();
        }
        if (erro || !retorno) {
          OpenSnack({
            message: "Não há fotos neste album para ser vinculado ao evento",
            type: "error"
          });
        }
        this.setState({ loadPg: false });
      }
    );
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View
          style={{ ...styles.Grid, paddingTop: 50 }}
          xs={12}
          ms={10}
          md={8}
          lg={6}
          item
        >
          <View type={"Paper"} style={{ padding: 20 }}>
            <Text style={styles.text2}>
              {"Vincular Album de Fotos no Evento"}
            </Text>
            {this.state.load ? <LinearProgress /> : null}
            {!this.state.load ? (
              <SelectInput
                style={styles.selectinput}
                onChange={(value, data, index) => {
                  console.log(value, data, index);
                  this.setState({ id_selecionado: value }, () => {
                    this.buscarAlbuns();
                  });
                }}
                key_value={"id"}
                label={"Selecione a pagina do FACEBOOK"}
                value={this.state.id_selecionado}
                list={this.state.lista}
              />
            ) : null}
            <View style={styles.view3}>
              {this.state.id_selecionado ? (
                <ListView
                  style={styles.listview}
                  dataSource={this.state.albuns}
                  load={this.state.loadPg}
                  renderHeader={() => {
                    return (
                      <Head
                        navigation={this.props.navigation}
                        screenProps={this.props.screenProps}
                        oldState={this.state}
                        activity={this}
                      />
                    );
                  }}
                  renderRow={(item, index, rowID) => {
                    return (
                      <ListItem key={index} style={{ width: "100%" }}>
                        <Avatar
                          src={item.cover_photo && item.cover_photo.source}
                          style={{ width: 60, height: 60 }}
                        >
                          <Icon name={"event"} />
                        </Avatar>
                        <ListItemText
                          primary={item.name}
                          secondary={"Quantidade de fotos: " + item.photo_count}
                        />
                        <ListItemSecondaryAction>
                          <TouchableOpacity
                            onPress={() => this.vincular(item)}
                            style={styles.delete}
                            children={"Vincular"}
                            variant="outlined"
                            disabled={this.state.loadPg}
                          ></TouchableOpacity>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  }}
                />
              ) : null}
            </View>
            {this.props.evento.fotos &&
            this.props.evento.fotos[0] &&
            !this.state.id_selecionado ? (
              <PhotosUpload
                value={this.props.evento.fotos}
                style={{ height: 500 }}
                label={
                  "Fotos do evento, Total: " + this.props.evento.fotos.length
                }
                onChange={fotos => {
                  this.props.evento.fotos = fotos;
                  this.setState({ evento: this.state.evento });
                }}
              />
            ) : null}
            <View style={styles.action}>
              {this.state.evento &&
              this.state.evento.fotos &&
              this.state.evento.fotos[0] ? (
                <TouchableOpacity
                  onPress={() => this.atualizar()}
                  load={this.state.load}
                  children={"Salvar"}
                  variant="outlined"
                ></TouchableOpacity>
              ) : null}
              {this.state.evento &&
              this.state.evento.fotos &&
              this.state.evento.fotos[0] ? (
                <TouchableOpacity
                  onPress={() => this.validarLimparFotos()}
                  style={styles.delete}
                  children={"Desvincular fotos"}
                  color={"secondary"}
                  variant="outlined"
                ></TouchableOpacity>
              ) : null}
              <TouchableOpacity
                onPress={() => this.props.closeModal()}
                style={styles.delete}
                children={"Fechar"}
                color={"default"}
                variant="outlined"
              ></TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

class Head extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <View
        style={styles.head}
        screenProps={this.props.screenProps}
        navigation={this.props.navigation}
        activity={this}
      >
        <Text style={styles.text2}>{"VINCULAR AO EVENTO"}</Text>
        <Text style={styles.text71}>
          {
            "Apenas vincular 1 album por evento, ao clicar em outro irá substituir o album anteriormente vinculado"
          }
        </Text>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  action: {
    padding: 10,
    paddingTop: 40,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  },
  fragment51: {
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  selectinput: {
    minHeight: 40,
    alignSelf: "stretch"
  },
  view3: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  listview: {
    alignSelf: "stretch",
    flex: 1
  },
  head: {
    alignSelf: "stretch",
    padding: 10,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  text2: {
    textAlign: "center",
    alignSelf: "stretch",
    fontWeight: "normal"
  },
  text71: {
    textAlign: "center",
    alignSelf: "stretch",
    fontWeight: "normal",
    fontSize: 12,
    margin: 10
  }
});
