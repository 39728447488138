import { Cloud } from "../infra";

export function listar(input, callback) {
  Cloud.get("pagamentos", input, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function estornar(pagamento, callback) {
  Cloud.put("pagamentos/estornar", pagamento, (res, error) => {
    if (callback) callback(res, error);
  });
}
