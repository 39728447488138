import React, { Component } from "react";

import { StyleSheet, PhotosUpload, Icon, ImageUpload } from "react-1app";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import View from "react-1app/lib/View";
import { OpenSnack } from "react-1app";
import ConfiguracoesDados from "../configuracoes/Dados.js";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import * as Configuracoes from "../../worker/configuracoes";
import Acesso from "./Acessos.js";
import Equipe from "./Equipe.js";
import Config from "./Config.js";
import Conta from "./Conta.js";

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      empresa: this.props.screenProps.store.getState().empresa,
      user: this.props.screenProps.store.getState().user,
      value: 0
    };
    this.props.screenProps.store.dispatch({
      type: "TITLE",
      data: "Configurações",
      bts: []
    });
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.empresa != this.state.empresa)
        this.setState({ empresa: store.empresa, value: 0 });
    });
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  salvar() {
    let { empresa } = this.state;
    Configuracoes.salvar(empresa, (resposta, error) => {
      if (resposta) {
        OpenSnack({ message: "Salvo com sucesso", type: "success" });
        // this.props.screenProps.store.dispatch(
        //   action.setEmpresaConfig(resposta)
        // );
      }
      if (error) {
        OpenSnack({ message: error.msg, type: "error" });
      }
    });
  }

  render() {
    return (
      <View type={"Paper"} style={{ padding: 20, paddingTop: 5 }}>
        <View style={styles.view5}>
          <View style={styles.view2} />
          <Tabs
            value={this.state.value}
            indicatorColor="primary"
            textColor="primary"
            onChange={(ii, value) => {
              this.setState({ value });
            }}
          >
            <Tab label="Dados para o app" />
            <Tab label="Equipe" />
            <Tab label="Acessos" />
            {this.state.user.adm?<Tab icon={<Icon name={"settings"} />} />:null}
            {this.state.user.adm&&this.state.empresa.gateway_pagamento !== 1?<Tab icon={<Icon name={"account_balance_wallet"} />} />:null}
          </Tabs>
        </View>
        {this.state.value == 0 ? (
          <View container spacing={16}>
            <View xs={12} md={7} lg={8} item>
              <ConfiguracoesDados
                style={styles.fragment}
                empresa={this.state.empresa}
                screenProps={this.props.screenProps}
                navigation={this.props.navigation}
                activity={this}
              />
            </View>
            <View xs={12} md={5} lg={"auto"} item>
              <ImageUpload
                value={this.state.empresa.logo}
                label={"Logo"}
                style={styles.logoUpload}
                directUpload
                onChange={value => {
                  this.state.empresa.logo = value;
                  this.setState({ item: this.state.item });
                }}
              />
              <PhotosUpload
                style={styles.photosupload}
                value={this.state.empresa.fotos}
                onChange={value => {
                  this.state.empresa.fotos = value;
                  this.setState({ item: this.state.item });
                }}
                directUpload={true}
                label={"Fotos"}
              />
            </View>
          </View>
        ) : null}
        {this.state.value == 1 ? (
          <Equipe
            style={styles.fragment2}
            empresa={this.state.empresa}
            screenProps={this.props.screenProps}
            navigation={this.props.navigation}
            activity={this}
          />
        ) : null}
        {this.state.value == 2 ? (
          <Acesso
            style={styles.fragment2}
            empresa={this.state.empresa}
            screenProps={this.props.screenProps}
            navigation={this.props.navigation}
            activity={this}
          />
        ) : null}
        {this.state.value == 3 ? (
          <Config
            style={styles.fragment2}
            empresa={this.state.empresa}
            screenProps={this.props.screenProps}
            navigation={this.props.navigation}
            activity={this}
          />
        ) : null}
        {this.state.value == 4 ? (
          <Conta
            style={styles.fragment2}
            empresa={this.state.empresa}
            screenProps={this.props.screenProps}
            navigation={this.props.navigation}
            activity={this}
          />
        ) : null}
        {this.state.value == 0 || this.state.value == 3? (
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 15
            }}
            xs={"12"}
            item
          >
            <TouchableOpacity
              variant="outlined"
              children={"Salvar"}
              onClick={() => {
                this.salvar();
              }}
            />
          </View>
        ) : null}
      </View>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: "rgba(255,255,255,1)",
    alignSelf: "stretch",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  logoUpload: {
    width: 100,
    height: 100,
    margin: "auto",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    borderColor: "rgb(224,224,224)"
  },
  photosupload: {
    backgroundColor: "rgba(227,227,227,0.2)",
    height: 330 * (16 / 9) + 40,
    minWidth: 330,
    marginTop: 20,
    alignSelf: "auto",
    marginLeft: 20
  },
  view1: {},
  button1: {},
  icon5: {},
  view5: {},
  view2: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  fragment: {
    alignSelf: "stretch",
    flex: 1
  },
  view3: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  fragment2: {
    alignSelf: "stretch",
    flex: 1
  },
  view1: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row",
    flex: 1
  },
  button1: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    width: 50
  },
  icon5: {
    color: "rgba(255,255,255,1)",
    fontSize: 25
  },
  textT: {
    textAlign: "left",
    color: "rgba(255,255,255,1)",
    alignSelf: "stretch",
    fontWeight: "normal",
    fontSize: 20
  }
});
