import React from "react";
import ListSubheader from "@material-ui/core/ListSubheader";
import View from "react-1app/lib/View";
import Text from "react-1app/lib/Text";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Icon from "react-1app/lib/Icon";
import Switch from "react-1app/lib/Switch";
import TextInput from "react-1app/lib/TextInput";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListView from "react-1app/lib/ListView";
import * as NotificacaoAgenda from "../../worker/notificacao_agenda";
import { OpenSnack } from "react-1app/lib/SnackBar";
import { Util } from "../../infra";
import styleGlobal from "../../styleGlobal";
import ModalVideoAjuda from "../ModalVideoAjuda";
import { OpenModal } from "react-1app/lib/PgModal";
import DatePicker from "react-1app/lib/DatePicker";
import ModalNotif from "../notificacao";
import InputAdornment from "@material-ui/core/InputAdornment";

class Produtos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {},
      load: true,
      totalLista: 0,
      list: [],
      parametros: {
        limit: 400,
        page: 0,
        paginacao: true
      },
      limite: {
        title: 35,
        body: 150
      },
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.projeto != this.state.projeto)
        this.setState({ projeto: store.projeto }, () => {
          this.buscar();
        });
    });
    this.buscar();
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  buscar() {
    NotificacaoAgenda.listar(
      this.state.parametros,
      (dados = { list: [], total: 0 }, error) => {
        this.setState({
          list: dados.list,
          total: dados.total,
          load: false
        });
      }
    );
  }

  salvar() {
    if (this.state.item.id && this.state.item.id !== "new") {
      NotificacaoAgenda.atualizar(this.state.item, (evento, error) => {
        this.onRes(evento, error, "atualizar");
      });
    } else {
      NotificacaoAgenda.salvar(this.state.item, (evento, error) => {
        this.onRes(evento, error, "salvar");
      });
    }
  }

  onRes(evento, error, tag) {
    if (error) {
      OpenSnack({
        message: "Erro ao " + tag + " a Notificação!",
        type: "error"
      });
    } else {
      this.setState({ item: evento });
      this.buscar();
      OpenSnack({
        message: "Notificação " + tag + " com sucesso!",
        type: "success"
      });
    }
  }

  remover() {
    if (window.confirm("Deseja remover este item?")) {
      NotificacaoAgenda.remover(this.state.item.id, (evento, error) => {
        if (error) {
          OpenSnack({
            message: "Erro ao salvar a Notificação!",
            type: "error"
          });
        } else {
          this.setState({ item: {} });
          this.buscar();
          OpenSnack({ message: "Notificação removido!", type: "success" });
        }
      });
    }
  }

  enviarAgora() {
    OpenModal(ModalNotif, {});
  }

  render() {
    return (
      <View container spacing={16}>
        <View {...styleGlobal.gridListDimacao} item>
          <View type={"Paper"} style={styles.root}>
            <ListView
              ListHeaderComponent={() => (
                <View style={styles.head}>
                  <ListSubheader component="div">
                    {"Agenda de notificação"}
                  </ListSubheader>
                  {false && (
                    <TouchableOpacity
                      children={"Ajuda"}
                      style={styles.add}
                      variant="default"
                      onPress={() => {
                        OpenModal(ModalVideoAjuda, {
                          url: "https://www.youtube.com/embed/eNEeGnjceHI"
                        });
                      }}
                    />
                  )}
                  <TouchableOpacity
                    children={"Enviar agora"}
                    style={{ marginLeft: 10 }}
                    style={styles.add}
                    variant="outlined"
                    onPress={() => {
                      this.enviarAgora();
                    }}
                  />
                  <TouchableOpacity
                    children={"Novo"}
                    style={{ marginLeft: 10 }}
                    // style={styles.add}
                    variant="outlined"
                    onPress={() => {
                      this.setState(
                        { item: { id: "new" }, index: null },
                        () => {
                          setTimeout(() => {
                            window.focusElementById("editar");
                          }, 100);
                        }
                      );
                    }}
                  />
                </View>
              )}
              renderItem={({ item, index }) => {
                return (
                  <ListItem
                    button
                    key={index}
                    style={{ width: "100%" }}
                    selected={this.state.index === index}
                    onClick={event =>
                      this.setState({ index, item }, () => {
                        setTimeout(() => {
                          window.focusElementById("editar");
                        }, 100);
                      })
                    }
                  >
                    <Avatar src={item.img}>
                      <Icon name={"confirmation_number"} />
                    </Avatar>
                    <ListItemText primary={item.title} secondary={item.body} />
                    <Icon color="default" name={"created"} />
                  </ListItem>
                );
              }}
              separator
              nativo
              data={this.state.list}
              tablePagination
              load={this.state.load}
              count={Number(this.state.total)}
              page={this.state.parametros.page}
              rowsPerPage={this.state.parametros.limit}
              handleChangePage={(event, page) => {
                this.setState(
                  {
                    parametros: Object.assign(this.state.parametros, { page }),
                    load: true
                  },
                  this.buscar()
                );
              }}
              handleChangeRowsPerPage={event => {
                this.setState(
                  {
                    parametros: Object.assign(this.state.parametros, {
                      limit: event.target.value,
                      page: 0
                    }),
                    load: true
                  },
                  this.buscar()
                );
              }}
            />
          </View>
        </View>
        <View {...styleGlobal.gridEditDimacao} item>
          {this.state.item && this.state.item.id ? (
            <View type={"Paper"} id="editar" style={styles.view}>
              <View style={styles.head}>
                <ListSubheader component="div">{"Notificação"}</ListSubheader>
              </View>
              <ListSubheader>
                {
                  "OBS: Este notificação sera enviada na data e hora selecionada para todos os usuários"
                }
              </ListSubheader>
              <TextInput
                value={this.state.item.title}
                onChange={value => {
                  this.setState({
                    item: { ...this.state.item, title: value },
                  });
                }}
                label={"Titulo"}
                limit={this.state.limite.title}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Text style={styles.text3}>{this.state.limite.title-(this.state.item.title||'').length||0}</Text>
                    </InputAdornment>
                  )
                }}
              />
              <TextInput
                value={this.state.item.body}
                onChange={value => {
                  this.setState({
                    item: { ...this.state.item, body: value },
                  });
                }}
                multiline={true}
                limit={this.state.limite.text}
                label={"Texto"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Text style={styles.text31}>{this.state.limite.body-(this.state.item.body||'').length||0}</Text>
                    </InputAdornment>
                  )
                }}
              />
              <DatePicker
                style={styles.datepicker}
                value={this.state.item.enviar}
                onChange={value => {
                  this.setState({
                    item: { ...this.state.item, enviar: value }
                  });
                }}
                datePickerModeAndroid={"calendar"}
                label={"Data e Hora da notificação"}
              />
              <View style={styles.action}>
                <TouchableOpacity
                  onPress={() => this.salvar()}
                  children={"Salvar"}
                  variant="outlined"
                />
                {this.state.item.id !== "new" ? (
                  <TouchableOpacity
                    onPress={() => this.remover()}
                    style={styles.delete}
                    children={"Apagar"}
                    color={"secondary"}
                    variant="outlined"
                  />
                ) : null}
              </View>
            </View>
          ) : null}
        </View>
      </View>
    );
  }
}

const styles = {
  root: {},
  icon: {
    margin: 15
  },
  cell: {
    width: "100%"
  },
  head: {
    height: 50,
    width: "100%",
    padding: 5
  },
  image: {
    maxWidth: 600,
    maxHeight: 600 / (16 / 9),
    height: "100vh",
    margin: "auto",
    borderRadius: 5,
    background: "rgba(0,0,0,0.1)"
  },
  gridEdit: {},
  view: {
    padding: 20,
    paddingTop: 0
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  add: {
    marginLeft: "auto"
  },
  delete: {
    marginLeft: 20
  }
};

export default Produtos;
