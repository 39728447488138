import React from "react";
import moment from "moment";
import { Util } from "../../infra";
import View from "react-1app/lib/View";
import Text from "react-1app/lib/Text";
import StyleSheet from "react-1app/lib/StyleSheet";
import Table from "react-1app/lib/Table";
import * as Entrada from "../../worker/entrada";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import AddEntrada from "./AddEntrada";
import { OpenModal } from "react-1app/lib/PgModal";
import ModalVideoAjuda from "../ModalVideoAjuda";
import Grafico from "../../components/GraficoBar";
import { json2excel } from "js2excel";

export default class Pagamentos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      lista: [],
      parametros: {
        text: "",
        limit: 50,
        page: 0,
        paginacao: true
      },
      totalLista: 0,
      produto: this.props.produto,
      load: true,
      entrada: {}
    };
  }

  componentDidMount() {
    this.buscarDados();
    this.buscarDadosVenda();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.produto &&
      this.props.produto &&
      nextProps.produto.id != this.props.produto.id
    )
      this.setState({ produto: nextProps.produto, load: true }, () => {
        this.buscarDados();
        this.buscarDadosVenda();
      });
    return true;
  }

  buscarDados() {
    if (!this.state.produto || !this.state.produto.id)
      return this.setState({ lista: [], restante: null });
    Entrada.listarAgrupado(
      { id_produto: this.state.produto.id, ...this.state.parametros },
      (doados = { list: [], total: 0 }, error) => {
        if (error) return console.log(error);
        this.setState({
          lista: doados.list,
          load: false,
          restante: doados.restante,
          totalLista: doados.total
        });
      }
    );
  }

  buscarDadosVenda() {
    if (!this.state.produto || !this.state.produto.id)
      return this.setState({ lista: [], restante: null });
    Entrada.listarAgrupadoVenda(
      { id_produto: this.state.produto.id, limit: 30 },
      (dados = [], error) => {
        if (error) return console.log(error);
        let { listG, listH } = this.ajusteGrafico(dados);

        this.setState({ listG, listH });
      }
    );
  }

  ajusteGrafico(list) {
    let listH = [{ dataKey: "total", fill: "#596DFF" }];
    let listG = list.map(item => ({
      ...item,
      name:moment(item.criado_data).format("DD/MM/YYYY")
    }));
    return { listG, listH };
  }

  nova(entrada) {
    this.setState({
      entrada: { id_produto: this.state.produto.id, ...entrada },
      open: true
    });
  }

  exportToExcel() {
    var data = this.state.lista.map(item => ({
      Data: moment(item.criado_data).format("DD/MM/YY"),
      Quantidade: item.quantidade,
      Descrição: item.descricao,
      Valor: Util.parseMoney(item.valor),
    }));
    console.log(data);
    try {
      json2excel({
        data,
        name: "Entradas e saida do estoque",
        formateDate: "yyyy/mm/dd"
      });
    } catch (e) {
      console.log(e);
      console.error("export error");
      alert("Erro ao criar");
    }
  }

  render() {
    return [
      this.state.listG && this.state.listG[1] ? (
        <View
          type={"Paper"}
          // classes={{
          //   root: this.props.classes.cardHeader
          // }}
          style={{ padding: 10, marginBottom: 20 }}
        >
        <Text style={{margin:10}}>{"Ultimos 30 dias com venda"}</Text>
          <Grafico dataKey={this.state.listH} lista={this.state.listG} />
        </View>
      ) : null,
      <Table
        title={"ENTRADAS NO ESTOQUE"}
        load={this.state.load}
        actionBar={
          <View style={styles.view}>
            <Text style={{ width: 350 }}>
              {"EM ESTOQUE: " + (this.state.restante || 0)}
            </Text>
            <TouchableOpacity
              children={"Ajuda"}
              style={styles.add}
              variant="default"
              onPress={() => {
                OpenModal(ModalVideoAjuda, {
                  url: "https://www.youtube.com/embed/TNVhjlpBaBE"
                });
              }}
            />
          <TouchableOpacity
            key={"89742"}
            style={{ marginLeft: 10 }}
            onPress={() => {
              this.exportToExcel({});
            }}
            children={"Excel"}
            />
            <TouchableOpacity
              key={"89742"}
              style={{ marginLeft: 10 }}
              variant="outlined"
              onPress={() => {
                this.nova({});
              }}
              children={"Novo"}
            />
          </View>
        }
        tableHead={["Data", "Quantidade", "Descrição", "Valor"]}
        tableData={this.state.lista.map((item, index) => [
          moment(item.criado_data).format("DD/MM/YY"),
          item.quantidade,
          item.descricao,
          Util.parseMoney(item.valor)
        ])}
      />,
      <AddEntrada
        style={styles.fragment}
        screenProps={this.props.screenProps}
        navigation={this.props.navigation}
        activity={this}
        entrada={this.state.entrada}
        open={this.state.open}
        closeModal={() => {
          this.setState({ open: false });
        }}
        atualizar={() => this.buscarDados()}
      />
    ];
  }
}

var styles = StyleSheet.create({
  add: {
    marginLeft: "auto"
  },
  view: {
    width: 400,
    alignItems: "center",
    justifyContent: "space-between"
  }
});
