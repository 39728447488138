import { Cloud } from "../infra";

export function salvar(bebida, callback){
  Cloud.post('equipe', bebida, (res,error) => {
    if(callback) callback(res,error);
  })
}

export function criarUsuario(user, callback){
  Cloud.post('equipe/criar/user', user, (res,error) => {
    if(callback) callback(res,error);
  })
}

export function tokenUsuario(user, callback){
  Cloud.post('equipe/token/user', user, (res,error) => {
    if(callback) callback(res,error);
  })
}

export function atualizarUsuario(user, callback){
  Cloud.put('equipe/atualizar/user/'+user.id, user, (res,error) => {
    if(callback) callback(res,error);
  })
}



export function atualizar(bebida, callback){
  Cloud.put('equipe/' + bebida.id, bebida, (res,error) => {
    if(callback) callback(res,error);
  })
}

export function remover(id, callback){
  Cloud.delete('equipe/' + id, {}, (res,error) => {
    if(callback) callback(res,error);
  })
}

export function listar(bebida, callback){
  Cloud.get('equipe', bebida, (res,error) => {
    if(callback) callback(res,error);
  })
}
export function getUsuario({login}, callback){
  if(!login)return;
  Cloud.get('equipe/pesquisar/user', {login}, (res,error) => {
    if(callback) callback(res,error);
  })
}
