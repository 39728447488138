import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Login from "./views/acesso";
import Cadastro from "./views/acesso";
import Evento from "./views/evento";
import Dashboard from "./views/dashboard";
import RelatorioAdm from "./views/relatorio_adm";
import Config from "./views/configuracoes";
import Produto from "./views/produto";
import Venda from "./views/vendas";
import Usuarios from "./views/usuarios";
import Relatorios from "./views/relatorios";
import CaixaFechado from "./views/caixa_fechado";
import Pdv from "./views/pdv";
import Historico from "./views/historico";
import IngressoPadrao from "./views/ingresso_padrao";
import Controle from "./views/controle";
import Perfil from "./views/perfil";
import Pagamento from "./views/pagamento";
import Card from "./views/card";
import NotificacaoAgenda from "./views/notificacao_agenda";
import RelatorioVendaAdm from "./views/relatorios_venda_adm";

export const pages = [
  { path: "/login", component: withRouter(connect()(Login)) },
  { path: "/cadastro", component: withRouter(connect()(Cadastro)) },
  // Private
  { path: "/", component: Dashboard, permissao: "admin" },
  {
    path: "/dashboard",
    component: withRouter(connect()(Dashboard)),
    icon: "dashboard",
    name: "Dashboard",
    menu: true,
    permissao: "admin"
  },
  {
    path: "/relatorio/adm",
    component: withRouter(connect()(RelatorioAdm)),
    permissao: "4party"
  },
  {
    path: "/relatorio/venda/adm",
    component: withRouter(connect()(RelatorioVendaAdm)),
    permissao: "4party"
  },
  {
    path: "/agenda/notificacao",
    component: withRouter(connect()(NotificacaoAgenda)),
    permissao: "admin"
  },
  {
    path: "/pagamento",
    component: withRouter(connect()(Pagamento)),
    icon: "attach_money",
    name: "Pagamentos",
    menu: true,
    permissao: "admin"
  },
  {
    path: "/relatorios",
    component: withRouter(connect()(Relatorios)),
    icon: "library_books",
    name: "Relatórios",
    menu: true,
    permissao: "realatorio"
  },
  {
    path: "/evento",
    component: withRouter(connect()(Evento)),
    icon: "event",
    name: "Eventos",
    menu: true,
    permissao: "eventos"
  },
  {
    path: "/caixa/pdv",
    component: withRouter(connect()(Pdv)),
    icon: "device_hub",
    name: "PDV",
    menu: true,
    permissao: "pdv"
  },
  {
    path: "/ingresso/padrao",
    component: withRouter(connect()(IngressoPadrao)),
    icon: "confirmation_number",
    name: "Ingresso Padrão",
    menu: true,
    permissao: "eventos"
  },
  {
    path: "/historico",
    component: withRouter(connect()(Historico)),
    icon: "event_available",
    name: "Historico",
    menu: true,
    permissao: "eventos"
  },
  {
    path: "/historico/relatorio",
    component: withRouter(connect()(Historico)),
    icon: "event_available",
    name: "Historico",
    menu: true,
    permissao: "realatorioIngresso"
  },
  {
    path: "/caixa/fechado",
    component: withRouter(connect()(CaixaFechado)),
    icon: "remove_shopping_cart",
    name: "Caixas Fechados",
    menu: true,
    permissao: "caixas"
  },
  {
    path: "/produto",
    component: withRouter(connect()(Produto)),
    icon: "local_bar",
    name: "Produto",
    menu: true,
    permissao: "admin"
  },
  {
    path: "/controle",
    component: withRouter(connect()(Controle)),
    icon: "check_circle_outline",
    name: "Controle",
    menu: true,
    permissao: "vendas"
  },
  {
    path: "/venda",
    component: withRouter(connect()(Venda)),
    icon: "receipt",
    name: "Vendas",
    menu: true,
    permissao: "vendas"
  },
  {
    path: "/usuarios",
    component: withRouter(connect()(Usuarios)),
    icon: "group",
    name: "Usuários",
    menu: true,
    permissao: "admin"
  },
  {
    path: "/configuracoes",
    component: withRouter(connect()(Config)),
    icon: "settings",
    name: "Configurações",
    menu: true,
    permissao: "admin"
  },
  {
    path: "/perfil",
    component: withRouter(connect()(Perfil)),
    icon: "account_circle",
    name: "Perfil",
    menu: true,
    permissao: "user"
  },
  {
    path: "/cartas",
    component: withRouter(connect()(Card)),
    icon: "table_chart",
    name: "Cartas",
    menu: true,
    permissao: "test"
  }
];
