import { Cloud } from "../infra";


export function salvar(usuario, callback) {
  Cloud.post("trello/coluna", usuario, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function atualizar(usuario, callback) {
  Cloud.put("trello/coluna", usuario, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function atualizarOrden(usuario, callback) {
  Cloud.put("trello/coluna/orden", usuario, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function remover({id}, callback) {
  Cloud.delete("trello/coluna", {id}, (res, error) => {
    if (callback) callback(res, error);
  });
}


export function listar(input, callback) {
  Cloud.get("trello/coluna", input, (res, error) => {
    if (callback) callback(res, error);
  });
}
