import React, { Component } from "react";

import * as Usuario from "../../worker/usuario";
import Switch from "react-1app/lib/Switch";
import Icon from "react-1app/lib/Icon";
import Avatar from "@material-ui/core/Avatar";
import Text from "react-1app/lib/Text";
import StyleSheet from "react-1app/lib/StyleSheet";
import moment from "moment";
import View from "react-1app/lib/View";
import { Util } from "../../infra";
import ListView from "react-1app/lib/ListView";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import styleGlobal from "../../styleGlobal";

export default class Listar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      load: true,
    };
  }

componentDidMount(){
this.buscar()  
}

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.item &&
      this.props.item &&
      nextProps.item.id != this.props.item.id
    )
      this.setState({ item: nextProps.item, lista: [] }, () => {
        this.buscar();
      });
  }

  buscar() {
    this.setState({ load: true });
    Usuario.cartoes(
      {id_usuario:this.props.item.id},
      (dados = { list: [], total: 0 }, error) => {
        console.log(dados);
        this.setState({
          list: dados,
          load: false
        });
      }
    );
  }


  render() {
    return (

          <View type={"Paper"} style={styles.root}>
            <ListView
              ListHeaderComponent={() => (
                <View style={styles.head}>
                  <Text
                    style={[styleGlobal.SubHeader, { marginRight: "auto" }]}
                  >
                    {"Cartoes"}
                  </Text>
                </View>
              )}
              renderItem={({ item, index }) => {
                return (
                  <ListItem
                    button
                    key={index}
                    style={{ width: "100%" }}
                    selected={this.state.index === index}
                    onClick={event =>
                      this.setState({ index, item }, () => {
                        setTimeout(() => {
                          window.focusElementById("editar");
                        }, 100);
                      })
                    }
                  >
                    <Avatar src={item.foto || "/img/user.png"} />
                    <ListItemText
                      primary={item.card_name}
                      secondary={
                        item.number
                      }
                    />
                  </ListItem>
                );
              }}
              separator
              nativo
              data={this.state.list}
              load={this.state.load}
            />
          </View>
    );
  }
}

var styles = StyleSheet.create({
  root:{
    marginTop:20
  },
  textinput: {
    width: 200,
    marginLeft: "auto",
    color: "rgba(150,150,150,1)",
    borderRadius: 5,
    backgroundColor: "rgba(0,0,0,0.12)",
    padding: 4,
    height: 40,
    fontSize: 15
  },
  view: {
    padding: 20,
    paddingTop: 0
  },
  action: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  head: {
    height: 50,
    width: "100%",
    padding: 5
  },
  action: {
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10
  },
  avatar: {
    width: 60,
    height: 60
  }
});
