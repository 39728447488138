import { Cloud } from "../infra";

var token_user_facebook =
  "EAAFDI9Lmv0EBAPc4Hoi8NRAE3gqG436VuojYrvlr6jYt2OXWpIAhaaJvf5tB6LbXIoGIgOIQFjuqDCWYPWMwiMiHEMxIFpddJrZC2fu6GF4cnk3go8eA7ZBIbGlodLsDDWYkW9etKso7ZBlZCZAIoFWmSVVKC7BdsrnsaZBPzyWw6WpiiF5p2TFdotZCZC0t8xiCv3J2cnsFjQZDZD";

// export function conversas() {
//   /* make the API call */
//   var FB = window.getFB();
//   FB.api("/254793571807866/conversations", function(response) {
//     if (response && !response.error) {
//       /* handle the result */
//     }
//   });
// }

// me/accounts
export function logout(callback) {
  var FB = window.getFB();
  if (!FB) {
    return;
  }
  FB.logout(function(response) {
    // user is now logged out
    if (callback) callback();
  });
}
export function pegarTokenFacebook(callback) {
  if (window.location.host == "localhost:3000")
    return callback(token_user_facebook);
  var FB = window.getFB();
  FB.getLoginStatus(function(response) {
    console.log(response);
    if (response.status === "connected") {
      var accessToken = response.authResponse.accessToken;
      callback(accessToken);
    } else {
      logar(callback);
    }
  });
  //  FB.getLoginStatus(function(response) {
  //      console.log(response)

  //  });
}
export function logar(callback) {
  var FB = window.getFB();
  if (!FB) {
    return;
  }
  FB.login(
    response => {
      console.log(response);
      if (response.authResponse) {
        if (callback) {
          //   token_user_facebook = response.authResponse.accessToken;
          if (callback) callback(response.authResponse.accessToken);
        }
      } else {
        if (callback) callback(null, response);
      }
    },
    {
      scope: "email,manage_pages,pages_messaging,read_page_mailboxes"
    }
  );
  //   FB.login(
  //     response => {
  //       console.log(response);
  //     },
  //     {
  //       scope: "email,manage_pages,pages_messaging,read_page_mailboxes"
  //     }
  //   );
  //pages_messaging_subscriptions // read_page_mailboxes //pages_show_list //user_friends
  //   FB.login(   function(response){  console.log(response);  }, {  scope: "email,manage_pages,pages_show_list,read_page_mailboxes,pages_messaging,pages_messaging_subscriptions"});
}

export function inscricaoMensagem(id_page, token, callback) {
  var FB = window.getFB();
  if (!FB) {
    return;
  }
  pegarTokenFacebook(token_user_facebook => {
    if (!token_user_facebook)
      return callback(null, { msg: "Erro ao pegar token facebook" });

    FB.api(
      "/" + id_page + "/subscribed_apps",
      "POST",
      { subscribed_fields: "feed,conversations", access_token: token },
      function(response) {
        console.log(response);
        if (callback && response.success) callback(response);
        if (callback && !response.success) callback(null, response);
      }
    );
  });
}

// export function getInscricao(id_page, callback) {
//   var FB = window.getFB();

//   pegarTokenFacebook(token_user_facebook => {
//     if (!token_user_facebook) return callback(null,"Erro ao pegar token facebook");

//     FB.api("/" + id_page + "/subscribed_apps", "GET", {}, function(response) {
//       console.log(response);
//       if (callback) callback(response);
//     });
//   });
// }
export function removerInscricaoPagina(id_page, token, callback) {
  var FB = window.getFB();
  if (!FB) {
    return;
  }

  pegarTokenFacebook(token_user_facebook => {
    if (!token_user_facebook)
      return callback(null, { msg: "Erro ao pegar token facebook" });

    FB.api(
      "/" + id_page + "/subscribed_apps",
      "DELETE",
      { subscribed_fields: "feed,conversations", access_token: token },
      function(response) {
        if (callback && response.success) callback(response);
        if (callback && !response.success) callback(null, response);
      }
    );
  });
}

export function getPosts(id_page, token, callback) {
  var FB = window.getFB();
  if (!FB) {
    return;
  }
  pegarTokenFacebook(token_user_facebook => {
    if (!token_user_facebook)
      return callback(null, { msg: "Erro ao pegar token facebook" });
    //  var token_user_facebook = "EAAFDI9Lmv0EBAJq2WPBxdc9TTXWu46TUFT1ZBqzdIR2tdhBIbLvpghcbDDuTUOwkH7nz3EHqgSK5XEnd24PZC8QRyovT4ZAlhUcoxfOzlM9DatDkpYZBmyBQdVbJcLBiM018XZBXIsYBpFWv6zUolZB0tZCsbzNxtJiqyqTEGJ5Vw2M0cE5KAenhDLPrp6f3XbexwYWROjZARAZDZD"
    //   callback(null,token_user_facebook)
    var url = id_page + "?fields=posts{id,full_picture,picture,name,message}";
    FB.api(url, { access_token: token }, function(response) {
      if (
        response &&
        response.posts &&
        response.posts.data &&
        !response.error
      ) {
        return callback(response.posts.data);
      } else {
        return callback([], { msg: response.error });
      }
    });
  });
}
//     var token_user_facebook = "EAAFDI9Lmv0EBACZBHbgYAAvy5laxXBa8s3tjtyKaE5pjRrgySZA2owM3s978r3EdJxfZAZAjoAI23ZCZClx6hcOxz1cZChPomej5A6jssmCxPFGVLrbYS4yZBaARa5DFgHb2jweRZCrXt9NXpdwlAkqV6I50ZAeEkqeIxnheByM7rAPgZDZD"
//   FB.api("951744164837196/accounts?fields=name,picture,access_token", { access_token: token_user_facebook }, function(response) {
//          console.log(response, );
//     });

//   FB.api("me?fields=name,id",  function(response) {
//          console.log(response, );
//     });

export function getPages(callback) {
  var url = "me/accounts?fields=name,picture,access_token";
  var FB = window.getFB();
  if (!FB) {
    return;
  }

  pegarTokenFacebook(token_user_facebook => {
    if (!token_user_facebook)
      return callback(null, { msg: "Erro ao pegar token facebook" });
    getAcessoEterno(token_user_facebook, (token_user_facebook, error) => {
      FB.api(url, { access_token: token_user_facebook }, function(response) {
        //   console.log(response, url);
        if (response && response.data && !response.error) {
          return callback({
            page_token: token_user_facebook,
            lista: response.data
          });
        } else {
          return callback([], { msg: response&&response.error&&response.error|| "error"});
        }
      });
    });
  });
}

export function getAcessoEterno(token_user_facebook, callback) {
  var FB = window.getFB();
  if (!FB) {
    return;
  }
  FB.api(
    "oauth/access_token",
    {
      client_id: "355296117964609", // FB_APP_ID
      client_secret: "8b210b01e260b394300c9b69629512f3", // FB_APP_SECRET
      grant_type: "fb_exchange_token",
      fb_exchange_token: token_user_facebook // USER_TOKEN
    },
    function(res) {
      if (!res || res.error) {
        console.log(!res ? "error occurred" : res.error);
        callback(null, { msg: "Erro ao solicitar acesso longo" });
      } else {
        if (res && res.access_token) callback(res.access_token);
      }
    }
  );
}
export function getEventosPagina(id_pagina, token, callback) {
  var FB = window.getFB();
  if (!FB) {
    return;
  }
  pegarTokenFacebook(token_user_facebook => {
    if (!token_user_facebook)
      return callback(null, { msg: "Erro ao pegar token facebook" });

    FB.api(
      "/" +
        id_pagina +
        "/events?fields=photo_count,name,cover_photo{source}&limit=300",
      {
        access_token: token
      },
      function(response) {
        console.log(response);
        if (response && response.data.length > 0 && !response.error) {
          return callback(response);
        } else {
          return callback([], { msg: "lista " });
        }
      }
    );
  });
}

export function getEventosDetalhes(id_evento, token, callback) {
  // console.log(token)
  var FB = window.getFB();
  if (!FB) {
    return;
  }
  pegarTokenFacebook(token_user_facebook => {
    if (!token_user_facebook)
      return callback(null, { msg: "Erro ao pegar token facebook" });

    FB.api(
      "/" +
        id_evento +
        "?fields=name,cover,description,end_time,place,start_time",
      {
        access_token: token
      },
      function(response) {
        // console.log(response);
        if (response) {
          return callback(response);
        } else {
          return callback(null, { msg: "lista " });
        }
      }
    );
  });
}
export function getAlbunsPagina(id_pagina, token, callback) {
  var FB = window.getFB();
  if (!FB) {
    return;
  }
  pegarTokenFacebook(token_user_facebook => {
    if (!token_user_facebook)
      return callback(null, { msg: "Erro ao pegar token facebook" });

    FB.api(
      "/" +
        id_pagina +
        "/albums?fields=photo_count,name,cover_photo{source}&limit=300",
      {
        access_token: token
      },
      function(response) {
        // console.log(response);
        if (response && response.data.length > 0 && !response.error) {
          return callback(response);
        } else {
          return callback([], { msg: "lista " });
        }
      }
    );
  });
}

export function getImagensDoAlbum(id_album, id_evento, token, callback) {
  var FB = window.getFB();
  if (!FB) {
    return;
  }
  pegarTokenFacebook(token_user_facebook => {
    if (!token_user_facebook)
      return callback(null, { msg: "Erro ao pegar token facebook" });

    FB.api(
      "/" + id_album + "/photos?fields=source&limit=300",
      {
        access_token: token
      },
      function(response) {
        if (response && response.data && response.data.length > 0) {
          let fotos = response.data;
          console.log(fotos, "HHHH");
          Cloud.put(
            "eventos/vincular/fotos",
            { fotos, id_evento },
            (res, error) => {
              if (callback) return callback(res, error);
            }
          );
        } else {
          return callback(null, { msg: "Error ao buscar novamente as fotos" });
        }
      }
    );
  });
}
