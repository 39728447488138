import React from "react";
import ListSubheader from "@material-ui/core/ListSubheader";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Icon from "react-1app/lib/Icon";
import TextInput from "react-1app/lib/TextInput";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListView from "react-1app/lib/ListView";
import SelectInput from "react-1app/lib/SelectInput";
import * as Produto from "../../worker/produtos";
import * as Categoria from "../../worker/categoria";
import { OpenSnack } from "react-1app/lib/SnackBar";
import { Util } from "../../infra";
import Estoque from "./Estoque";
import styleGlobal from "../../styleGlobal";
import { OpenModal } from "react-1app/lib/PgModal";
import ModalVideoAjuda from "../ModalVideoAjuda";
import { json2excel } from "js2excel";

class Produtos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {},
      load: true,
      total: 0,
      list: [],
      parametros: {
        limit: 400,
        page: 0,
        paginacao: true
      }
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.projeto != this.state.projeto)
        this.setState({ projeto: store.projeto, anchorEl: null }, () => {
          this.buscar();
          this.buscarDados();
        });
    });
    this.buscar();
    this.buscarDados();
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  buscar() {
    Produto.listar(
      this.state.parametros,
      (dados = { list: [], total: 0 }, error) => {
        console.log(dados);
        this.setState({
          list: dados.list,
          total: dados.total,
          load: false
        });
      }
    );
  }
  buscarDados() {
    Categoria.listar({}, (dados = [], error) => {
      this.setState({ listCategoria: dados.list, load: false });
    });
  }

  salvar() {
    if (this.state.item.id && this.state.item.id !== "new") {
      Produto.atualizar(this.state.item, (evento, error) => {
        this.onRes(evento, error, "atualizar");
      });
    } else {
      Produto.salvar(this.state.item, (evento, error) => {
        this.onRes(evento, error, "salvar");
      });
    }
  }

  onRes(evento, error, tag) {
    if (error) {
      OpenSnack({ message: "Erro ao " + tag + " produto!", type: "error" });
    } else {
      this.setState({ item: evento });
      this.buscar();
      OpenSnack({
        message: "Produto ao " + tag + " com sucesso!",
        type: "success"
      });
    }
  }

  remover() {
    if (window.confirm("Deseja remover este item?")) {
      Produto.remover(this.state.item.id, (evento, error) => {
        if (error) {
          OpenSnack({ message: "Erro ao salvar evento!", type: "error" });
        } else {
          this.setState({ item: {} });
          this.buscar();
          OpenSnack({ message: "Produto removido!", type: "success" });
        }
      });
    }
  }

  exportToExcel() {
    var data = this.state.list.map(item => ({
      Nome: item.nome,
      Quantidade: item.disponivel
    }));
    try {
      json2excel({
        data,
        name: "Estoque",
        formateDate: "yyyy/mm/dd"
      });
    } catch (e) {
      console.log(e);
      console.error("export error");
      alert("Erro ao criar");
    }
  }

  render() {
    return (
      <View container spacing={16}>
        <View {...styleGlobal.gridListDimacao} item>
          <View type={"Paper"} style={styles.root}>
            <ListView
              ListHeaderComponent={() => (
                <View style={styles.head}>
                  <ListSubheader component="div">{"Produtos"}</ListSubheader>
                  <TouchableOpacity
                    children={"Ajuda"}
                    style={styles.add}
                    variant="default"
                    onPress={() => {
                      OpenModal(ModalVideoAjuda, {
                        url: "https://www.youtube.com/embed/K76brUpy0cE"
                      });
                    }}
                  />
                  <TouchableOpacity
                    children={"Excel"}
                    style={{ marginLeft: 10 }}
                    onPress={() => {
                      this.exportToExcel();
                    }}
                  />
                  <TouchableOpacity
                    children={"Novo"}
                    style={{ marginLeft: 10 }}
                    variant="outlined"
                    onPress={() => {
                      this.setState(
                        { item: { id: "new" }, index: null },
                        () => {
                          setTimeout(() => {
                            window.focusElementById("editar");
                          }, 100);
                        }
                      );
                    }}
                  />
                </View>
              )}
              renderItem={({ item, index }) => {
                return (
                  <ListItem
                    button
                    key={index}
                    style={{ width: "100%" }}
                    selected={this.state.index === index}
                    onClick={event =>
                      this.setState({ index, item }, () => {
                        setTimeout(() => {
                          window.focusElementById("editar");
                        }, 100);
                      })
                    }
                  >
                    <Avatar src={item.img}>
                      <Icon name={"local_bar"} />
                    </Avatar>
                    <ListItemText
                      primary={item.nome}
                      secondary={"Itens restantes: " + (item.disponivel || 0)}
                    />
                    <Icon color="default" name={"created"} />
                  </ListItem>
                );
              }}
              separator
              nativo
              data={this.state.list}
              tablePagination
              load={this.state.load}
              count={Number(this.state.total)}
              page={this.state.parametros.page}
              rowsPerPage={this.state.parametros.limit}
              handleChangePage={(event, page) => {
                this.setState(
                  {
                    parametros: Object.assign(this.state.parametros, { page }),
                    load: true
                  },
                  this.buscar()
                );
              }}
              handleChangeRowsPerPage={event => {
                this.setState(
                  {
                    parametros: Object.assign(this.state.parametros, {
                      limit: event.target.value,
                      page: 0
                    }),
                    load: true
                  },
                  this.buscar()
                );
              }}
            />
          </View>
        </View>
        <View {...styleGlobal.gridEditDimacao} item>
          {this.state.item && this.state.item.id ? (
            <View type={"Paper"} id="editar" style={styles.view}>
              <View style={styles.head}>
                <ListSubheader component="div">{"Produto"}</ListSubheader>
              </View>
              <TextInput
                value={this.state.item.nome}
                onChange={value => {
                  this.setState({ item: { ...this.state.item, nome: value } });
                }}
                label={"Nome"}
              />
              <TextInput
                value={this.state.item.descricao}
                onChange={value => {
                  this.setState({
                    item: { ...this.state.item, descricao: value }
                  });
                }}
                label={"Descrição"}
                multiline={true}
              />
              <TextInput
                value={this.state.item.valor}
                onChange={value => {
                  this.setState({
                    item: {
                      ...this.state.item,
                      valor: Util.maskFormate(value, "money")
                    }
                  });
                }}
                label={"Valor base de venda"}
              />
              <SelectInput
                style={styles.selectinput2}
                value={this.state.item.id_categoria}
                onChange={(value, data, index) => {
                  this.setState({
                    item: { ...this.state.item, id_categoria: value }
                  });
                }}
                label={"Categoria Fiscal"}
                list={this.state.listCategoria}
              />
              <View style={styles.action}>
                <TouchableOpacity
                  onPress={() => this.salvar()}
                  children={"Salvar"}
                  variant="outlined"
                />
                <TouchableOpacity
                  onPress={() => this.remover()}
                  style={styles.delete}
                  children={"Apagar"}
                  color={"secondary"}
                  variant="outlined"
                />
              </View>
              {this.state.item.id && this.state.item.id !== "new" ? (
                <Estoque {...this.props} produto={this.state.item} />
              ) : null}
            </View>
          ) : null}
        </View>
      </View>
    );
  }
}

const styles = {
  root: {},
  icon: {
    margin: 15
  },
  cell: {
    width: "100%"
  },
  head: {
    height: 50,
    width: "100%",
    padding: 5
  },
  image: {
    maxWidth: 600,
    maxHeight: 600 / (16 / 9),
    height: "100vh",
    margin: "auto",
    borderRadius: 5,
    background: "rgba(0,0,0,0.1)"
  },
  gridEdit: {},
  view: {
    padding: 20,
    paddingTop: 0
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  add: {
    marginLeft: "auto"
  },
  delete: {
    marginLeft: 20
  }
};

export default Produtos;
