import {Cloud} from "../infra";


export function totalizadoresPriodo(obj, callback){
  Cloud.get('dashboard/dados/perido', obj, (res,error) => {
    if(callback) callback(res,error);
  })
}


export function totalizadoresSatus(obj, callback){
  Cloud.get('dashboard/dados/status', obj, (res,error) => {
    if(callback) callback(res,error);
  })
}

export function getDadosSemanais(obj, callback){
  Cloud.get('dashboard/dados/semana', obj, (res,error) => {
    if(callback) callback(res,error);
  })
}

export function getDadosMensais(obj, callback){
  Cloud.get('dashboard/dados/mes', obj, (res,error) => {
    if(callback) callback(res,error);
  })
}

export function getUsuariosMensais(obj, callback){
  Cloud.get('dashboard/user/mes', obj, (res,error) => {
    if(callback) callback(res,error);
  })
}

export function getResumoVendasPeriodo(obj, callback){
  Cloud.get('dashboard/venda/periodo', obj, (res,error) => {
    if(callback) callback(res,error);
  })
}

export function getSaldoReceber(obj, callback){
  Cloud.get('dashboard/extrato/saldo', obj, (res,error) => {
    if(callback) callback(res,error);
  })
}

export function getSaldoGet(obj, callback){
  Cloud.get('dashboard/extrato/getnet', obj, (res,error) => {
    if(callback) callback(res,error);
  })
}
