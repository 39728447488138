import { Cloud } from "../infra";

export function listar(promoter, callback) {
  Cloud.get("promoter", promoter, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function receber(obj, callback) {
  Cloud.put("promoter/marcar/pago", obj, (res, error) => {
    if (callback) callback(res, error);
  });
}


export function removerIngresso(obj, callback) {
  console.log(obj);
  Cloud.delete("promoter/apagar/ingresso", obj, (res, error) => {
    if (callback) callback(res, error);
  });
}
