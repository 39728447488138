import React, { Component } from "react";
import ListSubheader from "@material-ui/core/ListSubheader";

import {
  TouchableOpacity,
  View,
  Text,
  StyleSheet,
  OpenSnack,
  OpenDialogOption,
  OpenDialog,
  OpenModal
} from "react-1app";
import * as Pdv from "../../worker/pdv";
import * as Evento from "../../worker/evento";
import md5 from "md5";
import ModalVideoAjuda from "../ModalVideoAjuda";

export default class Links extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caixa_padrao: this.props.caixa_padrao,
      listaEvento: [],
      user: this.props.screenProps.store.getState().user
    };
    this.state.projeto = this.props.screenProps.store.getState().projeto;
  }

  componentDidMount() {
    this.buscar();
  }

  componentWillUnmount() {}

  buscar() {
    Evento.listar({ limit: 1000 }, (dados = { list: [], total: 0 }, error) => {
      this.setState({
        listaEvento: dados.list
      });
    });
  }

  ativarProdutoApp() {
    if (window.confirm("Deseja ativar as vendas no app com este caixa?")) {
      this.setState({ loadAivar: true });
      Pdv.ativarAppProduto(this.props.caixa_padrao, (caixa, error) => {
        if (error)
          return OpenSnack({
            message: error.msg || "Erro ao ativar!",
            type: "error"
          });
        this.props.caixa_padrao.ativo_app = true;
        OpenSnack({ message: "Ativado com sucesso!", type: "success" });
        this.setState({ loadAivar: false });
      });
    }
  }

  desativarProdutoApp() {
    this.validarDesativar(
      { ...this.props.caixa_padrao, id_evento: null },
      () => {
        this.props.caixa_padrao.ativo_app = null;
        this.setState({ load: false });
      }
    );
  }

  desativarIngressoApp(callback) {
    this.validarDesativar(
      { id_evento: this.props.caixa_padrao.id_evento },
      () => {
        this.props.caixa_padrao.id_evento = null;
        this.setState({ load: false });
        if (callback) callback();
      }
    );
  }

  validarDesativar(data, callback) {
    OpenDialog({
      title: "Desativar caixa",
      description: "Apos a desativação a venda para no app",
      action: [
        {
          label: "CONFIRMAR",
          onClick: () => {
            if (
              this.state.senha &&
              md5(this.state.senha + "") === this.state.user.password
            ) {
              this.desativarCaixaApp(data, callback);
            } else {
              alert("Verifique a senha");
            }
          },
          color: "secondary"
        },
        { label: "CANCELAR" }
      ],
      input: {
        secureTextEntry: true,
        type: "password",
        onChange: value => this.setState({ senha: value }),
        label: "Senha de confirmação"
      }
    });
  }

  desativarCaixaApp(data, callback) {
    this.setState({ loadAivar: true });
    OpenSnack({ message: "Aguarde! Desativado caixa." });
    Pdv.desativarApp(data, (caixa, error) => {
      if (error)
        return OpenSnack({
          message: error.msg || "Erro ao ativar!",
          type: "error"
        });
      OpenSnack({ message: "Desativado com sucesso!", type: "success" });
      if (callback) callback();
    });
  }

  selectCaixa() {
    if (this.props.caixa_padrao.id_evento) return alert("Caixa esta ativo");
    OpenDialogOption({
      title: "Selecione o evento para o para venda de ingresso",
      description: "",
      texto: "",
      action: [
        ...this.state.listaEvento.map(item => ({
          label: item.nome,
          onPress: () => this.ativarIngressoApp(item.id),
          icon: "event"
        })),
        {
          label: "Cancelar",
          onPress: () => console.log("Cancelar"),
          icon: "close",
          separa: "Não alterar"
        }
      ]
    });
  }

  ativarIngressoApp(id_evento) {
    this.setState({ loadAivar: true });
    OpenSnack({ message: "Aguarde! Ativando caixa." });
    Pdv.ativarAppIngresso(
      { id: this.props.caixa_padrao.id, id_evento },
      (caixa, error) => {
        if (error)
          return OpenSnack({
            message: error.msg || "Erro ao ativar!",
            type: "error"
          });
        this.props.caixa_padrao.id_evento = id_evento;
        this.setState({ loadAivar: false });
        OpenSnack({ message: "Ativado com sucesso!", type: "success" });
      }
    );
  }

  render() {
    return (
      <View container spacing={16}>
        <View xs={12} item>
          <View type={"Paper"}>
            <ListSubheader component="div">
              {"Vincular evento para venda de ingresso"}
            </ListSubheader>
            <Text
              style={styles.obs}
            >{`OBS: A venda de ingresso só começara com um caixa aberto.
              O Caixa fechara automaticamente 12 horas apos a data término do evento.`}</Text>
            <View style={{ width: "100%", padding: 20 }}>
              <TouchableOpacity
                variant="outlined"
                style={{ fontSize: 20, flex: 1, width: "100%" }}
                onPress={() => {
                  this.selectCaixa();
                }}
                children={
                  (this.state.listaEvento[0] &&
                    (
                      this.state.listaEvento.find(
                        caixa => caixa.id == this.props.caixa_padrao.id_evento
                      ) || {}
                    ).nome) ||
                  "Selecionar Evento"
                }
              />
              {this.props.caixa_padrao.id_evento ? (
                <TouchableOpacity
                  variant="outlined"
                  color={"secondary"}
                  style={{ marginLeft: 20, fontSize: 20 }}
                  onPress={() => {
                    this.desativarIngressoApp();
                  }}
                  children={"Desativar Caixa"}
                />
              ) : null}
            </View>
          </View>
        </View>
        <View xs={12} item>
          <View type={"Paper"}>
            <View>
              <ListSubheader component="div">
                {"Caixa de venda produtos"}
              </ListSubheader>
              <TouchableOpacity
                children={"Ajuda"}
                style={styles.add}
                variant="default"
                onPress={() => {
                  OpenModal(ModalVideoAjuda, {
                    url: "https://www.youtube.com/embed/kgTYlrlSWPU"
                  });
                }}
              />
            </View>
            <Text style={styles.obs}>
              {`OBS: A venda de produtos só começara com um caixa aberto.
                Apenas um caixa pode ser aberto por vez para produto.
                O Caixa fechara automaticamente 24 horas apos a data término do CAIXA PADRÃO.`}
            </Text>
            <View style={{ width: "100%", padding: 20 }}>
              <TouchableOpacity
                variant="outlined"
                style={{ fontSize: 20, flex: 1, width: "100%" }}
                onPress={() => {
                  this.ativarProdutoApp();
                }}
                children={
                  this.props.caixa_padrao.ativo_app
                    ? "Caixa Ativo"
                    : "Ativar Caixa APP"
                }
              />
              {this.props.caixa_padrao.ativo_app ? (
                <TouchableOpacity
                  variant="outlined"
                  color={"secondary"}
                  style={{ marginLeft: 20, fontSize: 20 }}
                  onPress={() => {
                    this.desativarProdutoApp();
                  }}
                  children={"Desativar Caixa"}
                />
              ) : null}
            </View>
          </View>
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  tela: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  progress: {
    width: 35,
    height: 35
  },
  switch: {
    alignSelf: "stretch",
    flex: 1,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderColor: "#eee",
    borderStyle: "solid",
    padding: 10
  },
  add: {
    marginLeft: "auto"
  },
  obs: {
    fontSize: 14,
    margin: 12,
    color: "rgba(150,150,150,1)"
  }
});
