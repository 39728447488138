import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import * as actions from "../../redux/actions";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import View from "react-1app/lib/View";
import Icon from "react-1app/lib/Icon";
import Hidden from "@material-ui/core/Hidden";

import NavLink from "react-router-dom/NavLink";

class MiniDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  componentDidMount() {}

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  handleDrawerOpen() {
    this.setState({ open: true });
  }

  handleDrawerClose() {
    this.setState({ open: false });
  }

  sair() {
    this.props.screenProps.store.dispatch(actions.logout());
  }

  render() {
    const { rotas = [] } = this.props;
    return (
      <List component="nav">
        {rotas.map((rota, key) => (
          <ActionMenu
            to={rota.path}
            tooltip={rota.name}
            disabled={!rota.path}
            key={"key_" + key}
            activeClassName="active"
          >
            <ListItem
              button
              disabled={!rota.path}
              style={
                window.location.pathname == rota.path
                  ? { backgroundColor: "rgba(0,0,0,0.2)" }
                  : {}
              }
            >
              <ListItemIcon>
                {(rota.image && <Avatar src={rota.image} />) || (
                  <View style={{ paddingRigth: 1, flex: 0, paddingLeft: 5 }}>
                    <Icon name={rota.icon} />
                  </View>
                )}
              </ListItemIcon>
              <ListItemText primary={rota.name} />
            </ListItem>
          </ActionMenu>
        ))}
      </List>

    );
  }
}

const ActionMenu = ({ to, tooltip, children, key }) => {
  const NavRender = prop =>
    (to && (
      <NavLink
        to={to}
        key={key}
        activeClassName="active"
        children={prop.children}
      />
    )) ||
    prop.children;
  const TooltipRender = prop =>
    (tooltip && [
      <Hidden smDown key={"key_maior"}>
        <Tooltip
          key={key}
          title={tooltip}
          placement="right"
          children={prop.children}
        />
      </Hidden>,
      <Hidden mdUp key={"key_menor"}>
        {prop.children}
      </Hidden>
    ]) ||
    prop.children;
  return (
    <NavRender>
      <TooltipRender>{children}</TooltipRender>
    </NavRender>
  );
};

export default MiniDrawer;
