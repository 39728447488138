import React from "react";
import Grafico from "../../components/Grafico";
import { Table, View, TouchableOpacity,OpenModal,Icon } from "react-1app";
import * as Ingresso from "../../worker/ingresso";
import { Util } from "../../infra";
import moment from "moment";
import withStyles from "@material-ui/core/styles/withStyles";
import * as CaixaFato from "../../worker/caixa_fato";
import { OpenSnack } from "react-1app/lib/SnackBar";
import styleGlobal from "../../styleGlobal";
import { PgModal } from "react-1app/lib/PgModal";
import { json2excel } from "js2excel";

class BodyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      evento: this.props.evento || {},
      list: [],
      listH: [],
      listG: []
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.evento && this.props.evento)
      this.setState(
        {
          evento: nextProps.evento,
          list: [],
          load: true
        },
        () => {
          this.buscar();
        }
      );
    return true;
  }
  componentDidMount() {
    this.buscar();
  }
  buscar() {
    this.setState({ load: true });
    if (!this.props.evento.id) return;
    Ingresso.listar(null, this.props.evento.id, (data = [], error) => {
      console.log(data);
      this.setState({ list: data.filter(e => Number(e.vendido)), load: false });
    });
    Ingresso.relatorioTotalDia(
      { id_evento: this.props.evento.id },
      (data = [], error) => {
        console.log(data);
        let { listG, listH } = this.ajusteGrafico(data);
        this.setState({ listG, listH, load: false });
      }
    );
  }

  ajusteGrafico(list) {
    let listG = [];
    let listH = [];
    var randomString = require("random-string");
    let color =
      this.props.screenProps.store.getState().theme === "light" ? "#44" : "#f6";
    for (var i = 0; i < list.length; i++) {
      let item = listG.find(a => a.dia === list[i].dia);
      let itemH = listH.find(a => a.dataKey == "item" + list[i].id);

      if (item) {
        item["item" + list[i].id] = list[i].total;
      } else {
        listG.push({ ["item" + list[i].id]: list[i].total, dia: list[i].dia });
      }
      if (!itemH)
        listH.push({
          dataKey: "item" + list[i].id,
          name: list[i].nome,
          stroke:
            color +
            randomString({
              length: 4,
              numeric: true,
              letters: false
            }).toLowerCase()
        });
    }
    listG = listG.map(item => {
      for (var i = 0; i < listH.length; i++) {
        if (!item[listH[i].dataKey]) item[listH[i].dataKey] = 0;
      }
      item.dia = moment(item.dia).format("DD/MM");
      return item;
    });
    return { listG, listH };
  }

  openListaIngresso() {
    this.setState({ load2: true});
    // this.open()
    CaixaFato.listar(
      { limit:100000,id_evento:this.props.evento.id,origen:'app',tipo:'ingresso'},
      (dados = { list: [], total: 0 }, error) => {
        console.log(dados);
        this.setState({ load2: false});

      if(!dados.list||!dados.list[0]) return OpenSnack({ message: "Sem dados para relatorio..." });
        this.open(dados)
      }
    );
  }

  open(dados){
    return this.Modal.openModal((props)=>(
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} item>
          <Table
            tableHead={["Usuário", "Código", "Ingresso", "Valor", "Data"]}
            title={"Lista de ingressos"}
            tableData={dados.list.map(item => [
              item.usuario_nome,
              item.codigo,
              item.nome,
              Util.parseMoney(item.valor),
              moment(item.criado).format("DD/MM/YYYY HH:mm")
            ])}
            actionBar={
              <div>
                <TouchableOpacity
                  style={styles.action}
                  children={"Gerar excel"}
                  variant={"outlined"}
                  onPress={() => {
                    this.exportToExcel(dados);
                    props.closeModal();
                  }}
                />
              <TouchableOpacity
                style={styles.action}
                color={"default"}
                onPress={() => {
                  props.closeModal();
                }}
              >
                <Icon fromFontFamily={"Material Design Icons"} name={"close"} />
              </TouchableOpacity>
              </div>
            }
            />
        </View>
      </View>
    ),{});
  }

  exportToExcel(dados) {
    var data = dados.list.map(item=>({
      "Usuario":item.usuario_nome,
       "Código":item.codigo,
        "Ingresso":item.nome,
         "Valor":Util.parseMoney(item.valor),
          "Data":moment(item.criado).format("DD/MM/YYYY HH:mm")
    }));
    console.log(data);
    try {
      json2excel({
        data,
        name: "Lista de Ingresso",
        formateDate: "yyyy/mm/dd"
      });
    } catch (e) {
      console.log(e);
      console.error("export error");
      alert("Erro ao criar");
    }
  }

  render() {
    return [
      <View
        type={"Paper"}
        classes={{
          root: this.props.classes.cardHeader
        }}
        style={{ padding: 10, marginBottom: 20 }}
      >
        <Grafico dataKey={this.state.listH} lista={this.state.listG} />
      </View>,
      <Table
        tableHead={["Nome", "Vendidos / Validado", "APP", "PDV", "V. Total"]}
        tableData={this.state.list.map(item => [
          item.nome,
          [item.vendido, " / ", item.validado],
          Util.parseMoney(item.ingressos_app),
          Util.parseMoney(item.ingressos_pdv),
          Util.parseMoney((item.ingressos_app || 0) + (item.ingressos_pdv || 0))
        ])}
        title={"Lista de resultado dos ingressos"}
        actionBar={
          <TouchableOpacity
            children={"Exportar ingressos do app"}
            variant={"outlined"}
            load={this.state.load2}
            onPress={() => {
              this.openListaIngresso();
            }}
          />
        }
        load={this.state.load}
      />,
      <PgModal {...this.props} ref={f => (this.Modal = f)} />
    ];
  }
}



let styles = tema => ({
  cardHeader: {
    display: "flex",
    marginRight: 15,
    marginLeft: 15,
    background: tema.palette.primary.main,
    boxShadow:
      "0 12px 20px -10px rgba(0, 0, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 0, 0, 0.2)"
    // paddingRight: 10,
  }
});
// export default BodyModal;
export default withStyles(styles)(BodyModal);
