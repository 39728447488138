// by 1app
import { Cloud } from "../infra";
import md5 from "md5";

export function addImagem(data, callback) {
  Cloud.post("file/imagens/" + data.id_projeto, data, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function getImagens(id_projeto, callback) {
  Cloud.get("file/imagens/" + id_projeto, {}, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function eventosPainel(id_projeto, callback) {
  Cloud.get("projeto/" + id_projeto + "/eventos", {}, (res, error) => {
    if (callback) callback(res, error);
  });
}
export function indicadoresPainel(id_projeto, callback) {
  Cloud.get("projeto/" + id_projeto + "/indicadores", {}, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function get(id, callback) {
  Cloud.get("projeto/" + id, {}, (res, error) => {
    if (callback) callback(res, error);
  });
}
export function listar(callback) {
  Cloud.get("projeto", {}, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function listarAtivos(callback) {
  Cloud.get("projeto_ativo", {}, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function criar(data, callback) {
  data.password = md5(data.password + "");
  Cloud.post("projeto", data, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function alterar(data, callback) {
  Cloud.put("projeto/" + data.id, data, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function ativar(data, callback) {
  Cloud.put("projeto/" + data.id + "/ativar", data, (res, error) => {
    if (callback) callback(res, error);
  });
}
///
///
///Cronograma
///
///
