// by 1app
import Cloud from "../infra/Cloud";

export function sobre(callback) {
  console.log(866);
  Cloud.get("sobre", {}, (res, error) => {
    console.log(res, error);
    if (callback) callback(res, error);
  });
}

export function salvar(data, callback) {
  Cloud.put("sobre", data, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function criar(data, callback) {
  Cloud.post("sobre", data, (res, error) => {
    if (callback) callback(res, error);
  });
}
