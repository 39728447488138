import * as types from "./actionTypes";
import { Cloud } from "../infra";
import * as login from "../worker/login";

export function init(argument) {
  // body...
}

export function logout() {
  login.cacheUser("");
  Cloud.setTokenUser("");
  Cloud.setTokenEmpresa("");
  return {
    type: types.LOGOUT
  };
}
export function logar(user) {
  login.cacheUser(user);
  Cloud.setTokenUser(user.token);
  Cloud.setTokenEmpresa(user.token_projeto);
  return {
    type: types.LOGIN,
    user: user
  };
}


export function setEmpresaConfig(empresaConfig){
    return {
    type: types.EMPRESA_CONFIG,
    empresaConfig: empresaConfig
  };
}


export function setProjeto(data) {
  login.cacheEmpresa(data ? data.id : "");
  return {
    type: "PROJETO",
    data: data
  };
}

export function setRegras(data) {
  return {
    type: "REGRAS",
    data: data
  };
}
