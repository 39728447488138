import React, { Component } from "react";

import {
  TouchableOpacity,
  View,
  Icon,
  StyleSheet,
  TextInput,
  Switch,
  Table as TableSelect,
  OpenSnack
} from "react-1app";
import moment from "moment";
import * as Equipe from "../../worker/equipe_new";
import md5 from "md5";
import ModalVideoAjuda from "../ModalVideoAjuda";
import { OpenModal } from "react-1app/lib/PgModal";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      load: true,
      totalLista: 0,
      equipe: { config: {} },
      parametros: {
        limit: 10,
        page: 0,
        paginacao: true
      },
      list: [
        { value: "pdv", text: "PDV" },
        { value: "caixas", text: "Caixas" },
        { value: "config", text: "Configuração" },
        { value: "eventos", text: "Eventos" },
        { value: "admin", text: "Admin" },
        { value: "realatorio", text: "Relatorio" },
        { value: "vendas", text: "Vendas" },
        { value: "realatorioIngresso", text: "Relatorio apenas ingressos" }
      ]
    };
  }

  componentDidMount() {
    this.buscar();
  }

  buscar() {
    Equipe.listar(
      this.state.parametros,
      (dados = { list: [], total: 0 }, error) => {
        this.setState({
          lista: dados.list,
          totalLista: dados.total,
          load: false
        });
      }
    );
  }

  salvar() {
    this.setState({ load: true });
    if (this.state.equipe.id && this.state.equipe.id !== "new") {
      Equipe.atualizar(this.state.equipe, (equipe, error) => {
        this.onRetorno({ ...this.state.equipe, ...equipe }, error, "atualizar");
      });
    } else {
      this.criarUser((user, error) => {
        Equipe.salvar(
          { ...this.state.equipe, id_usuario: user.id },
          (equipe, error) => {
            this.onRetorno({ ...this.state.equipe, ...equipe }, error, "criar");
          }
        );
      });
    }
  }

  criarUser(callback) {
    if (this.state.equipe.id_usuario)
      return callback({ id: this.state.equipe.id_usuario });
    if (
      !this.state.equipe.nome ||
      !this.state.equipe.login ||
      !this.state.senha
    )
      return alert("Complete os campos");
    Equipe.criarUsuario(
      { ...this.state.equipe, password: md5(this.state.senha) },
      (user, error) => {
        callback(user);
      }
    );
  }

  getUsuario() {
    Equipe.getUsuario(
      { ...this.state.equipe, id: null },
      (dados = {}, error) => {
        this.setState({
          equipe: {
            ...dados,
            id: dados.id ? dados.id : "new",
            config: dados.config || {},
            login: this.state.equipe.login
          },
          load: false
        });
      }
    );
  }

  delete() {
    Equipe.remover(this.state.equipe.id, (equipe, error) => {
      this.onRetorno({}, error, "deletar");
    });
  }

  onRetorno(equipe = {}, error, tag) {
    if (error) {
      OpenSnack({
        message: error.msg || `Erro ao ${tag} Acesso!`,
        type: "error"
      });
      this.setState({ load: false });
    } else {
      this.setState(
        {
          load: false,
          equipe: { config: {}, ...equipe }
        },
        () => {
          OpenSnack({
            message: `Acesso ${tag} com sucesso!`,
            type: "success"
          });
          this.buscar();
        }
      );
    }
  }

  componentWillUnmount() {}

  render() {
    return (
      <View style={styles.view2} container spacing={16}>
        <View style={styles.view3} xs={12} md={6} item>
          <TableSelect
            tableHead={["Nome", "Criado", "Ações"]}
            tableData={this.state.lista.map(item => [
              item.nome,
              moment(item.criado).format("DD/MM/YYYY HH:mm"),
              <View style={styles.view2}>
                <TouchableOpacity
                  icon
                  onPress={() =>
                    this.setState({
                      equipe: { config: {}, ...item },
                      senha: ""
                    })
                  }
                >
                  <Icon fromFontFamily={"Material Icons"} name={"create"} />
                </TouchableOpacity>
              </View>
            ])}
            title={"Lista de equipes"}
            tablePagination
            count={Number(this.state.totalLista)}
            load={this.state.load}
            page={this.state.parametros.page}
            rowsPerPage={this.state.parametros.limit}
            handleChangePage={(event, page) => {
              this.setState(
                {
                  parametros: Object.assign(this.state.parametros, { page }),
                  load: true
                },
                this.buscar()
              );
            }}
            handleChangeRowsPerPage={event => {
              this.setState(
                {
                  parametros: Object.assign(this.state.parametros, {
                    limit: event.target.value,
                    page: 0
                  }),
                  load: true
                },
                this.buscar()
              );
            }}
            actionBar={
              <View>
                <TouchableOpacity
                  children={"Ajuda"}
                  style={styles.add}
                  variant="default"
                  onPress={() => {
                    OpenModal(ModalVideoAjuda, {
                      url: "https://www.youtube.com/embed/MXAeU49Kmt8"
                    });
                  }}
                />
                <TouchableOpacity
                  onPress={() =>
                    this.setState({ equipe: { config: {}, id: "new" } })
                  }
                  variant="outlined"
                  children={"Novo"}
                ></TouchableOpacity>
              </View>
            }
          />
        </View>
        {this.state.equipe && this.state.equipe.id ? (
          <View style={styles.view3} xs={12} md={6} s item>
            <View type={"Paper"} style={{ padding: 20 }}>
              <View style={styles.view4}>
                <TextInput
                  value={this.state.equipe.login}
                  onChange={value => {
                    this.state.equipe.login = value;
                    this.setState({ equipe: this.state.equipe });
                    this.getUsuario();
                  }}
                  keyboardType={"default"}
                  label={"Login"}
                  disabled={this.state.equipe.id != "new"}
                />
                <TextInput
                  value={this.state.equipe.nome}
                  onChange={value => {
                    this.state.equipe.nome = value;
                    this.setState({ equipe: this.state.equipe });
                  }}
                  keyboardType={"default"}
                  label={"Nome"}
                  disabled={this.state.equipe.id_usuario}
                />
                {!this.state.equipe.id_usuario ? (
                  <TextInput
                    value={this.state.senha}
                    onChange={value => {
                      this.state.senha = value;
                      this.setState({ equipe: this.state.equipe });
                    }}
                    keyboardType={"default"}
                    label={"Senha"}
                    disabled={this.state.equipe.id_usuario}
                  />
                ) : null}
                {this.state.list.map(item => (
                  <Switch
                    type={"checkbox"}
                    label={item.text}
                    value={this.state.equipe.config[item.value]}
                    onChange={value => {
                      this.state.equipe.config[item.value] = value;
                      this.setState({ equipe: this.state.equipe });
                    }}
                  />
                ))}
              </View>
              <View
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 15
                }}
                xs={"12"}
                item
              >
                <TouchableOpacity
                  onClick={() => {
                    this.salvar();
                  }}
                  variant="outlined"
                  children={"Salvar"}
                ></TouchableOpacity>
                {this.state.equipe.id != "new" ? (
                  <TouchableOpacity
                    style={{
                      marginLeft: 20
                    }}
                    onClick={() => {
                      this.delete();
                    }}
                    variant="outlined"
                    children={"Deletar"}
                    color="secondary"
                  ></TouchableOpacity>
                ) : null}
              </View>
            </View>
          </View>
        ) : null}
      </View>
    );
  }
}

var styles = StyleSheet.create({
  view2: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  buttonActio: {
    alignSelf: "auto",
    alignItems: "center",
    justifyContent: "center"
  },
  IconButtonActio: {
    color: "rgba(0,0,0,1)",
    fontSize: 25,
    margin: 15
  },
  button2: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    padding: 15,
    borderRadius: 10,
    borderStyle: "solid",
    borderWidth: 1
  },
  text2: {
    textAlign: "left",
    alignSelf: "stretch",
    fontWeight: "normal",
    fontSize: 17
  },
  view3: {},
  content2: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "flex-start"
  },

  imageBanner: {
    backgroundColor: "rgba(227,227,227,1)",
    height: 200,
    width: 350,
    marginTop: 20,
    alignSelf: "auto"
  },
  textinput3: {
    alignSelf: "stretch",
    textAlign: "left",
    fontWeight: "normal",
    marginTop: 5
  },
  view4: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column"
  }
});
