import React, { Component } from "react";

import * as Promoter from "../../worker/promoter";
import styleGlobal from "../../styleGlobal";
import { OpenSnack } from "react-1app/lib/SnackBar";
import { Util } from "../../infra";
import moment from "moment";
import md5 from "md5";
import Icon from "react-1app/lib/Icon";
import Text from "react-1app/lib/Text";
import StyleSheet from "react-1app/lib/StyleSheet";
import ListView from "react-1app/lib/ListView";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import View from "react-1app/lib/View";
import { OpenDialog } from "react-1app/lib/DialogAlert";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";

export default class ModalVendasPromoter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promoter: this.props.promoter,
      load: false,
      senha: ""
    };
    this.state.user = this.props.screenProps.store.getState().user;
  }

  componentDidMount() {
    this.buscar();
  }

  componentWillUnmount() {}

  somarIngressos(lista) {
    let valorTotal = 0;

    lista.map(i => {
      valorTotal = valorTotal + i.valor;
    });

    return valorTotal;
  }

  buscar() {
    this.setState({ load: true });
    Promoter.listar(
      { id_usuario: this.state.promoter.id },
      (lista = [], error) => {
        if (error) {
          this.setState({ load: false });
          return alert("Erro ao buscar ingressos.");
        }

        this.setState({
          lista,
          load: false,
          valorTotal: this.somarIngressos(lista)
        });
      }
    );
  }

  receber() {
    this.setState({ load: true });
    Promoter.receber(
      { id_usuario_promoter: this.state.promoter.id },
      (res = {}, error) => {
        if (error) {
          this.setState({ load: false });
          return alert("Erro ao receber pagamento.");
        }

        this.setState({ promoter: {} });
        this.props.closeModal();
        if (this.props.atualizar) this.props.atualizar();
        this.setState({ load: false });
      }
    );
  }

  validarRemoverIngresso(ingresso) {
    OpenDialog({
      title: "Remover",
      description: "Ele será removido e não poderá mais ser usado! Confirmação para remover ingresso?",
      action: [
        {
          label: "CONFIRMAR",
          onClick: () => {
            if (!this.state.senha||md5(this.state.senha) !== this.state.user.password) {
              return OpenSnack({ message: "Senha inválida!", type: "error" });
            }
            this.removerIngresso(ingresso);
          },
          color: "secondary"
        },
        { label: "CANCELAR" }
      ],
      input: {
        secureTextEntry: true,
        type: "password",
        onChange: value => this.setState({ senha: value }),
        label: "Senha de confirmação"
      }
    });
  }

  removerIngresso(ingreso) {
    this.setState({ load: true });
    Promoter.removerIngresso(
      { id_caixa_fato: ingreso.id },
      (res = {}, error) => {
        if (error) {
          this.setState({ load: false });
          return alert(error.msg || "Erro ao remover.");
        }

        this.buscar();
        this.setState({ load: false });
      }
    );
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View type={"Paper"} style={styles.tela}>
            <ListView
              dataSource={this.state.lista}
              load={this.state.load}
              renderRow={(item, sectionID, rowID) => {
                return (
                  <ListItem key={sectionID} style={{ width: "100%" }}>
                    <Avatar>
                      <Icon name={"confirmation_number"} />
                    </Avatar>
                    <ListItemText
                      primary={item.nome + " | " + item.evento_nome}
                      secondary={
                        moment(item.criado).format("DD/MM/YY - HH:mm") +
                        " | #" +
                        item.codigo
                      }
                    />
                  <Text>{Util.parseMoney(item.valor)}</Text>
                    <TouchableOpacity
                      icon
                      onPress={() => {
                        this.validarRemoverIngresso(item);
                      }}
                    >
                      <Icon color="default" name={"delete"} />
                    </TouchableOpacity>
                  </ListItem>
                );
              }}
              colorSeparator={"rgba(227,227,227,1)"}
              ref={v => (this.campanha = v)}
              nativo
              separator
            />
            <Text style={styles.txValor}>
              {"Valor Total: " + Util.parseMoney(this.state.valorTotal)}
            </Text>
            <View style={styles.action}>
              <TouchableOpacity
                onPress={() => this.receber()}
                children={"Receber"}
                variant="outlined"
              ></TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.props.closeModal()}
                style={styles.delete}
                children={"Fechar"}
                color={"default"}
                variant="outlined"
              ></TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  },
  tela: {
    marginTop: 50,
    padding: 20
  },
  txValor: { fontSize: 20, color: "green", marginTop: 20 }
});
