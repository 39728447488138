import React, { Component } from "react";

import {
  TouchableOpacity,
  View,
  Icon,
  Image ,
  Text,
  StyleSheet,ListView
} from "react-1app";
import moment from "moment";
import * as projetos from "../../worker/projetos";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Util } from "../../infra";
import styleGlobal from "../../styleGlobal";

export default class Imagens extends Component {
  constructor(props) {
    super(props);
    this.state = { load: true,lista:[] };
  }

  upload() {
    this.loadImagem((base64, type, name) => {
    //   console.log(name, type, base64);
      if (this.props.redimencionar) {
        Util.redimencionarImage(base64, (base64, error) => {
          this.subir(base64, type, name);
        });
      } else {
        this.subir(base64, type, name);
      }
    });
  }

  subir(base64, type, name) {
    this.setState({ loadFile: true, load: true });
    var projeto = this.props.screenProps.store.getState().projeto;
    projetos.addImagem(
      { data: base64, tipo: type, id_projeto: projeto.id, nome: name },
      (data, error) => {
        this.setState({ load: true, loadFile: false });
        this.carregar();
      }
    );
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.projeto != this.state.projeto) this.carregar();
    });

    this.carregar();
  }

  carregar() {
    var projeto = this.props.screenProps.store.getState().projeto;
    if (!projeto) return;
    projetos.getImagens(projeto.id, lista => {
      // return console.log(lista);
      this.setState({ lista: lista, load: false });
    });
  }

  loadImagem(callback) {
    var file = document.createElement("INPUT");
    file.setAttribute("type", "file");
    file.style.visibility = "hidden";
    file.style.position = "fixed";
    document.body.appendChild(file);
    file.click();
    file.addEventListener(
      "change",
      event => {
        var files = file.files;
        var type = files[0].type;
        var name = files[0].name;
        // if (files[0].type == "image/jpeg") type = "jpg";
        // if (files[0].type == "image/png") type = "png";
        console.log(type, files[0].type);
        const reader = new FileReader();
        reader.onload = () => {
          callback(reader.result, type, name);
        };
        reader.readAsDataURL(files[0]);
      },
      false
    );
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
      <View type={"Paper"} style={styles.content}>
        <View style={styles.view}>
          <View style={styles.view1}>
            <Icon
              style={styles.icon7}
              fromFontFamily={"Material Design Icons"}
              name={"server-network"}
            />
            <Text style={styles.label}>{"Imagens"}</Text>
            <View style={styles.view81}>
              {this.props.logo_print?(
                <TouchableOpacity
                  onPress={() => {
                    this.props.closeModal();
                    this.props.alterarImagem("");
                  }}
                  children={"Remover Image"}
                  variant="outlined"
                  color="secondary"
                >
                </TouchableOpacity>
              ):null}
              <TouchableOpacity
                onPress={() => {
                  this.upload();
                }}
                style={{marginLeft:10}}
                children={"Nova Image"}
                variant="outlined"
                color="primary"
              >
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  this.props.closeModal();
                }}
                color={"default"}
              >
              <Icon name={"close"}/>
              </TouchableOpacity>
            </View>
          </View>
          <View style={styles.view8}>
            <ListView
              style={styles.gridview}
              dataSource={this.state.lista||[]}
              renderRow={(rowData, sectionID, rowID) => {
                return (
                  <Cell1
                    image={rowData}
                    alterarImagem={this.props.alterarImagem}
                    activity={this}
                    navigator={this.state.navigator}
                    rowID={rowID}
                    key={rowID}
                    sectionID={sectionID}
                  />
                );
              }}
              nativo
              column={"5"}
            />
          </View>
        </View>
      </View>
    </View>
  </View>
    );
  }
}

class Cell1 extends Component {
  constructor(props) {
    super(props);
    this.state = { copy: false };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  renderCopy() {
    return (
      <CopyToClipboard
        text={this.props.image.url}
        onCopy={() => {
          this.setState({ copy: true });
          setTimeout(() => {
            this.setState({ copy: false });
          }, 3000);
        }}
      >
        <span style={{ cursor: "pointer" }}>
          {this.state.copy ? "OK" : "COPIAR URL"}
        </span>
      </CopyToClipboard>
    );
  }
  miniNome() {
    var text = this.props.image.nome;
    if (text.length < 15 || !text) return this.props.image.nome;
    var inicio = text.substring(0, 9);
    var fim = text.substring(text.length - 7, text.length);
    return inicio + "..." + fim;
  }
  renderCopyNome() {
    if (!this.props.image.nome) return null;
    return (
      <CopyToClipboard
        text={this.props.image.nome}
        onCopy={() => {
          this.setState({ copy_nome: true });
          setTimeout(() => {
            this.setState({ copy_nome: false });
          }, 3000);
        }}
      >
        <span style={{ cursor: "pointer", fontSize: 13 }}>
          {this.state.copy_nome ? "OK" : this.miniNome()}
        </span>
      </CopyToClipboard>
    );
  }

  render() {
    return (
      <TouchableOpacity
        disabled={!this.props.alterarImagem}
        onPress={() => {
          if (this.props.alterarImagem){
            this.props.alterarImagem(this.props.image.base64);
            this.props.activity.props.closeModal()
            }
        }}
        style={styles.cell1}
      >
        <View style={styles.view211}>
          <Image
            style={styles.image1}
            resizeMode={"cover"}
            source={{ uri: this.props.image.url }}
          />
          <View style={styles.view41}>
            {this.props.image.nome ? (
              <View style={styles.view71}>{this.renderCopyNome()}</View>
            ) : null}
            <Text style={styles.createdAt11}>
              {moment(this.props.image.criado).fromNow()}
            </Text>
          </View>
          <View style={styles.view71}>{this.renderCopy()}</View>
        </View>
      </TouchableOpacity>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    margin: 20,
    minHeight: 300
  },
  view: {
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    borderRadius: 5,
    padding: 5
  },
  view1: {
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    padding: 5
  },
  icon7: {
    color: "rgba(69,191,85,1)",
    fontSize: 20
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1
  },
  view81: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  bottom: {
    alignSelf: "auto",
    justifyContent: "center",
    alignItems: "center",
    height: 30,
    flexDirection: "column",
    flexWrap: "nowrap",
    width: 30,
    borderRadius: 15,
  },
  icon5: {
    color: "rgba(255,255,255,1)",
    fontSize: 25
  },
  view8: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  gridview: {
    alignSelf: "stretch",
    flex: 1,
    flexWrap: "wrap",
    display:'flex',
    flexDirection: "row"
  },
  cell1: {
    flexDirection: "row",
    alignSelf: "stretch",
    padding: 2,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    borderStyle: "solid",
    borderColor: "rgba(150,150,145,1)",
    borderWidth: "thin",
  },
  view211: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    padding: 5,
    borderRadius: 5,
    backgroundColor: "rgba(255,255,255,1)"
  },
  image1: {
    width: 100,
    height: 80,
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    borderRadius: 5,
    backgroundColor: "rgba(238,238,238,1)"
  },
  view41: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    flexWrap: "nowrap",
    padding: 10
  },
  createdAt1: {
    textAlign: "center",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    fontSize: 12,
    fontWeight: "normal"
  },
  createdAt11: {
    textAlign: "center",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    fontSize: 12,
    fontWeight: "normal"
  },
  view71: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  cell: {
    flexDirection: "row",
    alignSelf: "stretch",
    flex: 1,
    padding: 2,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap"
  },
  view21: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    padding: 5,
    borderRadius: 5,
    backgroundColor: "rgba(255,255,255,1)"
  },
  image: {
    width: 100,
    height: 50,
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    borderRadius: 5,
    backgroundColor: "rgba(238,238,238,1)"
  },
  view4: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    flexWrap: "nowrap",
    padding: 10
  },
  createdAt: {
    textAlign: "center",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    fontSize: 12,
    fontWeight: "normal"
  },
  view7: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: 80
  }
});
