import React, { useState } from "react";
import {
  View,
  OpenSnack,
  TouchableOpacity,
  StyleSheet,
  TextInput,
  Text,
  Icon,
  File
} from "react-1app";
import { OpenModal } from "react-1app/lib/PgModal";
import styleGlobal from "../../styleGlobal";
import moment from "moment";
import { Cloud } from "../../infra";

export default class Editar extends React.Component {
  state = {};
  render() {
    let { card } = this.props;
    if (!card.atividades) card.atividades = [];
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View style={styles.tela} type={"Paper"}>
            <View>
              <Text style={styles.label}>{"Card"}</Text>
              <TouchableOpacity
                onPress={() => {
                  this.props.closeModal();
                  this.props.atualizar();
                }}
                variant="outlined"
              >
                <Icon name={"save"} />
              </TouchableOpacity>

              <TouchableOpacity
                onPress={() => this.props.closeModal()}
                style={styles.delete}
                color={"default"}
                variant="outlined"
              >
                <Icon name={"close"} />
              </TouchableOpacity>
            </View>
            <TextInput
              style={styles.textinput1}
              value={this.props.card.title}
              onChange={value => {
                this.props.card.title = value;
                this.setState({ item: this.state.item });
              }}
              keyboardType={"default"}
              label={"Titulo"}
              variant="outlined"
            />
            <TextInput
              style={styles.textinput1}
              value={this.props.card.description}
              onChange={value => {
                this.props.card.description = value;
                this.setState({ item: this.state.item });
              }}
              keyboardType={"default"}
              multiline={true}
              label={"Descrição"}
              variant="outlined"
            />
            <View style={styles.tela} type={"Paper"}>
              <Text style={styles.label}>{"Atividades"}</Text>
              {card.atividades.map(atividade => {
                return (
                  <View>
                    <Cell
                      atividade={atividade}
                      salvar={() => {
                        this.props.atualizar();
                      }}
                    />
                  </View>
                );
              })}
              <View>
                <Cell
                  atividade={{ texto: "" }}
                  label={"Nova atividade"}
                  novo
                  salvar={atividade => {
                    card.atividades.push(atividade);
                    this.setState({ item: this.state.item });
                    this.props.atualizar();
                  }}
                />
              </View>
            </View>
            <View style={styles.action}>
              <TouchableOpacity
                onPress={() => {
                  this.props.remover();
                  this.props.closeModal();
                }}
                children={"Deletar"}
                color={"secondary"}
                variant="outlined"
              ></TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

class Cell extends React.Component {
  state = { editar: false, texto: "" };
  render() {
    let { atividade, label, salvar, novo } = this.props;
    let { editar, texto } = this.state;
    if (!atividade.files) atividade.files = [];
    return (
      <View
        style={{...styles.atividade,...(!editar?styles.ative:{})}}
        type={editar ? "Paper" : null}
        onClick={() => !editar && this.setState({ editar: true })}
      >
      {editar ? (
        <View style={styles.action}>
          <Text style={styles.label}>{"Editar"}</Text>
          <TouchableOpacity
            onPress={() =>
              upLoadFile((res, error) => {
                if (!error && res && res.url) atividade.files.push(res);
                this.setState({ atividade });
              })
            }
            style={styles.delete}
            color={"default"}
            variant="outlined"
            >
            <Icon name={'attach_file'}/>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              if (salvar) {
                if (novo) {
                  salvar({ ...atividade });
                  atividade.files = [];
                  atividade.texto = "";
                } else {
                  salvar();
                }
              }
              this.setState({ editar: false });
            }}
            style={styles.delete}
            variant="outlined"
            >
            <Icon name={'save'}/>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.setState({ editar: false })}
            style={styles.delete}
            color={"default"}
            variant="outlined"
            >
            <Icon name={'close'}/>
          </TouchableOpacity>
        </View>
      ) : null}
        <TextInput
          style={styles.textinput1}
          value={atividade.texto}
          variant="outlined"
          onChange={value => {
            atividade.texto = value;
            this.setState({ texto: value });
          }}
          autoFocus
          multiline={true}
          keyboardType={"default"}
          label={label || "Atividade"}
          disabled={!editar}
        />
        {atividade.files.map((item, index) => (
          <View style={styles.item_file}>
            <a style={styles.link} target={"_blank"} href={item.url}>
              {item.nome || "ARQUIVO"}
            </a>
            <TouchableOpacity
              icon
              onPress={() => {
                atividade.files.splice(index, 1);
                this.setState({ atividade });
              }}
              style={styles.delete}
              color={"secondary"}
            >
              <Icon name={"delete"} />
            </TouchableOpacity>
          </View>
        ))}
      </View>
    );
  }
}

function upLoadFile(call) {
  var file = document.createElement("INPUT");
  file.setAttribute("type", "file");
  file.style.visibility = "hidden";
  file.style.display = "none";
  document.body.appendChild(file);
  file.click();
  file.addEventListener(
    "change",
    event => {
      var files = file.files;
      if (files && files[0]) {
        upFile(files[0], call);
      }
    },
    false
  );
}

function upFile(file, call) {
  var url = Cloud.getHost() + "/file";
  console.log(url);
  var data = new FormData();
  data.append("file", file);
  data.append("user", "hubot");
  var config = {
    method: "POST",
    contentType: false,
    processData: false,
    body: data,
    headers: {
      "x-request-id": Cloud.getToken()
    }
  };
  Cloud.onFetch(url, config, (data, error) => {
    call({ ...data, nome: file.name }, error);
  });
}

var styles = StyleSheet.create({
  link: { width: "100%", marginLeft: 15, color: "rgba(150,150,150,1)" },
  item_file: {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
    borderColor: "rgba(150,150,150,1)",
    borderStyle: "dotted",
    borderWidth: 1,
    marginBottom: 5
  },
  tela: {
    padding: 20,
    marginTop: 50
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1,
    margin: 10,
    marginRight: "auto"
  },
  atc: {
    width: "100%"
  },
  atividade: {
    padding: 20,
    width: "100%",
    flexDirection: "column",
    marginBottom:5
  },
  ative: {
    borderRadius: 5,
    borderStyle: "dotted"
  },
  action: {
    padding: 10,
    justifyContent: "center",
    alignItem: "center"
  },
  delete: {
    marginLeft: 20
  }
});
