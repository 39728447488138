import React from "react";
import moment from "moment";
import { Util } from "../../infra";
import Progress from "react-1app/lib/Progress";
import Text from "react-1app/lib/Text";
import View from "react-1app/lib/View";
import StyleSheet from "react-1app/lib/StyleSheet";
import Table from "react-1app/lib/Table";
import {OpenSnack} from "react-1app/lib/SnackBar";
import MenuItem from "@material-ui/core/MenuItem";
import * as Pagamento from "../../worker/pagamento";
import {OpenDialog} from "react-1app/lib/DialogAlert";
import md5 from "md5"
export default class Pagamentos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      listPagamento: [],
      parametros: {
        text: "",
        limit: 15,
        page: 0,
        paginacao:true
      },
      totalLista: 0,
      loadEstorno: false,
      load:true,
      projeto:this.props.screenProps.store.getState().projeto,
      user:this.props.screenProps.store.getState().user
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.projeto != this.state.projeto) this.setState({ projeto: store.projeto, listPagamento: [],totalLista:0 },()=>{
        this.buscarDadosPagamento();
      });
    });
    this.buscarDadosPagamento();
  }
  buscarDadosPagamento() {
    Pagamento.listar(this.state.parametros, (doados = {list:[],total:0}, error) => {
      if (error) return console.log(error);
      console.log(doados);
      this.setState({ listPagamento: doados.list, totalLista: doados.total,load:false });
    });
  }
  testoStatus(status) {
    return (status === 1 && "Pago") || (status === 4 && "Estornado")||(status === 'waiting_payment' && "Aguardando");
  }

  validarDeletar(pagamento){
    OpenDialog({
      title:"Estornar Pagamento",
      description:'Confirmação o estorno do pagamento para o cliente! Os produtos comprados votaram para o estoque.',
      action:[
        {label:"CONFIRMAR",onClick:()=>{
          if (this.state.senha&&md5(this.state.senha)===this.state.user.password) {
            this.estornar(pagamento);
          } else {
            alert("Verifique sua senha")
          }
        },color:'secondary'},
        {label:"CANCELAR"},
      ],
      input:{
        secureTextEntry:true,
        type:"password",
        onChange:(value)=>this.setState({senha:value}),
        label:"Senha de confirmação"
      }})
    }


  estornar(pagamento) {
      this.setState({ loadEstorno: pagamento.id });
      Pagamento.estornar({ id_pagamento: pagamento.id }, (res, error) => {
        if (error) {
          OpenSnack({ message: error.msg||error.error||"Erro ao estornar pagamento!", type: "error" });
        } else {
          OpenSnack({
            message: "Pagamento estornado com sucesso!",
            type: "success"
          });
        }
        this.setState({ loadEstorno: false });
        this.buscarDadosPagamento();
      });
  }
  render() {
    return (
      <View style={styles.content}>
        <Table
          tableHead={[
            "ID",
            "Nome",
            "Email",
            "Data",
            "Payment_id",
            "Valor",
            "Status",
            "Ação"
          ]}
          tableData={this.state.listPagamento.map((item, index) => [
            item.id,
            item.nome,
            item.email,
            moment(item.criado).format("DD/MM/YY - HH:mm"),
            (item.payment_id||'').substring(0,10)+'...',
            Util.parseMoney(item.valor),
            this.testoStatus(item.status),
            item.status == 1 &&
              ((this.state.loadEstorno != item.id && (
                <MenuItem
                  key={"editar" + index}
                  onClick={() => {
                    this.validarDeletar(item);
                  }}
                >
                  Estornar
                </MenuItem>
              )) || <Progress />)
          ])}
          count={Number(this.state.totalLista)}
          page={this.state.parametros.page}
          rowsPerPage={this.state.parametros.limit}
          handleChangePage={(event, page) => {
            this.setState(
              {
                parametros: Object.assign(this.state.parametros, { page }),
                load:true
              },
              this.buscarDadosPagamento()
            );
          }}
          handleChangeRowsPerPage={event => {
            this.setState(
              {
                parametros: Object.assign(this.state.parametros, {
                  limit: event.target.value,
                  page: 0
                }),
                load:true
              },
              this.buscarDadosPagamento()
            );
          }}
          tablePagination
          load={this.state.load}
        />
      </View>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    alignItems: "flex-end",
    justifyContent: "flex-end",
    flexDirection: "column",
    marginTop: 20
  },
  text1: {
    textAlign: "left",
    color: null,
    alignSelf: "stretch",
    fontWeight: "600",
    fontSize: 22,
    flex: 1,
    padding: 10
  }
});
