import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import View from "react-1app/lib/View";
import Text from "react-1app/lib/Text";

// Component styles
const styles = theme => ({
  root: {
    display: "flex",
    width: "100%",
    height: "100vh",
    padding: theme.spacing.unit * 4,
    backgroundColor: theme.palette.background.default,
  },
  content: {
    marginTop: '150px',
    textAlign: 'center'
  },
  image: {
    display: 'inline-block',
    marginTop: '50px',
    maxWidth: '100%',
    width: '554px'
  }
});

class NotFound extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <View
          container
          justify="center"
          spacing={4}
        >
          <View
            item
            lg={6}
            xs={12}
          >
            <div className={classes.content}>
              <Text variant="h4">
                404: A página que você está procurando não está aqui
              </Text>
              <Text variant="subtitle2" style={{textAlign:"center"}}>
                {`Você também tentou algum caminho obscuro ou veio aqui por engano.\nSeja qual for, tente usar a navegação`}
              </Text>
              <img
                alt="Under development"
                className={classes.image}
                src="/img/not_found.png"
              />
            </div>
          </View>
        </View>
      </div>
    );
  }
}

NotFound.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NotFound);
