// by 1app
import { Cloud } from "../infra";

export function getEnderecosCep(cep, callback) {
  Cloud.get("enderecos/cep", {
    cep
  }, (res, error) => {
    let dados = normatizarEndereco({}, res)
    getEnderecosGoogle(showAddress(dados, true), (latitude, longitude) => {
      if (callback) callback({ ...dados,
        latitude,
        longitude
      }, error);
    })
  });
}

export function getEnderecosGoogle(endereco, callback) {
  Cloud.get(
    "enderecos/pesquisagoogle",{ endereco }, (retorno  = {}, error) => {
      let { lat, long, address } = retorno
      let dados = normatizarEndereco({}, address);
      if (callback) callback(lat, long, dados, error);
    }
  );
}


export function showAddress(data, busca) {
  var address = data.rua ? data.rua + ", " : "";
  address += data.numero ? data.numero + ", " : "";
  address += data.bairro ? data.bairro + ", " : "";
  if (busca) {
    address += data.cidade ? "cidade " + data.cidade + ", " : "";
    address += data.cidade ? data.cidade + ", " : "";
    address += data.estado ? data.estado + ", " : "";
    address += data.cep ? data.cep + ", brasil" : "";
  } else {
    address += data.cidade ? data.cidade + ", " : "";
    address += data.cidade ? data.cidade + ", " : "";
    address += data.estado ? data.estado + ", " : "";
  }
  return address;
}


export function normatizarEndereco(data, resposta) {
  if (!resposta) return data;
  data.bairro = resposta.neighborhood ? resposta.neighborhood : data.bairro;
  data.cep = resposta.zipcode ? resposta.zipcode : data.cep;
  data.complemento = resposta.complemento ? resposta.complemento : data.complemento;
  data.cidade = resposta.localidade ? resposta.localidade : data.cidade;
  data.cidade = resposta.city ? resposta.city : data.cidade;
  data.estado = resposta.uf ? resposta.uf : data.estado;
  data.estado = resposta.state ? resposta.state : data.estado;
  data.rua = resposta.logradouro ? resposta.logradouro : data.rua;
  data.rua = resposta.street ? resposta.street : data.rua;
  data.numero = resposta.numero ? resposta.numero : data.numero;
  data.endereco = showAddress(data);
  return data;
}
