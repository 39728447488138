// by 1app
import { Cloud } from "../infra";
//
///
///CAIXAS
///
//

export function fechar(data, callback) {
  Cloud.put("pdv/caixa/fechamento/" + data.id, data, (res, error) => {
    // console.log(res);
    if (callback) callback(res, error);
  });
}

export function graficoVendasDia(data, callback) {
  Cloud.get("pdv/vendas/grafico/dia", data, (res, error) => {
    // console.log(res);
    if (callback) callback(res, error);
  });
}
export function graficoVendas(data, callback) {
  Cloud.get("pdv/vendas/grafico", data, (res, error) => {
    // console.log(res);
    if (callback) callback(res, error);
  });
}
export function listarCaixas(data, callback) {
  Cloud.get("pdv/caixa/empresa", data, (res, error) => {
    // console.log(res);
    if (callback) callback(res, error);
  });
}

export function deletarCaixas(data, callback) {
  Cloud.delete("pdv/caixa/empresa", data, (res, error) => {
    // console.log(res);
    if (callback) callback(res, error);
  });
}
export function listarVendas(data, callback) {
  Cloud.get("pdv/caixa/vendas", data, (res, error) => {
    // console.log(res);
    if (callback) callback(res, error);
  });
}
//
///
///PRODUTOS
///
//

export function listarProdutos(data, callback) {
  Cloud.get("pdv/produto", data, (res, error) => {
    // console.log(res);
    if (callback) callback(res, error);
  });
}
export function removerProduto(data, callback) {
  Cloud.delete("pdv/produto/" + data.id, data, (res, error) => {
    // console.log(res);
    if (callback) callback(res, error);
  });
}

export function criarProduto(data, callback) {
  // alert(JSON.stringify(data))
  Cloud.post("pdv/produto", data, (res, error) => {
    // console.log(res);
    if (callback) callback(res, error);
  });
}

export function alterarProduto(data, callback) {
  Cloud.put("pdv/produto/" + data.id, data, (res, error) => {
    // console.log(res);
    if (callback) callback(res, error);
  });
}
//
///
///PDV
///
//

export function remover(data, callback) {
  Cloud.delete("pdv/" + data.id, data, (res, error) => {
    // console.log(res);
    if (callback) callback(res, error);
  });
}

export function clonarProdutos({lista,id_caixa_padrao}, callback) {
  Cloud.put(`pdv/${id_caixa_padrao}/clonar/produtos`, {lista}, (res, error) => {
    // console.log(res);
    if (callback) callback(res, error);
  });
}


export function listar(data, callback) {
  Cloud.get("pdv", data, (res, error) => {
    // console.log(res);
    if (callback) callback(res, error);
  });
}

export function salvar(data, callback) {
  // alert(JSON.stringify(data))
  data.produtos=(data.produtos||[]).filter(a=>a.add)||[];
   console.log(data.produtos);
  Cloud.post("pdv", data, (res, error) => {
    // console.log(res);
    if (callback) callback(res, error);
  });
}
export function get(id, callback) {
  // alert(JSON.stringify(data))
  Cloud.get("pdv/" + id, {}, (res, error) => {
    // console.log(res);
    if (callback) callback(res, error);
  });
}

export function atualizar(data, callback) {
  Cloud.put("pdv/" + data.id, data, (res, error) => {
    // console.log(res);
    if (callback) callback(res, error);
  });
}

export function ativarAppProduto(data, callback) {
  Cloud.post("pdv/ativa/caixa/produto", data, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function ativarAppIngresso(data, callback) {
  Cloud.post("pdv/ativa/caixa/ingresso", data, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function desativarApp(data, callback) {
  Cloud.post("pdv/desativar/caixa/app", data, (res, error) => {
    if (callback) callback(res, error);
  });
}
